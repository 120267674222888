import React, { useState } from "react";
import { AIDataType } from "../../../types/Writer/AIData.ts";
import useAiTypeStore from "../../../stores/modules/aiType.ts";
import { Suggestion } from "./Suggestion.tsx";
import { AiPanelContainer } from "./AiPanelContainer.tsx";

interface AIPanelProps {
  paperId: string
  updateOutline: (updated: boolean) => void
}

export const AIPanel: React.FC<AIPanelProps> = (
  {
    paperId,
    updateOutline
  }
) => {
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const { aiType } = useAiTypeStore()

  switch (aiType) {
    case AIDataType.rephrase:
      return (
        <AiPanelContainer type={'rephrase'} setIsCalculated={setIsCalculated} isCalculated={isCalculated} />
      );
    case AIDataType.suggestion:
      return (
        <Suggestion paperId={paperId} setIsCalculated={setIsCalculated} isCalculated={isCalculated}  />
      );
    case AIDataType.reorganize:
      return (
        <AiPanelContainer type={'reorganize'} setIsCalculated={setIsCalculated} isCalculated={isCalculated} updateOutline={updateOutline} />
      );
    default:
      return <p style={{ color: "gray" }}>Unknown status</p>;
  }
}