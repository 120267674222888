import { User } from "@auth0/auth0-react";
import {
	Avatar,
	Flex,
	IconButton,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItem,
	MenuList,
	Switch,
	useDisclosure,
	Box,
	Text, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalHeader, HStack, Image
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LogoutModal from "./LogoutModal";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { isNewUI } from "../../../atoms/rootAtom";
import { pxToRem } from "../../../utils/editor.ts";
import { ExportHistory } from "../../PaperEditor/Export/ExportHistory.tsx";
import React from "react";
import ExportPreview from "../../PaperEditor/Export/ExportPreview.tsx";

interface Props {
	user: User;
}

const UserMenu = ({ user }: Props) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [newUIStatus, setNewUIStatus] = useRecoilState(isNewUI);
	const { isOpen: isOpenExport, onOpen: onOpenExport, onClose: onCloseExport } = useDisclosure();
	const [previewUrl, setPreviewUrl] = React.useState<string | null>(null)
	const [latexUrl, setLatexUrl] = React.useState<string | null>(null)
	const [pdfName, setPdfName] = React.useState<string | null>(null)

	const newUIOnSwitch = () => {
		setNewUIStatus(!newUIStatus);
		navigate('/reader2')
	}

	return (
		<>
			<Menu>
				<MenuButton
					as={IconButton}
					border="25px"
					aria-label="Options"
					width={pxToRem(50)}
					height={pxToRem(50)}
					icon={<Avatar
						width={pxToRem(50)}
						height={pxToRem(50)}
						size="md" name={user.name} src={user.picture} />}
					variant="outline"
				>
					{"Profile"}
				</MenuButton>
				<MenuList zIndex={3}>
					<MenuItem fontFamily={'Lato'} fontWeight={'bold'} justifyContent={'center'}
										onClick={() => onOpenExport()}>{t('my-export-history')}</MenuItem>
					<MenuItem fontFamily={'Lato'} fontWeight={'bold'} justifyContent={'center'}
										onClick={() => navigate("/profile")}>{t('my-profile-label')}</MenuItem>
					<Flex flexDir="row" alignItems={'center'} px={5} py={2} justifyContent={'center'}>
							<Switch mr={2} size="sm" checked={newUIStatus} onChange={newUIOnSwitch}/>
							<Text fontFamily={'Lato'} fontWeight={'bold'}>New UI</Text>
						</Flex>
					<MenuDivider />
					<MenuItem justifyContent={'center'}
										color={'#EA5050'}
										mt={2}
										fontFamily={'Lato'} fontWeight={'bold'} onClick={onOpen}>{t('logout-label')}</MenuItem>
				</MenuList>
			</Menu>

			<LogoutModal isOpen={isOpen} onClose={onClose} />
			{/** export history modal */}
			<Modal
				isOpen={isOpenExport}
				onClose={onCloseExport}
				scrollBehavior="inside"
			>
				<ModalOverlay background={'whiteAlpha.500'} backdropFilter={'blur(1px)'}/>
				<ModalContent minW="70%" height="80%" ml={'6%'} borderRadius={pxToRem(24)}>
					<ModalHeader>
						{previewUrl ?
						<HStack spacing={2} alignItems={'center'} cursor={'pointer'} onClick={() => {
								setPreviewUrl(null)
								setLatexUrl(null)
								setPdfName(null)
							}
						}>
							<Image w={pxToRem(24)} h={pxToRem(24)} src={'/static/back.svg'} />
							<Text fontSize={pxToRem(20)} fontWeight={'bold'} fontFamily={'Lato'} color={'#333'}>Back to Export History</Text>
						</HStack> :
						<Text fontSize={pxToRem(20)} fontWeight={'bold'} fontFamily={'Lato'} color={'#333'}>{t('my-export-history')}</Text>}
					</ModalHeader>
					<ModalCloseButton _focusVisible={{ 'boxShadow': 'none' }}
														bg={'#FFFFFF'}
														width={pxToRem(24)}
														height={pxToRem(24)}
														_hover={{ bg: '#D3D7E2' }}
														color={'#8E9ABC'}
														top={pxToRem(20)}
														right={pxToRem(20)}
														fontSize={'8px'}
														borderRadius={'50%'} />
					<ModalBody p={5} height={'calc(100% - 140px'}>
						{previewUrl ?
							<Box
								p={pxToRem(10)}
								w={'55%'}
								h={'full'}
								mx="auto"
								position="relative"
							>
								<ExportPreview latexUrl={latexUrl} previewUrl={previewUrl} pdfName={pdfName} />
							</Box>
							: <ExportHistory setPdfName={setPdfName} setPreviewUrl={setPreviewUrl} setLatexUrl={setLatexUrl}/>}
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};

export default UserMenu;
