import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import CitationItemRow from './CitationItemRow';
import ResourcesUploadCard from './ResourcesUploadCard';
import { PaperService } from '../../services/paperService';
import { useRecoilState } from 'recoil';
import { paperResources } from '../../atoms/rootAtom';
import { BibContent } from '../../types/Writer/BibContent';
import { cloneDeep, findIndex } from "lodash";
import { domToContent, handleInlineCursor, insertContentToDom, pxToRem } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import useEditorStore from "../../stores/modules/editor.ts";
import { OutlineNodeType } from "../../types/Writer/OutlineNode.ts";

const CitationPanel: React.FC<{
  paperId: string
}> = ({ paperId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isResourcesUploading, setIsResourcesUploading] = useState<boolean>(false);
  const inputResourcesRef = useRef<HTMLInputElement | null>(null);
  const [res, setRes] = useRecoilState(paperResources);
  const [selectedResource, setSelectedResource] = useState<BibContent[]>([]);
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()
  const { currentCursor, resources, setResources, setFocusEditor } = useEditorStore()
  const [lastInsert, setLastInsert] = useState<string>('')
  const [doneLoading, setDoneLoading] = useState<boolean>(false)

  useEffect(() => {
    if (lastInsert) {
      handleInlineCursor(nodeList[currentCursor.nodeIndex], lastInsert)
      setLastInsert('')
    }
  }, [lastInsert]);

  useEffect(() => {
    if (resources?.length) {
      const result = cloneDeep(resources)
      setRes(result);
    }
  }, [resources]);

  const removeResource = (index: number) => {
    // 清理nodeList中相关的所有cite
    // const removeItem = res[index]
    const newNodeList = cloneDeep(nodeList)
    newNodeList.forEach(node => {
      const nodeInfo = domToContent(node)
      if (node.cite && Object.keys(node.cite).length) {
        delete node.cite['1']
        node.content = (nodeInfo.content as any).replaceAll(`($cite{${1}}$)`, '')
      } else if (node.type === OutlineNodeType.Section) {
        if (node.name !== nodeInfo.content) {
          node.name = nodeInfo.content;
        }
      } else if (node.type === OutlineNodeType.Paragraph) {
        node.content = nodeInfo.content;
      }
    })
    setNodeList([...newNodeList])
    setOutlineIsChange(true)
    setRes(prevResources => {
      return prevResources.filter((_, i) => i !== index);
    });
  };

  const resourcesOnClick = () => {
    if (inputResourcesRef.current) {
      inputResourcesRef.current.click();
    }
  };

  const handleResourcesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.name?.indexOf('bib') > -1) {
      setIsResourcesUploading(true);
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const content = e.target?.result;
        if (typeof content === 'string') {
          PaperService.extractBibContent({ paperId, bib_content: content }).then(
            results => {
              setResources([...res, ...results]);
              setIsResourcesUploading(false)
            }
          )
        }
      };
      reader.readAsText(file);
    } else {
      alert('Please upload a valid .bib file');
    }
  };

  const addResources = () => {
    setFocusEditor(false)
    onOpen();
  }

  const resourceOnSelection = (resource: BibContent) => {
    setSelectedResource((prevSelected) => {
      const exists = prevSelected.some((ref) => ref === resource);
      if (exists) {
        return prevSelected.filter((ref) => ref !== resource);
      } else {
        return [...prevSelected, resource];
      }
    });
  }

  const citeResource = () => {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const newNodeList = cloneDeep(nodeList)
    const node = newNodeList[currentCursor.nodeIndex]
    let slotContent = ''
    if (!node.cite) {
      node.cite = {}
    }
    let lastSlot = ''
    selectedResource.forEach((ref, index) => {
      const id = ref.index
      node.cite[id] = {
        value: ref.title,
        id: ref.ID
      }
      slotContent += `($cite{${id}}$)`
      if (index === selectedResource.length - 1) {
        lastSlot = `($cite{${id}}$)`
      }
      const resItem = findIndex(resources, { index: ref.index })
      if (resItem !== -1) {
        if (!resources[resItem]?.count) {
          resources[resItem].count = 0
        }
        resources[resItem].count += 1
        setResources([...resources])
      }
    })
    const key = node.type === OutlineNodeType.Section ? 'name' : 'content'
    node[key] = insertContentToDom(node, currentCursor, slotContent).content
    setNodeList([...newNodeList])
    setSelectedResource([])
    setOutlineIsChange(true)
    setLastInsert(lastSlot)
  }

  /**
   * create custom bib
   */
  const createCustomBib = async (bibList: any) => {
    setDoneLoading(true)
    const res = await PaperService.addCustomBib({
      paperId,
      bibList
    })
    setDoneLoading(false)
    setResources(res)
    closeModal()
  }

  const closeModal = () => {
    onClose()
    setFocusEditor(true)
  }

  return (
    <Flex flexDir={'column'} onClick={(e) => e.stopPropagation()} overflow={'hidden'}>
      <Flex px={'0.5rem'} flexDir={'column'} overflow={'auto'} h={pxToRem(140)} css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#9EC3E8",
          borderRadius: "2px",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#2b6cb0",
        }
      }}>
        {res.map((ref, index) => (
          <CitationItemRow
            key={ref.ID + index}
            resource={ref}
            selectedResource={selectedResource}
            resourceOnSelection={resourceOnSelection}
            resourceOnDelete={() => removeResource(index)} />
        ))}
      </Flex>
      <Flex flexDir={'row'} justifyContent={'space-between'} pt={5} pb={2}>
        <Box>
          <Button w={pxToRem(99)} h={pxToRem(33)}
                  size={"xs"}
                  variant={'outline'}
                  fontSize={pxToRem(14)}
                  leftIcon={
                    <FaQuoteLeft />
                  }
                  onClick={citeResource}
          >
            Cite
          </Button>
        </Box>
        <Box>
          <Button w={pxToRem(99)} h={pxToRem(33)}
                  size={"sm"}
                  variant={'solid'}
                  colorScheme='brand'
                  fontSize={pxToRem(14)}
                  leftIcon={
                    <AddIcon />
                  }
                  onClick={() => addResources()}
          >
            Add
          </Button>
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={closeModal} size={'lg'} closeOnOverlayClick={false}>
        <ModalOverlay bg={'whiteAlpha.400'} />
        <ModalContent minW={pxToRem(730)} bg={'#fbfcff'} top={'20%'} borderRadius={pxToRem(24)}>
          <ModalCloseButton
            _focusVisible={{ boxShadow: 'none' }}
            bg={'#D3D7E2'}
            width={pxToRem(24)}
            height={pxToRem(24)}
            color={'#8E9ABC'}
            top={pxToRem(20)}
            right={pxToRem(20)}
            fontSize={'8px'}
            borderRadius={'50%'}/>
          <ModalBody p={8} bg={'#FBFCFF'} border={'1px solid #D3D7E2'} borderRadius={pxToRem(24)}>
            <ResourcesUploadCard
              doneLoading={doneLoading}
              createCustomBib={createCustomBib}
              handleFileUploadInputElementForResourcesOnClick={resourcesOnClick}
              resources={res}
              isResourcesUploading={isResourcesUploading}
              inputResourcesRef={inputResourcesRef}
              handleResourcesUpload={handleResourcesUpload}
              removeResource={removeResource}
              closeModal={closeModal}
            />
            <input
              ref={inputResourcesRef}
              type="file"
              onChange={handleResourcesUpload}
              style={{ display: "none" }}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default CitationPanel;


