import React from "react";
import { InlineNode, OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import {
  crossRefType,
  deleteInlineContent,
  domToContent,
  getCurrentEditLi,
  getNodePDom,
  handleChildNode,
  handlerChildTransfer,
  isZero,
  setCursorPosition,
  splitDomToContent,
  splitDomToContent2
} from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { useEditorHook } from "./useEditor.hook.ts";
import { cloneDeep, merge } from "lodash";
import useEditorStore, { CursorInfo } from "../../stores/modules/editor.ts";

export interface DomListItem {
  parent: HTMLElement | ChildNode | null,
  node: HTMLElement | ChildNode
}
/**
 * 平铺childNodes
 * 暂时只有strong, u, em存在dom嵌套场景
 */
export const flattenDOM = (node: HTMLElement | ChildNode, parent?: ChildNode, root?: boolean): DomListItem[] => {
  let nodes = [];
  if (!root) {
    nodes.push({
      parent: parent || null,
      node
    });
  }
  node.childNodes.forEach(child => {
    nodes = nodes.concat(flattenDOM(child, node));
  });
  return nodes;
}

/**
 * enter handler
 */
const enterHandler = (
  e: React.KeyboardEvent<HTMLElement>,
  range: Range,
  nodeList: PaperOutlineNode[],
  addNewNode: (node: PaperOutlineNode, type: OutlineNodeType, level: number, content: string, nextObj?: {
    [key: string]: InlineNode
  }) => void
) => {
  if (range) {
    try {
      const { startContainer } = range;
      e.preventDefault();
      const { index } = getCurrentEditLi(startContainer, nodeList)
      const currentNode = nodeList[index]

      nodeList.forEach((node) => {
        if (node.id !== currentNode.id) {
          const nodeInfo = domToContent(node)
          if (node.type === OutlineNodeType.Section) {
            if (node.name !== nodeInfo.content) {
              node.name = nodeInfo.content;
            }
          } else if (node.type === OutlineNodeType.Paragraph) {
            if (node.content !== nodeInfo.content) {
              node.content = nodeInfo.content;
            }
          }
        } else {
          if (crossRefType.includes(currentNode.type) && currentNode.type !== OutlineNodeType.Section) {
            addNewNode(
              currentNode,
              OutlineNodeType.Paragraph,
              currentNode.level,
              '',
            )
          } else {
            const splitInfo = splitDomToContent(node, startContainer as HTMLElement, range.startOffset)
            const { currentObj, nextObj, next } = splitInfo
            let nextContent = next
            if (next.length === 1 && (isZero(next) || next.trim()?.length === 0)) {
              nextContent = ''
            }
            addNewNode(
              currentObj,
              OutlineNodeType.Paragraph,
              currentNode.type === OutlineNodeType.Section ? (currentNode.level + 1) : currentNode.level,
              nextContent,
              nextObj
            )
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}

/**
 * delete handler
 */
export const deleteHandler = (
  e: React.KeyboardEvent<HTMLElement> | ClipboardEvent,
  range: Range,
  selection: Selection,
  nodeList: PaperOutlineNode[],
  setNodeList: (nodeList: PaperOutlineNode[]) => void,
  setOutlineIsChange: (outlineIsChanged: boolean) => void,
  setCurrentCursor: (cursor: CursorInfo) => void
) => {
  if (range) {
    const startContainer = range.startContainer as HTMLElement;
    // 单行按多行算，startIndex < n < endIndex, n要直接delete, endIndex后的的内容，补加到startIndex中，endIndex+1后到下一个level+1的section之前的节点，补加到
    // startIndex之前最近的level+1 section
    const selectString = selection.toString();
    const { endContainer } = range
    const startLi = getCurrentEditLi(startContainer, nodeList)
    const endLi = getCurrentEditLi(endContainer, nodeList)
    const startIndex = startLi.index
    const endIndex = endLi.index
    const newNodeList = cloneDeep(nodeList)
    let isChange = false
    const sliceIndexArr = []
    // 当前行选择多文本
    if (selectString && startIndex === endIndex && (selectString.indexOf('($') === -1|| selectString.indexOf('</') === -1)) {
      setOutlineIsChange(true)
      return
    }
    newNodeList.forEach((node, index) => {
      if (index <startIndex || index > endIndex) {
        const nodeInfo = domToContent(node)
        if (node.type === OutlineNodeType.Section) {
          if (node.name !== nodeInfo.content) {
            node.name = nodeInfo.content;
          }
        } else if (node.type === OutlineNodeType.Paragraph) {
          if (node.content !== nodeInfo.content) {
            node.content = nodeInfo.content;
          }
        }
      } else {
        if (index === startIndex) {
          if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) { // 当前行是块级equation figure table section
            e.preventDefault()
            const preDom = getNodePDom(newNodeList[index - 1])
            sliceIndexArr.push(index)
            setCurrentCursor({
              nodeIndex: index - 1,
              start: preDom.lastChild ? preDom.lastChild?.textContent?.length : preDom.textContent?.length
            })
            isChange = true
          } else if (selectString.length === 0) {
            // if it is inline 先判断当前删除的是否是Inline
            let inlineElement = null
            const pDom = getNodePDom(node)
            if (startContainer === pDom && pDom.lastChild && isZero(pDom.lastChild.textContent)) {
              inlineElement = pDom.lastElementChild
            } else {
              const isZeroElement = isZero(startContainer.textContent)
              const preIsZeroElement = isZero(startContainer.previousSibling?.textContent || '')
              if (isZeroElement || (preIsZeroElement && startContainer.textContent?.length === 0)) { // 前节点是inline并且当前为空时
                inlineElement = startContainer.previousElementSibling
              }
            }
            // 如果inlineElement现在是extra-tools
            // startContainer前者是空字符串或者inline时
            if (inlineElement) { // 当前准备删除inline refer cite, 不删除当前行
              e.preventDefault()
              const key = node.type === OutlineNodeType.Paragraph ? 'content' : 'name'
              const delInfo = deleteInlineContent(node, inlineElement)
              node[key] = delInfo.content
              isChange = true
              const preLength = pDom?.childNodes[delInfo.index - 1]?.textContent?.length
              setTimeout(() => {
                const dom = getNodePDom(node)
                if (dom) {
                  if (dom.childNodes?.length && !isZero(dom.childNodes[0]?.textContent)) {
                    setCursorPosition(dom.childNodes[delInfo.index - 1], preLength || 0)
                  } else {
                    setCursorPosition(dom, 0)
                  }
                }
              }, 200)
            } else if (range.startOffset === 0 || (pDom?.childNodes?.length && isZero(pDom.childNodes[0]?.textContent))) { // 当前行无内容，直接删除
              if (index > 0) { // 非第一行
                e.preventDefault()
                const preNode = newNodeList[index - 1]
                const key = preNode.type === OutlineNodeType.Section ? 'name' : 'content'

                if (pDom.textContent.replace(/\uFEFF/g, '').length !== 0) {
                  let content = ''
                  let newSlot = {}
                  const pDom = getNodePDom(node)
                  if (pDom) {
                    pDom.childNodes?.forEach((childNode: HTMLElement) => {
                      const info = handleChildNode(childNode, node)
                      content += info.content
                      newSlot = merge(newSlot, info.newSlot)
                    })
                  } else {
                    content = node.content
                  }
                  preNode[key] += content
                  for (const key in newSlot) {
                    preNode[key] = merge(preNode[key], newSlot[key])
                  }
                  if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) {
                    // 如果上一行是block figure table equation
                    return
                  }
                }
                if (newNodeList[index].type === OutlineNodeType.Section) {
                  handlerChildTransfer(newNodeList, index)
                }
                const preDom = getNodePDom(preNode)
                setCurrentCursor({
                  nodeIndex: index - 1,
                  start: preDom.lastChild ? preDom.lastChild?.textContent?.length : preDom.textContent?.length
                })
                sliceIndexArr.push(index)
                isChange = true
              } else if (index === 0) {
                e.preventDefault()
              }
            }
          } else { // selectString不为空
            e.preventDefault()
            const key = node.type === OutlineNodeType.Section ? 'name' : 'content'
            isChange = true
            if (startIndex !== endIndex) {
              const splitInfo = splitDomToContent(node, startContainer as HTMLElement, range.startOffset)
              if (splitInfo.currentObj?.type === OutlineNodeType.Section) {
                node[key] = splitInfo.currentObj.name
              } else {
                node[key] = splitInfo.currentObj.content
              }
            } else {
              node[key] = splitDomToContent2(node, range)?.content
            }
          }
        } else if (index === endIndex) { // 只会出现selectString不为空
          e.preventDefault()
          const splitInfo = splitDomToContent(node, endContainer as HTMLElement, range.endOffset)
          const startNode = newNodeList[startIndex]
          if (startNode) {
            const typeKey = startNode.type === OutlineNodeType.Section ? 'name' : 'content'
            startNode[typeKey] += splitInfo.next
            for (const key in splitInfo.nextObj) {
              startNode[key] = merge(startNode[key] || {}, splitInfo.nextObj[key])
            }
          }
          if (newNodeList[index].type === OutlineNodeType.Section) {
            handlerChildTransfer(newNodeList, index)
          }
          sliceIndexArr.push(index)
          isChange = true
        } else {
          sliceIndexArr.push(index)
          isChange = true
        }
      }
    });
    if (isChange) {
      if (sliceIndexArr?.length) {
        newNodeList.splice(sliceIndexArr[0], sliceIndexArr.length)
      }
      setOutlineIsChange(isChange)
      setNodeList(newNodeList)
    }
  }
}

/**
 * watch input keyboard key
 */
export const useEditorKeyboard = () => {
  const { nodeList, setNodeList, outlineIsChange, setOutlineIsChange } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const { currentCursor, setCurrentCursor } = useEditorStore()

  const keyboardHandler = async (
    e: React.KeyboardEvent<HTMLElement>,
  ) => {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      if (!outlineIsChange) {
        setOutlineIsChange(true)
      }
      switch (e.key) {
        case 'Enter': {
          enterHandler(e, range, nodeList, addNewNode)
          break
        }
        case 'Backspace':
        case 'Delete': {
          deleteHandler(e, range, selection, nodeList, setNodeList, setOutlineIsChange, setCurrentCursor)
          break
        }
        default: {
          // 特殊处理equation, figure的键入事件
          if (/^[a-zA-Z0-9`~!@#$%^&*()_+={}\]\\|;:'",.<>/?\s-]$/g.test(e.key) || e.key === '') {
            if (!outlineIsChange) {
              setOutlineIsChange(true)
            }
            const currentNodeType = nodeList[currentCursor.nodeIndex]?.type
            if (currentNodeType !== OutlineNodeType.Paragraph && currentNodeType !== OutlineNodeType.Section) {
              const { startContainer } = range;
              // todo: 防抖
              if (startContainer instanceof HTMLElement) {
                if (startContainer.getAttribute?.('data-type') === 'block-cursor') {
                  e.preventDefault()
                  const index = startContainer.getAttribute('data-index')
                  if (index) {
                    addNewNode(nodeList[Number(index)], OutlineNodeType.Paragraph, 2, e.key)
                  }
                }
              }
              if (startContainer.parentElement?.getAttribute('data-type') === 'block-cursor') {
                e.preventDefault()
                const index = startContainer.parentElement.getAttribute('data-index')
                if (index) {
                  addNewNode(nodeList[Number(index)], OutlineNodeType.Paragraph, 2, e.key)
                }
              }
            } else {
              // update node content
            }
          }
        }
      }
    }
  }

  return {
    keyboardHandler
  }
}