import React, { useEffect, useRef, useState } from "react";
import { Box, Center, Flex, HStack, Input, Spinner, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { PaperService } from "../services/paperService";
import { ClientError } from "../utils/clientError";
import { Paper, UpdatePaperRequest } from "../types/Writer/Paper";
import { OutlineNode, PaperOutline } from "../types/Writer/OutlineNode";
import { useTranslation } from "react-i18next";
import { formatString, getTreeNodes, listToTree, } from "../types/Writer/NodesFunc";
import ToolAIBox from "../components/PaperEditor/ToolAIBox";
import EditorModelRight from "./EditorModelRight";
import { useSetRecoilState } from "recoil";
import { selectedPaperId } from "../atoms/rootAtom";
import { getNodesWordsCount, pxToRem } from "../utils/editor.ts";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { LeftOutline } from "../components/PaperEditor/LeftOutline.tsx";
import { EmptySection } from "../components/PaperEditor/EmptySection.tsx";
import { SectionContent } from "../components/PaperEditor/SectionContent.tsx";
import useAiTypeStore from "../stores/modules/aiType.ts";
import { AIPanel } from "../components/PaperEditor/AIPanel/AIPanel.tsx";
import useOutlineStore from "../stores/modules/outline.ts";
import { useEditorHook } from "../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";
import UserMenu from "../components/NavBar/Components/UserMenu.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import { InsertLatex } from "../components/PaperEditor/InserTool/InsertLatex.tsx";
import { MathJaxContext } from 'better-react-mathjax';
import { InsertFigure } from "../components/PaperEditor/InserTool/InserFigure.tsx";
import { Export } from "../components/PaperEditor/Export.tsx";
import eventBus, { EventType } from "../hooks/useEventBus.ts";
import useEditorStore from "../stores/modules/editor.ts";
import { InsertFoot } from "../components/PaperEditor/InserTool/InsertFoot.tsx";

const EditorModel: React.FC = () => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const aiPanelRef = useRef(null);
  //----------------------------------------------------
  const { paperId } = useParams();
  const [usePaperId, setUsePaperId] = useState<string>("");
  const [paper, setPaper] = useState<Paper>(null);
  const [paperName, setPaperName] = useState<string>("");
  const [nodeWords, setNodeWords] = useState<number>(0);
  const [totalWords, setTotalWords] = useState<number>(0);

  const idRef = useRef<number>(undefined);
  const setPaperId = useSetRecoilState(selectedPaperId);
  // const setResources = useSetRecoilState(paperResources);
  const aiType = useAiTypeStore((state) => state.aiType)
  const { outline, referNextId, setOutline, setNextId, setReferNextId, setFigureNextId, setEquationId, setTableNextId,
    currentSectionIndex, outlineIsChange, setOutlineIsChange, nodeList, setNodeList, setToolPopOpen, addSpecial, setAddSpecial } = useOutlineStore();
  const { syncDOMToNodes } = useEditorHook()
  const { resources, setResources, setFocusEditor, setCurrentCursor } = useEditorStore()
  // file save status
  const [saving, setSaving] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  // edit paper name
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const { user, isAuthenticated } = useAuth0();
  const { isOpen: isOpenLatex, onOpen: openLatex, onClose: closeLatex } = useDisclosure();
  const { isOpen: isOpenTable, onOpen: openTable, onClose: closeTable } = useDisclosure();
  const { isOpen: isOpenFigure, onOpen: openFigure, onClose: closeFigure } = useDisclosure();
  const { isOpen: isOpenFoot, onOpen: openFoot, onClose: closeFoot } = useDisclosure();
  const [isExport, setIsExport] = useState<boolean>(false);
  const [popOpen, setPopOpen] = useState(false);

  const leftBarWidth: string = "15%";
  const rightBarWidth: string = "23%";
  const mathJaxConfig = {
    loader: { load: ['input/tex', 'output/svg'] },
    tex: { inlineMath: [['($', '$)'], ['\\(', '\\)']] },
  };

  useEffect(() => {
    async function fetchPaper() {
      try {
        const newPaper = await PaperService.getPaperById(usePaperId);
        setPaper(newPaper);
        setOutline(newPaper?.outline);
        setNextId(newPaper?.outline?.nextId)
        setReferNextId(newPaper?.outline?.referNextId || 1)
        setEquationId(newPaper?.outline?.equationNextId || 1)
        setFigureNextId(newPaper?.outline?.figureNextId || 1)
        setTableNextId(newPaper?.outline?.tableNextId || 1)
        setPaperName(newPaper?.name);
        setResources(newPaper?.resources);
        setPaperId(newPaper?.id);
        if (newPaper?.outline?.nodes?.length) {
          setCurrentCursor({
            nodeIndex: 0,
            start: newPaper.outline.nodes[0]?.name?.length || 0,
          })
        }
        // todo count
        // const citationCounts: CitationWithCount[] = newPaper.resources.map(resource => ({
        //   id: resource.ID,
        //   count: 0
        // }));
        //
        // setCitationCount(citationCounts);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    if (!usePaperId) return;
    fetchPaper();
  }, [usePaperId]);

  /**
   * when outline change, update nodeList
   * wait for optimized, whether to change nodeList first, then modify outline when saving
   */
  useEffect(() => {
    if (!outline) {
      // setNodeList([])
      return
    }
    const list = getTreeNodes([outline.nodes[currentSectionIndex]]);
    const count = getNodesWordsCount(getTreeNodes(outline?.nodes))
    setTotalWords(count);
    if (list?.length) {
      idRef.current = list[0].id;
    }
    setNodeList(list);
  }, [currentSectionIndex, outline]);

  useEffect(() => {
    if (!nodeList) return;
    setNodeWords(getNodesWordsCount(nodeList));
  }, [nodeList]);

  useEffect(() => {
    if (!paperId) return;
    setUsePaperId(paperId);
  }, [paperId]);

  useEffect(() => {
    if (addSpecial) {
      updateOutline(true)
      setAddSpecial(false)
    }
  }, [addSpecial]);

  /**
   * update editor block request
   */
  const updateOutline = async (update: boolean) => {
    const newOutline = cloneDeep(outline)
    // todo 很多变量不是最新的
    if (newOutline) {
      try {
        if (update) {
          const { nodeList, nextId, currentSectionIndex, figureNextId, referNextId, equationNextId, tableNextId } = useOutlineStore.getState()
          const list = syncDOMToNodes(nodeList);
          const newSection = listToTree(list)[0]
          if (currentSectionIndex > -1) {
            if (newSection) {
              newOutline.nodes[currentSectionIndex] = newSection
            }
          }
          newOutline.nextId = nextId
          newOutline.referNextId = referNextId
          newOutline.figureNextId = figureNextId
          newOutline.equationNextId = equationNextId
          newOutline.tableNextId = tableNextId
          setOutline({ ...newOutline, referNextId, equationNextId, figureNextId, tableNextId })
        }
        if (outlineIsChange) {
          setOutlineIsChange(false)
          const updateRequest: UpdatePaperRequest = {
            name: paperName,
            outline: newOutline,
            authors: paper.authors,
            content: paper.content,
            abstract: paper.abstract,
            keywords: paper.keywords,
            draft: paper.draft,
            resources: resources,
          }
            setSaving(true)
            await PaperService.updatePaper(
              usePaperId,
              updateRequest
            )
            setSaving(false)
            setSaveComplete(true)
            setTimeout(() => { // hide status after 1s
              setSaveComplete(false)
            }, 1000)
        }
      } catch (error) {
        setSaving(false)
        new ClientError(error).toast();
      }
    }
  };

  /**
   * only update outline structure
   */
  const updateOutlineStructure = async (updateOutline: PaperOutline) => {
    try {
      setSaving(true)
      await PaperService.updatePaper(
        usePaperId,
        {
          name: paperName,
          outline: { ...updateOutline, referNextId },
          authors: paper.authors,
          content: paper.content,
          abstract: paper.abstract,
          keywords: paper.keywords,
          draft: paper.draft,
          resources: paper.resources,
        }
      )
      setSaving(false)
    } catch (err) {
      setSaving(false)
      new ClientError(err).toast();
    }
  }
  //-------------------------------------------------------
  return (
    <>
      <MathJaxContext config={mathJaxConfig}>
        <HStack h={"100%"} w={"100%"} style={{ overflow: "auto" }}
                gap={0}
                bg={'#fbfcff'}
                onClick={(e) => {
                  setPopOpen(false)
                  eventBus.emit(EventType.CloseInsertTable)
                  if (outline?.nodes?.length === 0) return;
                  if (typeof (e.target as HTMLElement).onclick === "function") {
                    return;
                  }
                  if (isExport) return;
                  if (!listRef.current?.contains(e.target) && !aiPanelRef.current?.contains(e.target)) {
                    /**
                     * 引起不必要更新，待优化 对某些按钮进行stopPropagation
                     */
                    updateOutline(true)
                    setToolPopOpen(false)
                  }
                }}
        >
          {/** left outline */}
          <LeftOutline
            setPaperId={setPaperId}
            popOpen={popOpen}
            setPopOpen={setPopOpen}
            setIsExport={setIsExport}
            leftBarWidth={leftBarWidth}
            updateOutlineStructure={updateOutlineStructure}
          />

          {/** center work area */}
          {isExport ? <Export paperId={paperId} setIsExport={setIsExport} /> : <Flex
            display={"flex"}
            flexDir={"column"}
            flex={"1 auto"}
            h={"100%"}
            bg={'#fbfcff'}
            gap={'1rem'}
            maxW={`calc(100% - ${leftBarWidth})`}
          >
            <HStack mt={'1rem'} px={'0.75rem'} minHeight={'60px'}>
              {/** paper name */}
              <Flex gap={6} alignItems={"center"}
                    bg={'#FFFFFF'}
                    mx={'auto'}
                    px={'1rem'}
                    flex={1}
                    borderRadius={pxToRem(12)}
                    border={'2px solid #D3D7E2'}
                    justifyContent={"start"}>
                <Flex gap={3} alignItems={"center"} h={50} pl={2}>
                  {editStatus ?
                    <Input defaultValue={paperName}
                           autoFocus={true}
                           focusBorderColor={'gray.200'}
                           onChange={(e) => {
                             setPaperName(e.target.value)
                           }}
                           onBlur={() => {
                             setEditStatus(false)
                             setFocusEditor(true)
                             setOutlineIsChange(true)
                             updateOutline(false)
                           }}
                    />
                    : <Text h={pxToRem(50)} as={"b"} lineHeight={pxToRem(50)} fontSize={pxToRem(20)}
                            onClick={() =>{
                              setFocusEditor(false)
                              setEditStatus(true);
                            }}>
                      {paperName}
                    </Text>}
                </Flex>
                {/** saving display */}
                {saving && <Flex alignItems={"center"}>
                    <Text color={"#666666AA"}>{t('save-loading-text')}</Text>
                    <Spinner
                        thickness="2px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="sm"
                    />
                </Flex>}
                {saveComplete && <Flex alignItems={"center"}>
                    <Text color={"#666666AA"}>{t('save-completed-text')}</Text>
                    <CheckCircleIcon
                        color="green.500"
                        w="12"
                    />
                </Flex>}
              </Flex>
              <Flex flexDir={'row'}
                    boxShadow={'1px 4px 10px #AAB2C8'}
                    width={pxToRem(50)}
                    height={pxToRem(50)}
                    borderRadius={'50%'}
                    justify="flex-end" align='center'>
                {isAuthenticated && user && (
                  <UserMenu user={user}/>
                )}
              </Flex>
            </HStack>
            <HStack flex={1} minHeight={'calc(100% - 180px)'}>
              <HStack width={`calc(100% - ${rightBarWidth})`} height={'100%'}>
                <VStack width={`calc(100% - ${aiType ? pxToRem(292) : '0px'})`} height={'100%'}
                        transition={"width 0.5s"}
                        gap={'1rem'}>
                  <ToolAIBox openLatex={openLatex}
                             openFigure={openFigure}
                             openFoot={openFoot}
                             openTable={openTable}
                             isOpenTable={isOpenTable}
                             closeTable={closeTable} />
                  {/** section editor */}
                  <HStack
                    justifyContent="flex-end"
                    w={'full'}
                    height={`calc(100% - ${pxToRem(80)})`}
                    gap={0}
                    position={'relative'}
                  >
                    <Box
                      height={"100%"}
                      width={"100%"}
                      justifyContent="flex-start"
                      overflow={"auto"}
                      id={'editor-main-container'}
                    >
                      {outline?.nodes[currentSectionIndex] == undefined ? (<EmptySection/>) : (
                        <Flex
                          flexDir={"column"}
                          width={`calc(100% - ${pxToRem(40)})`}
                          maxWidth={pxToRem(883)}
                          minHeight={'100%'}
                          px={pxToRem(20)}
                          pt={pxToRem(64)}
                          m={'auto'}
                          bg={'#FFFFFF'}
                          borderRadius={'10px'}
                          border={'2px solid #D3D7E2'}
                          overflowY={"hidden"}
                        >
                          <SectionContent
                            listRef={listRef}
                            updateOutline={updateOutline}
                            openLatex={openLatex}
                            openTable={openTable}
                            openFoot={openFoot}
                            isOpenFoot={isOpenFoot}
                            isOpenTable={isOpenTable}
                            isOpenLatex={isOpenLatex}
                            isOpenFigure={isOpenFigure}
                          />
                          {/** total words */}
                          <Center position={'absolute'} bottom={pxToRem(10)}
                                  left={'50%'} transform={'translateX(-50%)'}>
                            <Center
                              border={"1px solid #8E9ABC"}
                              borderRadius={"10px"}
                              bg={'#FFFFFF'}
                              minWidth={pxToRem(143)}
                              height={pxToRem(25)}
                              px={pxToRem(10)}
                              fontSize={pxToRem(12)}
                              fontWeight={'bold'}
                              fontFamily={'Lato'}
                              color={'#8E9ABC'}
                            >
                              {formatString("{0}/{1} words", nodeWords, totalWords)}
                            </Center>
                          </Center>
                        </Flex>
                      )}
                    </Box>
                  </HStack>
                </VStack>
                {/** AiPanel rephrase/suggestion/reorganize */}
                {aiType && (
                  <VStack
                    width={pxToRem(304)}
                    height={`calc(100% - 6rem)`}
                    overflowY={"auto"}
                    overflowX={"hidden"}
                    pl={'1rem'}
                    pr={'1rem'}
                    mt={'1rem'}
                    ref={aiPanelRef}
                    transition={"all 0.5s"}
                  >
                    <AIPanel paperId={paperId} updateOutline={updateOutline} />
                  </VStack>
                )}
              </HStack>
              {/** right area */}
              <EditorModelRight
                rightBarWidth={rightBarWidth}
                paperId={paperId}
                onAddLabel={(node: OutlineNode[]) => {
                  return console.log(node);
                }}
              />
            </HStack>
          </Flex>}
        </HStack>
      </MathJaxContext>
      {/** insert math latex */}
      {isOpenLatex && <InsertLatex isOpenModal={isOpenLatex} closeModal={closeLatex} />}
      {/** insert figure */}
      {isOpenFigure && <InsertFigure isOpenModal={isOpenFigure} closeModal={closeFigure} />}
      {/** insert inline footnote */}
      {isOpenFoot && <InsertFoot isOpenModal={isOpenFoot} closeModal={closeFoot} />}
    </>
  );
};

export default EditorModel;
