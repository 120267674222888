import React, { useEffect, useState } from 'react'
import { Box, Grid, GridItem, Text } from '@chakra-ui/react'
import { TableModal } from "./TableModal.tsx";
import eventBus, { EventType } from "../../../hooks/useEventBus.ts";

interface TableInsertProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  tablePoi: {
    x: number
    y: number
  }
  openTable: () => void
  isOpenTable: boolean
  closeTable: () => void
}

const TableInsert: React.FC<TableInsertProps> = ({ isOpen, setIsOpen, tablePoi, openTable, isOpenTable, closeTable }) => {
  const [hoveredRow, setHoveredRow] = useState(0)
  const [hoveredCol, setHoveredCol] = useState(0)
  const rows = Array.from({ length: 6 })
  const [initGrid, setInitGrid] = useState<{ row: number, col: number }>({
    row: 2,
    col: 3
  })

  const handleMouseEnter = (row: number, col: number) => {
    setHoveredRow(row)
    setHoveredCol(col)
  }

  const handleConfirm = () => {
    setInitGrid({
      row: hoveredRow,
      col: hoveredCol,
    })
    closeInsertTable()
    openTable()
  }

  const closeInsertTable = () => {
    setIsOpen(false)
    setHoveredRow(0)
    setHoveredCol(0)
  }

  useEffect(() => {
    eventBus.on(EventType.CloseInsertTable, closeInsertTable)
    return () => {
      eventBus.off(EventType.CloseInsertTable, closeInsertTable);
    }
  }, [])

  return (
      <>
        <Box
            position="absolute"
            bg="white"
            border="1px solid #D3D7E2"
            borderRadius={10}
            p={4}
            shadow="md"
            zIndex={12}
            top={tablePoi.y + 20}
            left={tablePoi.x + 30}
            display={isOpen ? 'block' : 'none'}
        >
          <Grid templateRows={`repeat(6, 16px)`}
                justifyContent={'center'}
                templateColumns={`repeat(6, 16px)`} gap={1}>
            {rows.map((_, rowIndex) => (
                <React.Fragment key={rowIndex}>
                  {rows.map((_, colIndex) => (
                      <GridItem
                          key={colIndex}
                          w={'16px'}
                          h={'16px'}
                          bg={
                            rowIndex < hoveredRow && colIndex < hoveredCol
                                ? "#1D8AFF"
                                : "gray.100"
                          }
                          border="1px solid"
                          borderColor="gray.300"
                          onMouseEnter={() => handleMouseEnter(rowIndex + 1, colIndex + 1)}
                          onClick={handleConfirm}
                          cursor="pointer"
                      />
                  ))}
                </React.Fragment>
            ))}
          </Grid>
          <Text mt={4} fontSize={'0.875rem'} textAlign={'right'}>
            {hoveredRow} rows x {hoveredCol} columns
          </Text>
        </Box>
        {/** insert table */}
        {isOpenTable && <TableModal isOpenModal={isOpenTable} closeModal={closeTable} initGrid={initGrid} setInitGrid={setInitGrid} />}
      </>
      )
}

export default TableInsert