import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, Box, Text } from "@chakra-ui/react";
import ExportService from "../../../services/exportServices.ts";
import dayjs from 'dayjs'

interface ExportHistoryProps {
  paperId?: string
  currentTrPar?: number | null
  setPreviewUrl: (url: string) => void
  setLatexUrl: (url: string) => void
  setPdfName: (url: string) => void
  setIsTemplate?: (isTemplate: boolean) => void
  setSelectedStyle?: (style: any) => void
}

interface ExportHistoryItem {
  templateName: string
  title: string
  pdfUrl: string
  exportDate: string
  latexZipUrl: string
}

export const ExportHistory: React.FC<ExportHistoryProps> = ({ paperId, setPreviewUrl, setLatexUrl, setPdfName, currentTrPar, setIsTemplate, setSelectedStyle }) => {
  const [historyList, setHistoryList] = useState<ExportHistoryItem[]>([]);
  const [currentTr, setCurrentTr] = useState<number | null>(null);

  useEffect(() => {
    getExportHistory()
  }, []);

  useEffect(() => {
    if (!currentTrPar) {
      setCurrentTr(null)
    }
  }, [currentTrPar]);

  async function getExportHistory() {
    let res = null
    if (paperId) {
      res = await ExportService.getRecentExportHistory(paperId)
    } else {
      res = await ExportService.getRecentExportHistory('')
    }
    setHistoryList(res)
  }
  return (
    <Box w={'full'} h={'full'} overflow={'auto'}>
      {historyList.length ? <Table variant="simple">
        <Thead>
          <Tr>
            <Th fontFamily={'Lato'} fontSize={'1rem'} color={'#8E9ABC'}>File Name</Th>
            <Th fontFamily={'Lato'} fontSize={'1rem'} color={'#8E9ABC'}>Format Style</Th>
            <Th fontFamily={'Lato'} fontSize={'1rem'} color={'#8E9ABC'}>Export Date</Th>
          </Tr>
        </Thead>
        <Tbody>
          {historyList.map((file, index) => (
            <Tr key={index} bg={currentTr === index ? 'rgba(56,152,255,0.14)' : ''}
                cursor={'pointer'}
                onClick={() => {
                  currentTrPar && setCurrentTr(index)
                  setSelectedStyle && setSelectedStyle(null)
                  setIsTemplate && setIsTemplate(false)
                  setPdfName(file.title)
                  setPreviewUrl(file.pdfUrl)
                  setLatexUrl(file.latexZipUrl)
                }}>
              <Td fontFamily={'Lato'} fontSize={'1rem'} color={'#333'} py={2}>{file.title}</Td>
              <Td fontFamily={'Lato'} fontSize={'1rem'} color={'#333'} py={2}>{file.templateName}</Td>
              <Td fontFamily={'Lato'} fontSize={'1rem'} color={'#8E9ABC'} py={2}>{dayjs(file.exportDate).format('YYYY/MM/DD HH:mm')}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table> : <Text textAlign={'center'} fontFamily={'Lato'} color={'rgba(51,51,51,0.45)'}>No export history</Text>}

    </Box>
  )
}
