import {
  Box,
  Heading,
  Flex,
  Button,
  Image,
  Text,
  OrderedList,
  IconButton,
  ListItem,
  HStack,
  VStack, Tabs,
  TabList, TabPanel, Tab,
  TabPanels, Select
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { BibContent } from '../../types/Writer/BibContent';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { pxToRem } from "../../utils/editor.ts";
import { cloneDeep } from "lodash";
import { Input } from "antd";

type ResourcesUploadCardProps = {
  handleFileUploadInputElementForResourcesOnClick: () => void;
  resources: BibContent[];
  isResourcesUploading: boolean;
  doneLoading: boolean
  inputResourcesRef: React.RefObject<HTMLInputElement>;
  handleResourcesUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  removeResource: (index: number) => void;
  closeModal: () => void
  createCustomBib: (list: any) => void
}

/**
 * resource Type list
 */
const resourceType = {
  article: [
    { key: "title" },
    { key: "author" },
    { key: "journal" },
    { key: "number" },
    { key: "pages" },
    { key: "volume" },
    { key: "year" }
  ],
  book: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "publisher" },
    { key: "year" }
  ],
  booklet: [
    { key: "title" },
    { key: "author" },
    { key: "howpublished" },
    { key: "month" },
    { key: "year" }
  ],
  conference: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "booktitle" },
    { key: "pages" },
    { key: "publisher" },
    { key: "year" }
  ],
  inproceedings: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "booktitle" },
    { key: "pages" },
    { key: "publisher" },
    { key: "series" },
    { key: "year" }
  ],
  incollection: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "booktitle" },
    { key: "editor" },
    { key: "pages" },
    { key: "publisher" },
    { key: "year" }
  ],
  manual: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "organization" },
    { key: "year" }
  ],
  mastersthesis: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "month" },
    { key: "school" },
    { key: "year" }
  ],
  misc: [
    { key: "title" },
    { key: "author" },
    { key: "howpublished" },
    { key: "note" },
    { key: "year" }
  ],
  phdthesis: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "month" },
    { key: "school" },
    { key: "year" }
  ],
  proceedings: [
    { key: "title" },
    { key: "editor" },
    { key: "pages" },
    { key: "publisher" },
    { key: "series" },
    { key: "volume" },
    { key: "year" }
  ],
  techreport: [
    { key: "title" },
    { key: "author" },
    { key: "address" },
    { key: "institution" },
    { key: "month" },
    { key: "number" },
    { key: "year" }
  ],
  unpublished: [
    { key: "title" },
    { key: "author" },
    { key: "year" }
  ]
}

const ResourcesUploadCard: React.FC<ResourcesUploadCardProps> = (
  {
    createCustomBib, doneLoading,
     handleFileUploadInputElementForResourcesOnClick,
     resources,
     isResourcesUploading,
     inputResourcesRef,
     handleResourcesUpload,
     removeResource,
     closeModal
                                                                 }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [citeType, setCiteType] = useState('custom')
  const [citeResources, setCiteResources] = useState<any>([
    {
      ENTRYTYPE: 'article',
    }
  ])
  const [resourceTypes] = useState([
    'article', 'book', 'booklet', 'conference', 'incollection', 'inproceedings', 'manual', 'mastersthesis', 'misc', 'phdthesis', 'proceedings', 'techreport', 'unpublished'
  ])
  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (citeResources?.length > 0) {
      setTabIndex(citeResources.length - 1)
    }
  }, [citeResources.length]);

  return (
    <Box h={pxToRem(400)} bg={'#FBFCFF'}>
      <Heading size={'md'} mb={1}>{currentStep=== 1 ? 'Add Reference' : (citeType === 'file' ? ' Upload bib file' : 'Enter Entry')}</Heading>
      {/** step1 */}
      {currentStep === 1 && <HStack h={'full'} justifyContent={'space-around'} alignItems={'center'}>
          <VStack gap={2} w={pxToRem(170)} h={pxToRem(170)}
                  bg={citeType === 'custom' ? '#3898FF33' : '#FFF'}
                  borderRadius={pxToRem(8)}
                  border={'1px solid #3898FF44'}
                  borderColor={citeType === 'custom' ? '#3898FF' : '#3898FF44'}
                  cursor={'pointer'}
                  justifyContent={'center'} alignItems={'center'}
                  onClick={() => {
                    setCurrentStep(2)
                    setCiteType('custom')
                  }}>
              <Image src={'/static/editor/custom_cite.svg'} w={pxToRem(80)}/>
              <Text fontFamily={'Lato'} fontWeight={'bold'} color={'#333'} fontSize={'1rem'}>
                  Enter Reference
              </Text>
          </VStack>
          <VStack gap={2} w={pxToRem(170)} h={pxToRem(170)}
                  bg={citeType === 'file' ? '#3898FF33' : '#FFF'}
                  borderRadius={pxToRem(8)}
                  border={'1px solid #3898FF44'}
                  cursor={'pointer'}
                  borderColor={citeType === 'file' ? '#3898FF' : '#3898FF44'}
                  justifyContent={'center'} alignItems={'center'}
                  onClick={() => {
                    setCurrentStep(2)
                    setCiteType('file')
                  }}>
              <Image src={'/static/editor/cite_file.svg'} w={pxToRem(80)}/>
              <Text fontFamily={'Lato'} fontWeight={'bold'} color={'#333'} fontSize={'1rem'}>
                  Upload Reference
              </Text>
          </VStack>
      </HStack>}
      {currentStep === 2 && citeType === 'file' && <React.Fragment>
          <Text mt={4} fontFamily={'Lato'} color={'gray'}>Upload your resource materials to Doenba Editor to help us
              better understand your draft. You can upload <Text as='b'>bib.</Text> file. We will add your resources to
              your library.</Text>
          <Flex flexDir={'row'} mt={5}>
              <Box w={pxToRem(200)} mr={5}>
                  <Box
                      onClick={handleFileUploadInputElementForResourcesOnClick}
                      p={4}
                      border="1px solid"
                      borderColor="gray.300"
                      borderRadius="md"
                      textAlign="center"
                      cursor="pointer"
                      w={'160px'}
                      h={pxToRem(190)}
                      mb={5}
                  >
                    {resources.length === 0 && !isResourcesUploading &&
                        <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                            <Box w={pxToRem(40)} mb={3}>
                                <Image
                                    w={pxToRem(40)}
                                    objectFit='cover'
                                    src='/static/editor/export.png'
                                    alt='Doenba'
                                />
                            </Box>
                            <Text color={'gray.500'}>Drag to Upload</Text>
                            <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                            <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' _hover={{ bg: '#1d8aff' }}
                                    color='white'>Select</Button>
                        </Flex>
                    }
                    {isResourcesUploading &&
                        <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                            <Box pt={1}>
                                <DonebaSpinner/>
                            </Box>
                            <Text color={'gray.500'}>Drag to Upload</Text>
                            <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                            <Button size={'sm'} bg='#1d8aff' w='80%' mt='2' color='white'>Select</Button>
                        </Flex>
                    }
                    {resources.length > 0 && !isResourcesUploading &&
                        <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                            <Box w={'40px'} mb={3}>
                                <FaCheckCircle color='green' fontSize={'35px'}/>
                            </Box>
                            <Text color={'gray.500'}>Drag to Upload</Text>
                            <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                            <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' color='white'>Select</Button>
                        </Flex>
                    }
                      <input
                          ref={inputResourcesRef}
                          type="file"
                          onChange={handleResourcesUpload}
                          style={{ display: "none" }}
                      />
                  </Box>
              </Box>
              <Box w={'calc(100% - 100px)'} h={pxToRem(240)} overflow={'auto'}>
                  <OrderedList spacing={3}>
                    {resources.map((resource, index) => (
                      <ListItem key={resource.ID}>
                        <Box><Text as='b'>{resource.title} - {resource.year}</Text></Box>
                        <Flex flexDir={'row'} justifyContent={'space-between'} pr={5}>
                          <Box><Text color={'gray.400'}>{resource.author}</Text></Box>
                          <Box><IconButton variant={'ghost'} size={'xs'} aria-label='remove' icon={<DeleteIcon/>}
                                           onClick={() => removeResource(index)}/></Box>
                        </Flex>
                      </ListItem>
                    ))}
                  </OrderedList>
              </Box>
          </Flex>
          <HStack w={'full'} justifyContent={'space-between'}>
              <Button bg={'#9399AB'} color={'#FFF'} _hover={{ bg: '#9399ab' }} borderRadius={pxToRem(13)} w={pxToRem(120)}
                      mr={3} onClick={() => {
                setCurrentStep(1)
              }}>Back</Button>
              <Button
                  w={pxToRem(120)}
                  bg={'#1D8AFF'}
                  _hover={{ background: '#1D8AFF' }}
                  border={'1px solid #1D8AFF'}
                  borderRadius={pxToRem(13)}
                  color={'#FFFFFF'}
                  onClick={closeModal}
              >
                  Start
              </Button>
          </HStack>
      </React.Fragment>}
      {currentStep === 2 && citeType === 'custom' && <React.Fragment>
          <Tabs index={tabIndex}>
              <TabList overflowX={'auto'} css={{
                "&::-webkit-scrollbar": {
                  height: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#9EC3E8",
                  borderRadius: "2px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#2b6cb0",
                }
              }}>
                {citeResources.map((resource, index) => {
                  return (
                    <Tab key={index}
                         color="#333"
                         textAlign={'left'}
                         pl={1}
                         h={pxToRem(58)}
                         onClick={() => {
                           setTabIndex(index)
                         }}

                         _selected={{ color: '#1D8AFF', borderBottom: '2px solid #1D8AFF' }}
                    >
                      <Text
                        maxW={pxToRem(100)}
                         w={pxToRem(100)}
                         whiteSpace={'nowrap'}
                         overflow={'hidden'}
                         textOverflow={'ellipsis'}>
                        {`(${resource.ENTRYTYPE})${resource.title || ''}-${index + 1}`}
                      </Text>
                      {citeResources.length > 1 &&
                          <Image
                              ml={4}
                              onClick={() => {
                                const newRes = citeResources.filter((_, i) => i !== index)
                                setCiteResources(newRes)
                              }}
                              w={pxToRem(18)} src="/static/editor/citation_delete.svg" alt="del reference"/>}

                    </Tab>
                  )
                })}
                  <HStack alignItems={'center'}>
                      <IconButton bg={'transparent'} _hover={{ bg: 'transparent' }} aria-label={''}
                                  onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    const newRes = cloneDeep(citeResources)
                                    newRes.push({
                                      ENTRYTYPE: 'article',
                                    })
                                    setCiteResources(newRes)
                                  }}
                                  icon={<AddIcon w={pxToRem(12)}/>}
                      ></IconButton>
                  </HStack>
              </TabList>

              <TabPanels h={pxToRem(250)} overflowY={'auto'}>
                {citeResources.map((resource, index) => {
                  return (
                    <TabPanel key={index}>
                      <VStack w={'full'} alignItems={'start'}>
                        <HStack w={'full'}>
                          <Text w={pxToRem(120)} whiteSpace={'nowrap'} fontFamily={'Lato'} fontSize={'1rem'} mr={5}>Resource
                            Type</Text>
                          <Select
                            outline={0}
                            _focusVisible={{ boxShadow: 'none' }}
                            sx={{
                              option: {
                                color: '#333',
                              }
                            }}
                            value={resource.ENTRYTYPE} onChange={(e) => {
                            const newRes = cloneDeep(citeResources)
                            newRes[index].ENTRYTYPE = e.target.value
                            setCiteResources(newRes)
                          }}>
                            {resourceTypes.map((res, resIndex) => {
                              return (
                                <option key={resIndex} value={res}>{res}</option>
                              )
                            })}
                          </Select>
                        </HStack>
                        {resourceType[resource.ENTRYTYPE]?.map((field, fieldIndex) => {
                          return (
                            <HStack w={'full'} key={fieldIndex}>
                              <Text w={pxToRem(120)} whiteSpace={'nowrap'} fontFamily={'Lato'} fontSize={'1rem'}
                                    mr={5}>{field.key}</Text>
                              <Input
                                defaultValue={resource[field.key]}
                                onChange={(e) => {
                                  const newRes = cloneDeep(citeResources)
                                  newRes[index][field.key] = e.target.value
                                  setCiteResources(newRes)
                                }}/>
                            </HStack>
                          )
                        })}
                      </VStack>
                    </TabPanel>
                  )
                })}
              </TabPanels>
          </Tabs>
          <HStack w={'full'} justifyContent={'space-between'} mt={9}>
              <Button bg={'#9399AB'} color={'#FFF'} _hover={{ bg: '#9399ab' }} borderRadius={pxToRem(13)} w={pxToRem(120)}
                      mr={3} onClick={() => {
                setCurrentStep(1)
              }}>Back</Button>
              <Button
                  w={pxToRem(120)}
                  bg={'#1D8AFF'}
                  _hover={{ background: '#1D8AFF' }}
                  border={'1px solid #1D8AFF'}
                  borderRadius={pxToRem(13)}
                  color={'#FFFFFF'}
                  isLoading={doneLoading}
                  onClick={() => createCustomBib(citeResources)}
              >
                  Done
              </Button>
          </HStack>
      </React.Fragment>}

    </Box>
  )
}

export default ResourcesUploadCard;