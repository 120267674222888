import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  HStack,
  Text,
  RadioGroup,
  Radio, Image, Modal, ModalOverlay, ModalContent, ModalBody, Flex, useDisclosure, ModalFooter, Box,
} from "@chakra-ui/react";
import { pxToRem } from "../../../utils/editor.ts";
import { AutoComplete } from 'antd';
import type { AutoCompleteProps } from 'antd';
import { useTranslation } from "react-i18next";
import ExportService, { Author } from "../../../services/exportServices.ts";

interface AuthorProps {
  paperId: string
  authors: Author[]
  setAuthors: (authors: Author[]) => void
}

export const Authors: React.FC<AuthorProps> = ({ authors, setAuthors, paperId }) => {

  const [authorType, setAuthorType] = useState("identified");
  const [options, setOptions] = useState<AutoCompleteProps['options']>([]);
  const {
    isOpen: isOpenAdd,
    // onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const { t } = useTranslation()

  useEffect(() => {
    getDefaultNames()
  }, []);

  const addAuthor = () => {
    setAuthors([...authors, { name: "", affiliation: "" }]);
  };

  const handleInputChange = (index: number, field: keyof Author, value: string) => {
    const updatedAuthors = [...authors];
    updatedAuthors[index][field] = value;
    setAuthors(updatedAuthors);
  };

  const getDefaultNames = async () => {
    const res = await ExportService.getAuthorList(paperId)
    setOptions(res)
  }

  return (
    <VStack w={'full'} h={'full'} alignItems={'start'} mt={2}>
      <RadioGroup onChange={setAuthorType} value={authorType} mb={4}>
        <HStack
          sx={{
            '.chakra-radio__control[data-checked]': {
              bg: 'transparent',
              borderColor: '#8E9ABC',
              borderWidth: '1px'
            },
            '.chakra-radio__control[data-checked]::before': {
              bg: '#60D697',
            }
          }}
          gap={4}>
          <Radio
            value="identified">Identified Author</Radio>
          <Radio value="anonymous">Anonymous Author</Radio>
        </HStack>
      </RadioGroup>
      <Text fontWeight="bold" fontSize={pxToRem(14)} fontFamily={'Lato'} color="#333">Paper Metadata</Text>
      <Text fontSize={pxToRem(14)} fontFamily={'Lato'} color="#8E9ABC" mb={4}>Follow the Sequence of Authorship</Text>
      <VStack w={'100%'} h={'full'} alignItems={'start'} mt={2}>
        <VStack w={'full'} maxH={pxToRem(600)} overflow={'auto'} pr={pxToRem(20)} spacing={4} align="start">
          {authors.map((author, index) => (
            <HStack key={index} w="full" spacing={4} align="end">
              <Text color={'#333'} fontSize={pxToRem(16)}>{index + 1}.</Text>
              <FormControl flex={1}>
                <FormLabel color={'#333'} fontSize={pxToRem(16)}>Author Name</FormLabel>
                <AutoComplete
                  value={author.name}
                  options={options}
                  style={{ border: '1px solid #1D8AFF', width: '100%', height: '40px', borderRadius: pxToRem(6) }}
                  onSelect={(value) => handleInputChange(index, 'name', value)}
                  onChange={(value) => handleInputChange(index, 'name', value)}
                  placeholder="Author name"
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel fontSize="sm">Affiliation(s)</FormLabel>
                <Input
                  placeholder="Type Affiliation(s)"
                  value={author.affiliation}
                  borderColor={'#1D8AFF'}
                  borderRadius={pxToRem(6)}
                  _focus={{ borderColor: '#1D8AFF' }}
                  _focusVisible={{ borderColor: '#1D8AFF' }}
                  onChange={(e) => handleInputChange(index, "affiliation", e.target.value)}
                  // onBlur={() => {
                  //   if (index === 0) {
                  //     onOpenAdd()
                  //   }
                  // }}
                />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel color={'#333'} fontSize={pxToRem(16)}>Email</FormLabel>
                <Input
                  placeholder="Type Email"
                  value={author.email}
                  borderColor={'#1D8AFF'}
                  borderRadius={pxToRem(6)}
                  _focus={{ borderColor: '#1D8AFF' }}
                  _focusVisible={{ borderColor: '#1D8AFF' }}
                  onChange={(e) => handleInputChange(index, "email", e.target.value)} />
              </FormControl>
              <FormControl flex={1}>
                <FormLabel color={'#333'} fontSize={pxToRem(16)}>ORCID</FormLabel>
                <Input
                  placeholder="Type ORCID"
                  value={author.orcid}
                  borderColor={'#1D8AFF'}
                  borderRadius={pxToRem(6)}
                  _focus={{ borderColor: '#1D8AFF' }}
                  _focusVisible={{ borderColor: '#1D8AFF' }}
                  onChange={(e) => handleInputChange(index, "orcid", e.target.value)} />
              </FormControl>
            </HStack>
          ))}
        </VStack>
        <Button
          color={'#1D8AFF'}
          bg={'transparent'}
          border={'1px solid #1D8AFF'}
          borderRadius={pxToRem(6)}
          onClick={addAuthor}
          w={pxToRem(145)}
          h={pxToRem(31)}
          m={'auto'}
          mt={pxToRem(20)}
          leftIcon={
            <Image
              bg={'#E0EAFF'}
              borderRadius={pxToRem(8)}
              padding={'2px'}
              w={pxToRem(18)} src={'/static/editor/add.svg'} />
          }>Add Author</Button>
      </VStack>
      <Modal
        isOpen={isOpenAdd}
        onClose={onCloseAdd}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={pxToRem(551)}
          height={pxToRem(345)}
          top={pxToRem(200)}
          borderRadius={pxToRem(24)}
          border={"1px solid #ccc"}
          bg={'#FBFCFF'}
        >
          <ModalBody p={5}>
            <Flex h={'full'} w={'full'} flexDir={"column"} alignItems={'center'} justifyContent={'center'}>
              <Image
                w={pxToRem(56)}
                objectFit="cover"
                src={"/static/doenba-ico-blue.png"}
                mb={pxToRem(24)}
              />
              <Text textAlign={'center'} color={'#333'} fontFamily={'Lato'} fontSize={'1rem'}>
                Do you want to add
                <Box as={'span'} fontWeight={'bold'} mx={2}>
                  Jason Keen, Affiliation Example
                </Box>
                as default author?
                You can always edit your default author in your settings
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <HStack w={'full'} justifyContent={'space-between'}>
              <Button
                bgColor={"#9399AB"}
                width={pxToRem(139)}
                height={pxToRem(48)}
                borderRadius={pxToRem(12)}
                textColor={"white"}
                _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                onClick={() => {
                  onCloseAdd();
                }}
              >
                {t("editor-editormodel-leftBtnCancel")}
              </Button>
              <Button
                bgColor={"#1D8AFF"}
                width={pxToRem(139)}
                height={pxToRem(48)}
                borderRadius={pxToRem(12)}
                textColor={"white"}
                _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                onClick={() => {
                  onCloseAdd();
                }}
              >
                {t("yes-label")}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

