import { CrossReference, OutlineNodeType, PaperOutline, PaperOutlineNode } from "../types/Writer/OutlineNode";

export const getNodeNames = (data: PaperOutline): string[] => {
    const names: string[] = [];

    function traverse(nodes: PaperOutlineNode[] | null[]) {
        nodes.forEach(node => {
            if (node && node.level == 1 && node.name) {
                names.push(node.name);
            }
            if (node && node.children) {
                traverse(node.children);
            }
        });
    }

    traverse(data.nodes);
    return names;
}

export function getCrossReferenceNodes(nodes: PaperOutlineNode[]) {
	const groupedNodes: CrossReference = {
		[OutlineNodeType.Section]: [],
		[OutlineNodeType.Paragraph]: [],
		[OutlineNodeType.Figure]: [],
		[OutlineNodeType.Equation]: [],
		[OutlineNodeType.Table]: [],
	};

	function traverse(node: PaperOutlineNode) {
		if (!node) return;

		// Process paragraph children of section nodes
		if (node.type === OutlineNodeType.Section) {
			groupedNodes[OutlineNodeType.Section].push(node);
		} 
		else if (node.type === OutlineNodeType.Paragraph) {
			groupedNodes[OutlineNodeType.Paragraph].push(node);
		} else if (node.type === OutlineNodeType.Figure) {
			groupedNodes[OutlineNodeType.Figure].push(node);
		} else if (node.type === OutlineNodeType.Equation && node.level) {
			groupedNodes[OutlineNodeType.Equation].push(node);
		} else if (node.type === OutlineNodeType.Table) {
			groupedNodes[OutlineNodeType.Table].push(node);
		}

		// Continue processing children recursively for all node types
		if (node.children && node.children.length > 0 && node.type !== OutlineNodeType.Paragraph) {
			node.children.forEach(traverse);
		}
	}

	// Start traversal from the root nodes
	nodes.forEach(traverse);
	return groupedNodes;
}

