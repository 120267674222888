import { useState } from 'react';
import { HStack, Text, IconButton, Icon } from "@chakra-ui/react";
import { MdLanguage } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { UserDataService } from "../../services/userDataService";


const LangSelectV2 = () => {
  const { i18n } = useTranslation();
  const { user } = useAuth0();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  async function handleChange(lang: string) {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang);
    UserDataService.setLanguageLocal(lang);

    const userId = user?.sub;
    if (user) {
      await UserDataService.updateUserDataLang(userId, { lang });
    }
  }

  return (
    <HStack spacing={2} alignItems="center">
      {/* Globe Icon */}
      <IconButton
        aria-label="Select language"
        icon={<Icon as={MdLanguage} />}
        variant="ghost" _hover={{ background: 'none' }}
        size="sm"
        onClick={() => handleChange(selectedLanguage === 'en' ? 'cn' : 'en')}
      />

      {/* Inline Language Options */}
      <Text
        as="span"
        fontWeight={selectedLanguage === 'en' ? 'bold' : 'normal'}
        color={selectedLanguage === 'en' ? 'black' : 'gray'}
        cursor="pointer"
        onClick={() => handleChange('en')}
      >
        En
      </Text>
      <Text as="span">/</Text>
      <Text
        as="span"
        fontWeight={selectedLanguage === 'cn' ? 'bold' : 'normal'}
		color={selectedLanguage === 'cn' ? 'black' : 'gray'}
        cursor="pointer"
        onClick={() => handleChange('cn')}
      >
        中文
      </Text>
    </HStack>
  );
};

export default LangSelectV2;