
export interface OutlineNode {
	id: number;
	name: string;
	children: Omit<OutlineNode, 'children'>[];
}

export enum OutlineNodeType {
	Section = "section",
	Paragraph = "paragraph",
	Figure = "figure",
	Equation = "equation",
	Table = "table",
}

export interface InlineNode {
	[key: number]: {
		value: string
		type?: OutlineNodeType
		id?: string
		content?: string
	}
}
export interface PaperOutlineNode {
	id: number;
	type: OutlineNodeType;

	// Will have an empty array for all but Section
	children?: PaperOutlineNode[];

	// For Section only
	name?: string;
	level?: number;
	label?: string;
	placeholder?: string;

	// For Paragraph only
	content?: string;

	// For Figure, Equation, Table
	caption?: string;
	parentId?: number

	// refer
	refer?: InlineNode
	// inline-equation
	equation?: InlineNode
	// cite
	cite?: InlineNode
	// footnote
	footnote?: InlineNode
}

export interface PaperOutline {
	nodes: PaperOutlineNode[];
	nextId: number;
	referNextId?: number
	equationNextId?: number
	figureNextId?: number
	tableNextId?: number
}

export interface CrossReference {
	section: PaperOutlineNode[];
	paragraph: PaperOutlineNode[];
	figure: PaperOutlineNode[];
	equation: PaperOutlineNode[];
	table: PaperOutlineNode[];
}
