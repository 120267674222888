import axios from "axios";
import { BASE_URL } from ".";
import { StyleTemplate } from "../components/PaperEditor/Export/ExportProcess.tsx";

export interface Author {
  name: string;
  affiliation: string;
  email?: string;
  orcid?: string;
}

class ExportService {
  /**
   * 获取最近导出的历史记录
   */
  public static async getRecentExportHistory(paperId: string): Promise<{ title: string; templateName: string; exportDate: string }[]> {
    const response = await axios.get(BASE_URL + "/paper/export/history", {
      params: {
        paperId
      }
    });
    return response.data;
  }

  /**
   * 获取所有模板
   */
  public static async getAllTemplates(): Promise<StyleTemplate[]> {
    const response = await axios.get(BASE_URL + "/paper/export/all-templates");
    return response.data;
  }

  /**
   * 获取作者列表
   * @param paperId
   */
  public static async getAuthorList(paperId: string): Promise<{ name: string; id: string }[]> {
    const response = await axios.get(BASE_URL + "/paper/export/author-list", {
      params: {
        paperId
      }
    });
    return response.data;
  }
  /**
   * 生成样式文件
   * @param templateId 模板 ID
   * @param paperId
   * @param authors 元数据（作者信息）
   */
  public static async generatePdf(
    templateId: number,
    paperId: string,
    authors: Author[]
  ): Promise<{
    pdf_url: string
    title: string
    latex_zip_url: string
  }> {
    const response = await axios.post(BASE_URL + "/paper/export/generator", {
     templateId, authors, paperId
    });
    return response.data;
  }

  /**
   * 下载文件
   * @param fileId 文件 ID
   */
  public static async downloadFile(fileId: string): Promise<Blob> {
    const response = await axios.get(BASE_URL + `/paper/download/${fileId}`, { responseType: "blob" });
    return response.data;
  }
}

export default ExportService;
