import { create } from 'zustand';
import { PaperOutline, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";

interface CounterState {
  outline: PaperOutline | null
  nodeList: PaperOutlineNode[]
  nextId: number
  referNextId: number
  equationNextId: number
  figureNextId: number
  tableNextId: number
  currentSectionIndex: number
  currentNodeId: number
  outlineIsChange: boolean
  toolPopOpen: boolean
  addSpecial: boolean
  createOutline: boolean
  setCreateOutline: (createOutline: boolean) => void
  setAddSpecial: (addSpecial: boolean) => void
  setNextId: (newId: number) => void
  setReferNextId: (newId: number) => void
  setTableNextId: (newId: number) => void
  setFigureNextId: (newId: number) => void
  setEquationId: (newId: number) => void
  setCurrentNodeId: (newId: number) => void
  setOutline: (newOutline: PaperOutline | null) => void
  setNodeList: (newNodeList: PaperOutlineNode[]) => void
  setOutlineIsChange: (change: boolean) => void
  setToolPopOpen: (open: boolean) => void
  setCurrentSectionIndex: (newIndex: number) => void
  reset: () => void
}

const useOutlineStore = create<CounterState>((set) => ({
  outline: null,
  nodeList: [],
  nextId: 0,
  referNextId: 0,
  equationNextId: 0,
  currentNodeId: 0,
  figureNextId: 0,
  tableNextId: 0,
  toolPopOpen: false,
  currentSectionIndex: 0,
  outlineIsChange: false,
  addSpecial: false,
  createOutline: false,
  setCreateOutline: (createOutline: boolean) => set(() => ({ createOutline })),
  setAddSpecial: (addSpecial: boolean) => set(() => ({ addSpecial })),
  setNextId: (newId) => set(() => ({ nextId: newId })),
  setCurrentNodeId: (newId) => set(() => ({ currentNodeId: newId })),
  setReferNextId: (newId) => set(() => ({ referNextId: newId })),
  setFigureNextId: (newId) => set(() => ({ figureNextId: newId })),
  setTableNextId: (newId) => set(() => ({ tableNextId: newId })),
  setEquationId: (newId) => set(() => ({ equationNextId: newId })),
  setOutline: (newOutline) => set(() => ({ outline: newOutline })),
  setNodeList: (newNodeList) => set(() => ({ nodeList: newNodeList })),
  setOutlineIsChange: (change) => set(() => ({ outlineIsChange: change })),
  setToolPopOpen: (open) => set(() => ({ toolPopOpen: open })),
  setCurrentSectionIndex: (newIndex) => set(() => ({ currentSectionIndex: newIndex })),
  reset: () => set({
    outline: null,
    nodeList: [],
    nextId: 0,
    referNextId: 0,
    equationNextId: 0,
    currentNodeId: 0,
    figureNextId: 0,
    tableNextId: 0,
    toolPopOpen: false,
    currentSectionIndex: 0,
    outlineIsChange: false,
    addSpecial: false,
  }),
}));

export default useOutlineStore;

