import { create } from 'zustand';
import { OutlineNodeType, PaperOutline } from "../../types/Writer/OutlineNode.ts";
import { BibContent } from "../../types/Writer/BibContent.ts";

export interface CursorInfo {
  target?: HTMLElement | null | Node
  start?: number
  nodeIndex: number // 光标所在行处于nodeList的索引
}

interface CurrentEditInfo {
  id: number | null,
  content: string
  nodeIndex: number
  label?: string
  caption?: string
  type?: OutlineNodeType
}

export interface LatexEditInfo extends CurrentEditInfo {
  inlineType: 'block' | 'inline'
}

interface EditorState {
  currentCursor: CursorInfo | null
  setCurrentCursor: (cursor: CursorInfo | null) => void
  currentLatex: LatexEditInfo | null
  setCurrentLatex: (latex: LatexEditInfo | null) => void
  currentTable: CurrentEditInfo | null
  setCurrentTable: (table: CurrentEditInfo | null) => void
  currentFigure: CurrentEditInfo | null
  setCurrentFigure: (table: CurrentEditInfo | null) => void
  editorHistory: PaperOutline[]
  setEditorHistory: (history: PaperOutline[]) => void
  resources: BibContent[]
  setResources: (resources: BibContent[]) => void
  focusEditor: boolean
  setFocusEditor: (focus: boolean) => void
  addMode: 'blank' | 'content'
  setAddMode: (mode: 'blank' | 'content') => void
  currentFoot: CurrentEditInfo | null
  setCurrentFoot: (foot: CurrentEditInfo) => void
}

const useEditorStore = create<EditorState>((set) => ({
  currentCursor: {
    nodeIndex: -1,
    start: 0,
    target: null
  },
  setCurrentCursor: (cursor: CursorInfo | null) => set({ currentCursor: cursor }),
  currentLatex: {
    id: null,
    content: '',
    label: '',
    nodeIndex: -1,
    inlineType: 'inline'
  },
  currentFigure: {
    id: null,
    content: '',
    label: '',
    nodeIndex: -1,
    caption: '',
  },
  currentTable: {
    id: null,
    content: '',
    label: '',
    caption: '',
    nodeIndex: -1
  },
  editorHistory: [],
  resources: [],
  focusEditor: true,
  addMode: 'blank',
  currentFoot: null,
  setCurrentFoot: (foot: CurrentEditInfo) => set({ currentFoot: foot }),
  setAddMode: (mode: 'blank' | 'content') => set({ addMode: mode }),
  setFocusEditor: (focus: boolean) => set({ focusEditor: focus }),
  setEditorHistory: (history: PaperOutline[]) => set({ editorHistory: history }),
  setCurrentTable: (table: CurrentEditInfo | null) => set({ currentTable: table }),
  setCurrentLatex: (latex: LatexEditInfo | null) => set({ currentLatex: latex }),
  setCurrentFigure: (figure: CurrentEditInfo | null) => set({ currentFigure: figure }),
  setResources: (resources: BibContent[]) => set({ resources: resources })
}))

export default useEditorStore;

