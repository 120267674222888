import React, { useContext, useMemo, useState, useEffect } from 'react';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table, Input } from 'antd';
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { generatorTableHtml, parseTableHtml, pxToRem } from "../../../utils/editor.ts";
import {
  Button, Flex, Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter, ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import useEditorStore from "../../../stores/modules/editor.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { useEditorHook } from "../../../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";

interface DataType {
  key: string;
  [key: string]: string | number
}

interface RowContextProps {
  setActivatorNodeRef?: (element: HTMLElement | null) => void;
  listeners?: any;
}
interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}
const PLUS_COL_KEY = "plus-col";
const RowContext = React.createContext<RowContextProps>({});
/**
 * cell component
 * @constructor
 */
const EditableCell: React.FC<React.PropsWithChildren<any>> = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const save = async (value: string) => {
    try {
      record[dataIndex] = value
      handleSave({ ...record });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = (
        <Input style={{ paddingLeft: 0 }}
          defaultValue={record[dataIndex]}
          onChange={(e) => {
            save(e.target.value)
          }} />
    )
  }

  return <td {...restProps}>{childNode}</td>;
};
/**
 * row render
 * @param props
 * @constructor
 */
const Row: React.FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  const contextValue = useMemo<RowContextProps>(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners],
  );
  return (
      <RowContext.Provider value={contextValue}>
            <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
  );
};

interface TableModalProps {
  isOpenModal: boolean
  closeModal: () =>  void
  initGrid: {
    row: number
    col: number
  }
  setInitGrid: (grid: {
    row: number
    col: number
  }) => void
}

export const TableModal: React.FC<TableModalProps> = ({ isOpenModal, closeModal, initGrid }) => {
  const { currentCursor } = useEditorStore()
  const { nodeList, setNodeList, setAddSpecial, setOutlineIsChange } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const { currentTable, setCurrentTable } = useEditorStore()
  const [caption, setCaption] = useState<string>('')

  useEffect(() => {
    if (initGrid.row) {
      setDataSource(Array.from({ length: initGrid.row }, (_, i) => ({ key: (i + 1).toString() })))
    }
    if (initGrid.col) {
      setColumns(Array.from({ length: initGrid.col }, (_, i) => `column${i + 1}` ))
    }
  }, [])

  useEffect(() => {
    if (currentTable?.id) {
      const { columns, data } = parseTableHtml(currentTable.content)
      setColumns(columns)
      setDataSource(data)
      setCaption(currentTable.caption)
    }
  }, [currentTable])
  /**
   * 编辑实时保存
   * @param row
   */
  const handleSave = (row: DataType) => {
    const newData = cloneDeep(dataSource);
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    if (index > -1) {
      newData[index] = { ...item, ...row };
      setDataSource([...newData]);
    }
  };
  const cleanColumnsAndDataSource = (columns, dataSource)=> {
    const columnsToKeep = columns.filter((col) => {
      return dataSource.some((row) => {
        const value = row[col];
        // 非空值：不为空字符串、不是 undefined 也不是 null
        return value !== "" && value !== undefined && value !== null;
      });
    });

    const cleanedDataSource = dataSource.map((row) => {
      const newRow = { ...row };
      columns.forEach((col) => {
        if (!columnsToKeep.includes(col)) {
          delete newRow[col];
        }
      });
      return newRow;
    });

    const finalDataSource = cleanedDataSource.filter((row) => {
      const values = Object.keys(row)
        .filter((key) => key !== "key")
        .map((key) => row[key]);
      return values.some((val) => val !== "" && val !== undefined && val !== null);
    });

    return {
      columns: columnsToKeep,
      dataSource: finalDataSource,
    };
  }
  /**
   * 编辑完成，确认插入
   */
  const confirmHandler = () => {
    try {
      const data = cleanColumnsAndDataSource(columns, dataSource)
      const content = generatorTableHtml(data.columns, data.dataSource)
      closeModal()
      if (currentTable?.id) {
        const newNodeList = cloneDeep(nodeList)
        newNodeList[currentTable.nodeIndex].content = content
        newNodeList[currentTable.nodeIndex].caption = caption
        setNodeList([...newNodeList])
        setOutlineIsChange(true)
        setAddSpecial(true)
        setCurrentTable(null)
        setCaption('')
        console.log(12)
      } else {
        addNewNode(nodeList[currentCursor.nodeIndex], OutlineNodeType.Table,
          nodeList[currentCursor.nodeIndex]?.level, content, undefined, caption)
        setCaption('')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex((record) => record.key === active.id);
      const overIndex = dataSource.findIndex((record) => record.key === over.id);

      if (activeIndex !== -1 && overIndex !== -1) {
        const updatedData = arrayMove(dataSource, activeIndex, overIndex);
        setDataSource(updatedData);
      }
    }
  }

  /**
   * 添加列
   */
  const addColumn = () => {
    const newColName = `Column ${columns.length + 1}`;
    setColumns([...columns, newColName]);
    const newTableData: any = dataSource.map((row: any) => {
      return {
        ...row,
        [newColName]: '',
      }
    });

    setDataSource(cloneDeep(newTableData));
  }
  /**
   * plus col icon
   */
  const plusColumn: any = {
    title: (
      <IconButton
        aria-label="Add column"
        icon={<AddIcon />}
        variant="ghost"
        size="xs"
        onClick={addColumn}
      />
    ),
    dataIndex: PLUS_COL_KEY,
    key: PLUS_COL_KEY,
    width: 40,
    render: () => {
      return null;
    },
  };
  /**
   * 拖拽句柄
   */
  const DragHandle: React.FC<{
    rowIndex: number
  }> = ({ rowIndex }) => {
    const { setActivatorNodeRef, listeners } = useContext(RowContext);
    return (
        <Flex gap={1}>
          <IconButton
            type="text"
            size="small"
            bg={'transparent'}
            icon={
              <PiDotsSixVerticalBold
                style={{ width: pxToRem(20), height: pxToRem(20) }}
              />
            }
            style={{ cursor: 'move' }}
            ref={setActivatorNodeRef}
            {...listeners}
          />
          <IconButton
            alignSelf={'start'}
            aria-label="delete row"
            icon={<DeleteIcon />}
            variant="ghost"
            pl={5}
            size="xs"
            onClick={() => {
              const newDataSource = dataSource.filter((_, i) => i !== rowIndex);
              setDataSource(cloneDeep(newDataSource));
            }}
          />
        </Flex>
    );
  };
  const updatedColumns: any = [
    {
      title: '',
      dataIndex: 'dragHandle',
      render: (_value, _record, index) => <DragHandle rowIndex={index} />,
      fixed: 'left',
      width: 80,
    },
    ...columns.map((col: string, index: number) => ({
      title: (
        <Flex gap={1} justifyContent={'space-between'} alignItems={'center'}>
          <Input
            defaultValue={col}
            onChange={(e) => { // 失焦会影响delete
              const newColumns = [...columns];
              const colIndex = columns.indexOf(col);
              newColumns[colIndex] = e.target.value;
              setColumns(newColumns);
            }}
          />
          <IconButton
            alignSelf={'start'}
            aria-label="delete column"
            icon={<DeleteIcon />}
            variant="ghost"
            size="xs"
            onClick={() => {
              // 删除当前列
              const newColumns = columns.filter((_, i) => i !== index);
              setColumns(cloneDeep(newColumns));
              // 可选：同步更新 dataSource，去掉对应的字段
              // const newDataSource = dataSource.map((row) => {
              //   const newRow = { ...row };
              //   delete newRow[col]; // 删除该列对应的字段
              //   return newRow;
              // });
              // setDataSource(newDataSource);
            }}
          />
        </Flex>
      ),
      dataIndex: col,
      editable: true,
      onCell: (record: DataType) => ({
        record,
        editable: true,
        column: col,
        dataIndex: col,
        children: record[col],
        handleSave,
      }),
      fixed: index === 0 ? 'left' : undefined, // 保证 DragHandle 固定
    })),
    plusColumn
  ]
  const closeModalHandler = () => {
    closeModal()
    setCurrentTable(null)
    setCaption('')
  }
  return (
      <Modal isOpen={isOpenModal} onClose={closeModalHandler}
             closeOnOverlayClick={false}>
        <ModalOverlay bg={'whiteAlpha.500'}/>
        <ModalContent
            position={'fixed'}
            minW={pxToRem(1000)} minHeight={"320px"}
            bg={'#FBFCFF'}
            boxShadow={'none'}
            top={150}
            border={'2px solid #D3D7E2'}
            borderRadius={pxToRem(12)}
            onClick={(e) => {
              e.stopPropagation()
            }}>
          <ModalHeader>
            <Heading size={'md'} mb={1}>Insert Table</Heading>
          </ModalHeader>
          <ModalCloseButton
              _focusVisible={{ boxShadow: 'none' }}
              bg={'#D3D7E2'}
              width={pxToRem(24)}
              height={pxToRem(24)}
              color={'#8E9ABC'}
              top={pxToRem(20)}
              right={pxToRem(20)}
              fontSize={'8px'}
              borderRadius={'50%'}
              onClick={() => {
                setCurrentTable(null)
                setCaption('')
              }}
          />
          <ModalBody
              display={'flex'}
              bg={'#FBFCFF'}
              justifyContent={'center'}
              flexDir={'column'}
              pb={pxToRem(20)}
              borderRadius={pxToRem(12)}>
            <Flex mt={4} alignItems={'center'} mb={4} gap={2}>
              Caption:
              <Input
                value={caption}
                onChange={(e) => {
                  setCaption(e.target.value)
                }}
              ></Input>
            </Flex>
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
              <SortableContext items={dataSource.map((i) => i.key)} strategy={verticalListSortingStrategy}>
                <Table<DataType>
                    rowKey="key"
                    pagination={false}
                    components={{ body: { row: Row, cell: EditableCell } }}
                    columns={updatedColumns}
                    dataSource={dataSource}
                    scroll={{ x: 'max-content' }}
                />
              </SortableContext>
            </DndContext>
            <IconButton
              alignSelf={'start'}
              aria-label="Add row"
              icon={<AddIcon />}
              variant="ghost"
              pl={5}
              size="xs"
              onClick={() => {
                dataSource.push({
                  key: (dataSource.length + 1).toString(),
                })
                setDataSource([...dataSource])
              }}
            />
          </ModalBody>
          <ModalFooter p={8}>
            <Button  w={pxToRem(120)} mr={3} onClick={closeModalHandler}>Close</Button>
            <Button
                w={pxToRem(120)}
                bg={'#1D8AFF'}
                _hover={{ background: '#1D8AFF' }}
                border={'1px solid #1D8AFF'}
                borderRadius={pxToRem(12)}
                color={'#FFFFFF'}
                onClick={confirmHandler}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  );
};
