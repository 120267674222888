import React from "react";
import { Button, Image, Menu, MenuButton, MenuItem, MenuList, Portal } from "@chakra-ui/react";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { pxToRem } from "../../utils/editor.ts";
import { useTranslation } from "react-i18next";
import { PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import useEditorStore from "../../stores/modules/editor.ts";

interface EditListItemIconProps {
  node: PaperOutlineNode
  sectionRoot: PaperOutlineNode
  onOpenDeleteConfirmPanel: () => void
  setInputArgs: (newTitle: string, label: string, position: number, node?: PaperOutlineNode) => void
}

export const EditListItemIcon: React.FC<EditListItemIconProps> = (
  {
    node,
    sectionRoot,
    setInputArgs,
    onOpenDeleteConfirmPanel
  }
) => {
  const { t } = useTranslation()
  const { setFocusEditor } = useEditorStore()
  return (
    <Menu matchWidth={true} offset={[32, -39]}>
      <MenuButton height={"100%"} width={pxToRem(32)} pl={0}
                  onClick={(e) => {
                    e.stopPropagation()
                    setFocusEditor(false)
                  }}>
        <PiDotsThreeVerticalBold
          color={sectionRoot?.id === node.id ? "white" : "#8E9ABC"}
        />
      </MenuButton>
      <Portal>
        <MenuList
          border={"1px solid #cccccc"}
          minWidth={93}
          bgSize={"xs"}
          maxBlockSize={pxToRem(100)}
          backgroundSize={"sm"}
        >
          <MenuItem
            as={Button}
            _hover={{ bgColor: "#3898FF61" }}
            borderRadius={10}
            justifyContent="flex-start"
            textAlign="left"
            pl={0.5}
            onClick={(e) => {
              setInputArgs(node.name, node.label, e.pageY - 95, node);
            }}
            leftIcon={
              <Image
                sizes="xs"
                w={pxToRem(24)}
                objectFit="cover"
                src="/static/editor/edit2.png"
                alt="no draft"
              />
            }
            fontSize={14}
            textColor={"black"}
          >
            {t("editor-editormodel-treeRename")}
          </MenuItem>
          <MenuItem
            as={Button}
            pl={0.5}
            _hover={{ bgColor: "#3898FF61" }}
            borderRadius={10}
            onClick={onOpenDeleteConfirmPanel}
            justifyContent="flex-start"
            textAlign="left"
            leftIcon={
              <Image
                w={pxToRem(24)}
                objectFit="cover"
                src="/static/editor/delete.png"
                alt="no draft"
              />
            }
            textColor={"black"}
            fontSize={14}
          >
            {t("editor-editormodel-treeDelete")}
          </MenuItem>
        </MenuList>
      </Portal>
        
    </Menu>
  )
}