import { Box, Checkbox, Flex, Image, IconButton, Tag, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { BibContent } from '../../types/Writer/BibContent';
import { pxToRem } from "../../utils/editor.ts";

type CitationItemRowProps = {
    resource: BibContent,
    selectedResource: BibContent[]
    resourceOnDelete: () => void,
    resourceOnSelection: (resource: BibContent) => void
}

const CitationItemRow: React.FC<CitationItemRowProps> = ({ resource, resourceOnDelete, resourceOnSelection, selectedResource }) => {
    return (
        <Flex flexDir={'row'} justifyContent={'space-between'} py={1}>
            <Flex flexDir={'row'} alignItems={'center'}>
                <Checkbox
                  sx={{
                    '.chakra-checkbox__label span': {
                      textDecoration: 'underline',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: pxToRem(160),
                      fontSize: pxToRem(15),
                      display: 'inline-block'
                    }
                  }}
                    isChecked={selectedResource.includes(resource)}
                    onChange={() => resourceOnSelection(resource)} mr={2}>
                  <Tooltip placement={'left'} hasArrow padding={1} bg={'#FFF'} color={'#333'} label={`(${resource.ID}) ${resource.title}`}>
                    {`(${resource.ID}) ${resource.title}`}
                  </Tooltip>
                </Checkbox>
                {resource.count && <Tag size={'sm'} variant={'outline'} ml={1} colorScheme='brand'>{resource.count}</Tag>}
            </Flex>
            <Box>
                <IconButton
                  aria-label={'Delete button'}
                  bg={'transparent'}
                  _hover={{ bg: 'transparent' }}
                  onClick={resourceOnDelete}
                  icon={<Image w={pxToRem(18)} src="/static/editor/citation_delete.svg" alt="Refer button" />}
                >
                </IconButton>
            </Box>
        </Flex>
    )
}

export default CitationItemRow;
