import { Flex, Heading, Box, Image } from "@chakra-ui/react";
import VideoCarousel from "../VideoCarousel/VideoCarousel";
import FeatureCards from "./FeatureCards";
import { useTranslation } from "react-i18next";

const FeatureWrapper: React.FC = () => {
	const { t } = useTranslation();

    return (
        <Box w={'full'} h={'full'} pos={'absolute'} left={0} top={0} bg={'white'} p={5} overflow={'auto'}>
            <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} mb={12}>
                <Box mr={2}>
                    <Image
                        w={'120px'}
                        objectFit='cover'
                        src='/static/doenba-logo.svg'
                        alt='Dooyeed'
                    />
                </Box>
            </Flex>
            <FeatureCards />
            <Box my={5} p={10}>
                <Heading as="h2" size="lg" mb={5}>
                    {t('demo-videos')}
                </Heading>
                <VideoCarousel />
            </Box>
        </Box>
    )
}

export default FeatureWrapper;