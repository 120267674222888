import React, { useEffect, useState } from "react";
import { Box, Flex, IconButton, Image, Text, Tooltip, } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/editor.ts";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { RephraseResult } from "../../../types/Writer/RephraseSentencesResult.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import { cloneDeep, find } from "lodash";
import { MathJax } from "better-react-mathjax";
import { TableRender } from "../InserTool/TableRender.tsx";
import parse from "html-react-parser";

interface RephraseProps {
  result: RephraseResult
}

export const Rephrase: React.FC<RephraseProps> = (
  {
    result
  }
) => {
  const [renderContent, setRenderContent] = useState(null)
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()

  useEffect(() => {
    const content = result?.select_content_rephrased_result

    if (content?.indexOf('($') > -1) {
      const node = find(nodeList, { id: result.id })
      const replacedContent = result.select_content_rephrased_result.split(/(\(\$\w+\{\d+}\$\))/).map((part, index) => {
        const match = part.match(/\$(\w+){(\d+)}\$/);
        // let length = part.length
        if (match) {
          const id = Number(match[2]);
          const slotItem = node[match[1]][id];
          if (slotItem) {

            const content = '($' + slotItem.value + '$)'
            return (
              <React.Fragment key={`${index}-${id}`}>
                {match[1] === 'equation' && <Box
                    as="span"
                    mx={'5px'}
                    _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                    data-content={part}
                    data-id={id}
                    data-type={match[1]}>
                    <MathJax inline>{content}</MathJax>
                </Box>}
                {match[1] === 'footnote' && <Box
                    as="span"
                    mx={'5px'}
                    _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                    data-content={part}
                    data-type={match[1]}
                    data-id={id}
                    fontSize={pxToRem(14)}
                    color={'#224ea8'}
                    fontStyle={'italic'}>
                  {slotItem.value}
                </Box>}
                {match[1] === 'cite' && <Box
                    color={'blue.500'}
                    as="span"
                    mx={'5px'}
                    _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                    data-content={part}
                    data-id={id}
                    data-type={match[1]}>
                    <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={slotItem.value}
                             aria-label='Description'>
                      {`[${slotItem.id}]`}
                    </Tooltip>
                </Box>}
                {match[1] === 'refer' &&
                    <Box color={'blue.500'}
                         as="span"
                         mx={'5px'}
                         _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                         data-inline={true}
                         data-content={part}
                         data-id={id}
                         data-type={match[1]}>
                      {slotItem.type === OutlineNodeType.Figure &&
                          <Tooltip hasArrow padding={0} label={slotItem.content?.indexOf('base64') > -1 ?
                            <Image width="200px" alt="Reference Preview" src={slotItem.content}/>
                            : <Image width="200px" alt="Reference Preview"
                                     src={'data:image/png;base64,' + slotItem.content}/>
                          } aria-label='Description'>
                            {`[${slotItem.value}]`}
                          </Tooltip>}
                      {slotItem.type === OutlineNodeType.Equation &&
                          <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={
                            <MathJax inline>(${slotItem.content}$)</MathJax>
                          } aria-label='Description'>
                            {`[${slotItem.value}]`}
                          </Tooltip>}
                      {slotItem.type === OutlineNodeType.Table &&
                          <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={
                            <TableRender content={slotItem.content}/>
                          } aria-label='Description'>
                            {`[${slotItem.value}]`}
                          </Tooltip>}
                      {slotItem.type === OutlineNodeType.Section && `[${slotItem.value}]`}
                    </Box>}
                &#xFEFF;
              </React.Fragment>
            )
          }
        }
        if (part.indexOf('<strong') > -1 || part.indexOf('<em') > -1 || part.indexOf('<u') > -1) {
          return <React.Fragment key={index}>{parse(part)}</React.Fragment>;
        }
        return <React.Fragment key={`${content}-${index}`}>{part?.replace(/\uFEFF/g, '')}</React.Fragment>;
      })
      setRenderContent(replacedContent)
    }
  }, [])
  return (<>
      <Text
        width={pxToRem(248)}
        aria-multiline={true}
        fontSize={pxToRem(14)}
        fontWeight={400}
      >
        {renderContent ? renderContent : result.select_content_rephrased_result}
      </Text>
      <Flex width={pxToRem(248)}
            alignItems={"center"}
            justifyContent={"start"}>
        <IconButton
          mr={"10px"}
          size={"sm"}
          minWidth={pxToRem(16)}
          width={pxToRem(16)}
          bg="transparent"
          p={0}
          _hover={{ bg: 'transparent' }}
          icon={
            <Image
              w={pxToRem(16)}
              objectFit="cover"
              src={"/static/ToolAI/useContent.png"}
            />
          }
          aria-label="replace"
          onClick={(e) => {
            e.stopPropagation()
            if (!result.replaced) {
              const newNodeList = cloneDeep(nodeList)
              const node = find(newNodeList, { id: result.id })
              switch (node.type) {
                case OutlineNodeType.Section: {
                  node.name = node.name.replace(result.select_content, result.select_content_rephrased_result)
                  break
                }
                case OutlineNodeType.Paragraph: {
                  // node.content = node.content.replace(result.select_content,
                  //         `<span class="replacedEffect">${result.select_content_rephrased_result}</span>`)
                  node.content = node.content.replace(result.select_content, result.select_content_rephrased_result)
                  break
                }
              }
              result.replaced = true
              setOutlineIsChange(true)
              setNodeList([...newNodeList]) // todo: 这里修改的nodeList，导致updateOutline中比较实际文本那里没有设置成功outlineIsChange为true
              window.getSelection()?.removeAllRanges()
            }
          }}
        />
        {result.replaced && <CheckCircleIcon
            sx={{
              color: 'hsla(128, 71%, 50%, 0.8)'
            }}/>}
      </Flex>
    </>
  )
}