import React, { useRef } from "react";
import {
  Box,
  Button, Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter, ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-textmate";
import "katex/dist/katex.min.css";
import { crossRefType, handleInlineCursor, insertContentToDom, pxToRem } from "../../../utils/editor.ts";
import useEditorStore from "../../../stores/modules/editor.ts";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { useEditorHook } from "../../../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";

interface InsertFootProps {
  isOpenModal: boolean
  closeModal: () =>  void
}

export const InsertFoot: React.FC<InsertFootProps> = ({ isOpenModal, closeModal }) => {
  const { currentCursor, currentFoot, setCurrentFoot } = useEditorStore()
  const { nodeList, setNodeList } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const editorRef = useRef(null);

  /**
   * add foot to editor
   */
  const confirmAddFoot = () => {
    closeModal()
    if (currentFoot.id) { // 编辑
      const newNodeList = cloneDeep(nodeList)
        const node = newNodeList[currentFoot.nodeIndex]
        node.footnote[currentFoot.id] = {
          value: currentFoot.content
        }
      setNodeList([...newNodeList])
    } else { // 新增
      const newNodeList = cloneDeep(nodeList)
      const node = newNodeList[currentCursor.nodeIndex]
        if (!node.footnote) {
          node.footnote = {}
        }
        const id = Object.keys(node.footnote)?.length + 1
        node.footnote[id] = {
          value: currentFoot.content
        }
        const slotContent = `($footnote{${id}}$)`
        if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) { // 碰到块级的直接下一行
          addNewNode(node, OutlineNodeType.Paragraph, 2, slotContent)
        } else {
          const key = node.type === OutlineNodeType.Paragraph ? 'content' : 'name'
          node[key] = insertContentToDom(node, currentCursor, slotContent).content
          setNodeList([...newNodeList])
        }
        setTimeout(() => {
          handleInlineCursor(nodeList[currentCursor.nodeIndex], slotContent)
        }, 300)
      
    }
    setCurrentFoot(null)
  }

  const closeModalHandler = () => {
    closeModal()
    setCurrentFoot(null)
  }

  return (
    <Modal isOpen={isOpenModal} onClose={closeModalHandler}
           closeOnOverlayClick={false}>
      <ModalOverlay bg={'whiteAlpha.500'}/>
      <ModalContent
        position={'fixed'}
        minW={pxToRem(600)} minHeight={"220px"}
        bg={'#FBFCFF'}
        boxShadow={'none'}
        top={150}
        border={'2px solid #D3D7E2'}
        borderRadius={pxToRem(12)}>
        <ModalHeader>
          <Heading size={'md'} mb={1}>Insert Footnote</Heading>
        </ModalHeader>
        <ModalCloseButton
          _focusVisible={{ boxShadow: 'none' }}
          bg={'#D3D7E2'}
          width={pxToRem(24)}
          height={pxToRem(24)}
          color={'#8E9ABC'}
          top={pxToRem(20)}
          right={pxToRem(20)}
          fontSize={'8px'}
          borderRadius={'50%'} />
        <ModalBody
          display={'flex'} p={2}
          bg={'#FBFCFF'}
          borderRadius={pxToRem(12)}>
          { currentFoot && <Box px={2} pb={0} w={pxToRem(600)}>
              <Box  width={'100%'}
                    height={pxToRem(150)}
                    p={2}
                    border="1px solid #D3D7E2"
              >
                {/* ace math editor */}
                  <AceEditor
                      ref={editorRef}
                      mode="latex"
                      theme="textmate"
                      value={currentFoot.content}
                      onChange={(value) => {
                        setCurrentFoot({
                          ...currentFoot,
                          content: value
                        })
                      }}
                      fontSize={16}
                      width={'100%'}
                      height={'100%'}
                      showGutter={false}
                      focus={true}
                      setOptions={{ showLineNumbers: false }}
                  />
              </Box>
              <ModalFooter p={2}>
                  <Button  w={pxToRem(120)} mr={3} onClick={closeModalHandler}>Close</Button>
                  <Button
                      w={pxToRem(120)}
                      bg={'#1D8AFF'}
                      _hover={{ background: '#1D8AFF' }}
                      border={'1px solid #1D8AFF'}
                      borderRadius={pxToRem(12)}
                      color={'#FFFFFF'}
                      onClick={confirmAddFoot}
                  >
                      Confirm
                  </Button>
              </ModalFooter>
          </Box>}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

