import React, { useRef, useState } from 'react';
import { Flex, Box, Image, Text, Button, VStack, useToast } from '@chakra-ui/react';
import DonebaSpinner from "../DoenbaSpinner/DoenbaSpinner.tsx";
import { OutlineService } from "../../services";
import { ClientError } from "../../utils/clientError.ts";
import { PaperOutline } from "../../types/Writer/OutlineNode.ts";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../utils/editor.ts";

/**
 * file upload, used in create
 */
interface FileUploadProps {
  flexDir?: 'row' | 'column'
  usage?: 'draft' | 'reference'
  border?: boolean
  uploadText?: string
  afterUpload: (outline: PaperOutline) => void;
  setShowModalFooter?: (show: boolean) => void
}

enum FileType {
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  TXT = 'text/plain'
}

export const FileUpload: React.FC<FileUploadProps> = ({
                                                        border,
                                                        usage,
                                                        uploadText,
                                                        flexDir,
                                                        afterUpload,
                                                        setShowModalFooter
                                                      }) => {

  const [uploading, setUploading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const { t } = useTranslation();
  const [controller ,setController] = useState<AbortController>(new AbortController())
  const toast = useToast();
  const [dragOver, setDragOver] = useState(false);

  const handleFileUploadOnClick = () => {
    if (uploading) {
      setUploading(false)
    }
    if (inputRef.current) {
      inputRef.current.click();
    }
  };


  /**
   * validate file type
   * @param file
   */
  function isValidFileType(file: File): boolean {
    const allowedTypes = [
      FileType.PDF,
      FileType.DOC,
      FileType.DOCX,
      FileType.TXT
    ]
    const isTypeValid = allowedTypes.includes(file.type as FileType)
    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.txt)$/i
    const isExtensionValid = allowedExtensions.test(file.name)

    return isTypeValid && isExtensionValid
  }

  /**
   * upload file adn extract outline
   * @param event
   */
  const handlerUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (isValidFileType(file)) {
        setUploading(true);
        setTimeout(() => {
          setIsProcessing(true)
        }, 800) // process after upload 800ms
        setShowModalFooter && setShowModalFooter(false)
        try {
          // usage draft reference
          const newController = new AbortController();
          setController(newController)
          const signal = newController.signal
          OutlineService.extractOutline(file, usage, signal).then(
            res => {
              afterUpload(res.outline)
              resetInit()
            }
          ).catch(() => {
            resetInit()
          })
        } catch (error) {
          resetInit()
          new ClientError(error).toast()
        }
      } else {
        toast({
          title: "Upload Error",
          description: t(
            'editor-editormodel-leftTipUploadFile'
          ),
          status: "warning",
          duration: 1000,
        });
      }
    } else {
      setUploading(false);
      alert('Please upload a valid article file');
    }

    event.target.value = null
  };

  /**
   * cancel upload reference
   */
  const handleCancelUpload = ()=> {
    setUploading(false)
    controller.abort()
    setShowModalFooter && setShowModalFooter(true)
  }

  const resetInit = () => {
    setUploading(false)
    setIsProcessing(false)
    setShowModalFooter && setShowModalFooter(true)
  }

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  }

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  }

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handlerUpload({ target: { files: e.dataTransfer.files } } as React.ChangeEvent<HTMLInputElement>);
      e.dataTransfer.clearData();
    }
  }

  return (
    <Flex borderRadius={8}
          w={'full'}
          background={dragOver ? "#E3F2FD" : "transparent"}
          flexDir={flexDir} alignItems="center">
      <Box
        as="button"
        onClick={handleFileUploadOnClick}
        cursor="pointer"
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver }
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        padding={border ? 10 : 0}
      >
        {!uploading &&
            <VStack w={"full"} p={0}>
                <Box mb={2}>
                    <Image
                        w={usage === 'draft' ? pxToRem(40) : pxToRem(50)}
                        objectFit="cover"
                        src="/static/editor/export.png"
                        alt="Doenba"
                    />
                </Box>
                <Text color="#8E9ABC"
                      fontSize={pxToRem(16)}
                      fontFamily={'Lato'}>
                  {uploadText || 'Drag to Upload your saved content' }</Text>
                <Text
                    mt={2}
                    fontSize={pxToRem(14)}
                    fontFamily={'Lato'}
                    color="#8E9ABC"
                    textAlign="center"
                >
                  {t('editor-editormodel-leftTipUploadFile')}
                </Text>
            </VStack>
        }
        {uploading &&
            <Flex flexDir={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}>
                <DonebaSpinner/>
              {!isProcessing ? <Text mt={5} color={'gray.400'}>{t('file-uploading-text')}</Text>
                : <Text mt={5} color={'gray.400'}>{t('file-processing-text')}</Text>}
            </Flex>
        }
        <input
          ref={inputRef}
          type="file"
          accept=".pdf,.doc,.docx,.txt"
          onChange={handlerUpload}
          style={{ display: "none" }}
        />
      </Box>

      {!uploading && <Button
        size="sm"
        width="120px"
        mt={4}
        colorScheme="brand"
        _hover={{ bg: '#1d8aff' }}
        onClick={handleFileUploadOnClick}
      >
        Select
      </Button>}
      {
        uploading && <Button
              size="sm"
              width="120px"
              mt={4}
              variant={'outline'}
              colorScheme="brand"
              borderColor={'#1d8aff'}
              color={'#FFF'}
              bg={'#1d8aff'}
              _hover={{ bg: '#1d8aff' }}
              onClick={handleCancelUpload}
          >
              Cancel
          </Button>
      }
    </Flex>
  );
};

export default FileUpload;
