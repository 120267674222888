// ExportProcess.tsx
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  HStack,
  VStack, Image, Flex, useToast, Tooltip
} from '@chakra-ui/react';
import { CheckIcon, SearchIcon } from '@chakra-ui/icons';
import { pxToRem } from "../../../utils/editor.ts";
import { Authors } from "./Authors.tsx";
import { ExportHistory } from "./ExportHistory.tsx";
import ExportService, { Author } from "../../../services/exportServices.ts";

interface ExportProcessProps {
  paperId?: string
  setPreviewUrl: (url: string) => void
  setLatexUrl: (url: string) => void
  setPdfName: (url: string) => void
  setIsTemplate: (isTemplate: boolean) => void
}

export interface StyleTemplate {
  id: number
  thumbnailImageUrl: string
  templateName: string
  samplePdfUrl: string
  description: string
}
const ExportProcess: React.FC<ExportProcessProps> = ({ paperId, setPreviewUrl, setPdfName, setIsTemplate, setLatexUrl }) => {
  const [selectedStyle, setSelectedStyle] = useState<StyleTemplate | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [generator, setGenerator] = useState<'pending' | 'resolve' | 'reject'>('pending');
  const toast = useToast();
  const [templates, setTemplates] = useState<StyleTemplate[]>([]);
  const [currentTrPar, setCurrentTrPar] = useState<number | null>(null);
  const [authors, setAuthors] = useState<Author[]>([
    { name: "", affiliation: "", email: '', orcid: '' },
    { name: "", affiliation: "", email: '', orcid: '' },
    { name: "", affiliation: "", email: '', orcid: '' },
    { name: "", affiliation: "", email: '', orcid: '' },
  ]);
  useEffect(() => {
    getTemplates()
  }, []);

  const steps = [
    { title: 'Select Style' },
    { title: 'Paper Metadata' },
    { title: 'Export' },
  ];
  async function getTemplates() {
    const res = await ExportService.getAllTemplates()
    setTemplates(res)
  }

  /**
   * generator pdf
   */
  async function generatePdf() {
    setGenerator('pending')
    try {
      const res = await ExportService.generatePdf(selectedStyle.id, paperId, authors.filter(a => a.name !== ''))
      if (res.pdf_url) {
        setPreviewUrl(res.pdf_url)
        setLatexUrl(res.latex_zip_url)
        setPdfName(res.title)
        setGenerator('resolve')
      } else {
        setGenerator('reject')
      }
    } catch (error) {
      setGenerator('reject')
    }
  }

  return (
    <Box
      borderRadius={pxToRem(24)}
      border={'1px solid #D3D7E2'}
      w={'60%'}
      h={"full"}
      bg="#FBFCFF"
      px={pxToRem(32)}
      py={pxToRem(26)}
    >
      <VStack justifyContent="space-between" h={"full"} alignItems="inherit">
        <Text fontSize={pxToRem(20)} fontWeight={'bold'} fontFamily={'Lato'}>
          Export
        </Text>

        {currentStep === 0 && <VStack flex={1}>
          {/* Search and Upload Section */}
          <HStack w={'full'} mt={pxToRem(20)} mb={pxToRem(24)} justify="space-between">
            <InputGroup w={'80%'}>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="#1D8AFF" />
              </InputLeftElement>
              <Input
                  borderColor={'#8E9ABC'}
                  borderRadius={pxToRem(24)}
                  _focusVisible={{ borderColor: '#1D8AFF' }}
                placeholder="Search Conference Paper / Journal Export Style"
                variant="outline"
              />
            </InputGroup>
            {/*<Button*/}
            {/*  color={'#1D8AFF'}*/}
            {/*  bg={'transparent'}*/}
            {/*  border={'1px solid #1D8AFF'}*/}
            {/*  borderRadius={pxToRem(6)}*/}
            {/*  _hover={{ bg: 'transparent' }}*/}
            {/*  leftIcon={*/}
            {/*  <Image*/}
            {/*    bg={'#E0EAFF'}*/}
            {/*    borderRadius={pxToRem(8)}*/}
            {/*    padding={'2px'}*/}
            {/*    w={pxToRem(18)} src={'/static/editor/upload.svg'} />*/}
            {/*}>Upload Paper Style</Button>*/}
          </HStack>
          {/* Recently Used Section */}
          <Box w={'full'} pl={pxToRem(12)} mb={pxToRem(24)}>
            <Text fontWeight="bold" mb={4} fontFamily={'Lato'}>
              Recently Used
            </Text>
            <ExportHistory paperId={paperId} setPdfName={setPdfName} setPreviewUrl={setPreviewUrl}
                           setLatexUrl={setLatexUrl}
                           currentTrPar={currentTrPar} setIsTemplate={setIsTemplate} setSelectedStyle={setSelectedStyle} />
          </Box>
          {/* Templates */}
          <Box w={'full'} pl={pxToRem(12)}>
            <Text fontWeight="bold" mb={4} fontFamily={'Lato'}>
              Templates
            </Text>
            <HStack>
              {templates.map((style, index) => (
                <VStack key={index}
                        position={'relative'}
                        cursor={'pointer'}
                        onClick={() => {
                          setSelectedStyle(style)
                          setCurrentTrPar(null)
                          setIsTemplate(true)
                          setPreviewUrl(style.samplePdfUrl)
                          setLatexUrl(null)
                          setPdfName(style.templateName)
                        }}>
                  <Image w={pxToRem(128)} h={pxToRem(157)} src={style.thumbnailImageUrl || '/static/editor/style-template.png'} />
                  { selectedStyle?.id === style.id
                    && <Flex borderRadius={pxToRem(10)}
                             position={'absolute'}
                             w={pxToRem(128)} h={pxToRem(157)}
                             top={0}
                             left={'50%'}
                             ml={pxToRem(-64)}
                             justifyContent={'center'}
                             alignItems={'end'}
                             pb={pxToRem(10)}
                             bg={'rgba(56,152,255,0.38)'} border={'1px solid #1D8AFF'}>
                          <Image w={pxToRem(24)} src={'/static/editor/selected.png'} />
                      </Flex>}
                  <Tooltip label={style.templateName}  hasArrow padding={2} bg={'#FFF'} color={'#333'}>
                    <Text width={pxToRem(220)} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>{style.templateName}</Text>
                  </Tooltip>
                </VStack>
              ))}
            </HStack>
          </Box>
        </VStack>}
        {currentStep === 1 && <Authors authors={authors} setAuthors={setAuthors} paperId={paperId} />}
        {currentStep === 2 && (
          <VStack w='full' h={'full'} alignItems={'center'} justifyContent={'center'}>
            {generator === 'pending' && <Image
                w={pxToRem(100)}
                h={pxToRem(100)}
                objectFit='cover'
                src='/static/loading-animate.svg'
                alt='Doenba'
                bg={'transparent'}
            />}
            {generator === 'reject' && <Image
                w={pxToRem(100)}
                h={pxToRem(100)}
                objectFit='cover'
                src='/static/editor/error.png'
                alt='Doenba'
                bg={'transparent'}
            />}
            {generator !== 'resolve' && <Text mt={pxToRem(28)}
                  mb={pxToRem(24)}
                  fontSize={'1rem'} fontFamily={'Lato'} color={'#8E9ABC'}>Generating {selectedStyle.templateName} Style (7th Edition) </Text>}
            {generator === 'reject' && <VStack>
                <Text mb={pxToRem(16)} fontWeight={'bold'} fontSize={'1rem'} fontFamily={'Lato'} color={'#EA5050'}>Something Wrong</Text>
                <HStack gap={3} cursor={'pointer'} onClick={() => {
                  generatePdf()
                }}>
                    <Image src={'/static/editor/refresh.svg'} />
                    <Text fontSize={'1rem'} fontFamily={'Lato'} color={'#8E9ABC'}>Refresh</Text>
                </HStack>
            </VStack>}
            {generator === 'resolve' && <VStack justifyContent={'center'} alignItems={'center'}>
                  <Image
                    w={pxToRem(150)}
                    h={pxToRem(140)}
                    objectFit='cover'
                    src='/static/editor/complete.png'
                    alt='Doenba'
                    bg={'transparent'}
                />
                <Text mt={'1rem'} fontSize={'1rem'} fontFamily={'Lato'} color={'#8E9ABC'}>Completed</Text>
              </VStack>
            }
          </VStack>
        )}

        {/* Navigation Steps and Next Button */}
        <VStack justify="space-between" align="center" mt={3}>
          <HStack w={'full'} justifyContent={currentStep === 0 ? 'center' : 'space-between'} mb={8}>
            {currentStep > 0 && <Button
              w={pxToRem(138)}
              h={pxToRem(48)}
              bg={'#CACFDD'}
              _hover={{ background: '#CACFDD', color: '#333' }}
              border={'1px solid #CACFDD'}
              borderRadius={pxToRem(12)}
              mb={3}
              color={'#333'}
              onClick={() => {
                if (currentStep >= 1) {
                  setCurrentStep(currentStep - 1)
                }
              }}
            >
              Back
            </Button>}
            {currentStep < 2 && <Button
              w={pxToRem(138)}
              h={pxToRem(48)}
              bg={'#1D8AFF'}
              _hover={{ background: '#FBFCFF', color: '#1D8AFF' }}
              border={'1px solid #1D8AFF'}
              borderRadius={pxToRem(12)}
              mb={3}
              color={'#FFFFFF'}
              onClick={() => {
                if (currentStep < 2) {
                  if (!selectedStyle && currentStep === 0) {
                    toast({
                      description: 'Please select a template style',
                      status: 'warning',
                      duration: 20000,
                      isClosable: true,
                      position: 'top'
                    });
                    return
                  }
                  setCurrentStep(currentStep + 1)
                  if (currentStep === 1) {
                    setIsTemplate(false)
                    generatePdf()
                  }
                }
              }}
            >
              Next
            </Button>}
          </HStack>

          { /** steps */}
          <Flex width={"60%"} height={'66px'} justify={'space-around'}
                alignItems={'center'} gap={4}>
            <HStack w={'full'} alignItems={"center"}>
              {steps.map((style, index) => {
                return (
                  <React.Fragment key={index}>
                    <VStack alignItems={"center"}>
                      <Flex width={"20px"} height={"20px"} alignItems={"center"} justifyContent={"center"}
                            border={currentStep < index ? '1px solid #D3D7E2' : '0'}
                            background={index <= currentStep ? "#1D8AFF" : 'transparent'} borderRadius={4}>
                        {index <= currentStep ? <CheckIcon color={"#FFFFFF"} fontSize={12}/>
                          : <Box bg={"#D3D7E2"} width={'10px'} height={'10px'} borderRadius={6}/>
                        }
                      </Flex>
                      <Text fontWeight={"bold"} fontSize={14} color={"#333"} fontFamily={'Lato'}>
                        Step {index + 1}
                      </Text>
                      <Text fontSize={14} color={"#333"} fontFamily={'Lato'}>
                        {style.title}
                      </Text>
                    </VStack>
                    {index !== 2 && <Box h={"2px"} flex={"1"} background={"#1D8AFF"}></Box>}
                  </React.Fragment>
                )
              })}
            </HStack>
          </Flex>
        </VStack>
      </VStack>
    </Box>
  );
};

export default ExportProcess;