import React from "react";
import { Box, Image, Text, Tooltip } from "@chakra-ui/react";
import { MathJax } from "better-react-mathjax";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { TableRender } from "./InserTool/TableRender.tsx";
import parse from "html-react-parser";
import useEditorStore from "../../stores/modules/editor.ts";
import { pxToRem } from "../../utils/editor.ts";

interface InlineContentProps {
  content: string
  node: PaperOutlineNode
  nodeIndex: number
  dataId: string
  commonTextProps?: {
    [key: string]: string
  }
  openLatex?: () => void
  openFoot?: () => void
}

export const InlineContent: React.FC<InlineContentProps> = ({ content, node, openLatex, openFoot, nodeIndex, commonTextProps, dataId }) => {
  const { setCurrentLatex, setCurrentFoot } = useEditorStore()
  const hSize = [pxToRem(32), pxToRem(24), pxToRem(20), pxToRem(16), pxToRem(14)]
  const replacedContent = content.split(/(\(\$\w+\{\d+}\$\))/).map((part, index) => {
    const match = part.match(/\$(\w+){(\d+)}\$/);
    if (match && node && node[match[1]]) {
      const id = Number(match[2]);
      const slotItem = node[match[1]][id];
      if (slotItem) {
        const content = '($' + slotItem.value + '$)'
        return (
          <React.Fragment key={`${index}-${id}`}>
            {match[1] === 'equation' && <Box
                as="span"
                contentEditable={false}
                mx={'5px'}
                _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                data-content={part}
                data-type={match[1]}
                data-id={id}
                onClick={() => {
                  setCurrentLatex({
                    id,
                    content: slotItem.value,
                    label: '', // inline-equation label不需要
                    nodeIndex,
                    inlineType: 'inline'
                  })
                  openLatex && openLatex()
                }}>
                <MathJax inline>{content}</MathJax>
            </Box>}
            {match[1] === 'footnote' && <Box
                as="span"
                contentEditable={false}
                mx={'5px'}
                _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                data-content={part}
                data-type={match[1]}
                data-id={id}
                fontSize={pxToRem(14)}
                fontStyle={'italic'}
                color={'#224ea8'}
                onClick={() => {
                  setCurrentFoot({
                    id,
                    content: slotItem.value,
                    nodeIndex,
                  })
                  openFoot && openFoot()
                }}>
                {slotItem.value}
            </Box>}
            {match[1] === 'cite' && <Box
                color={'blue.500'}
                as="span"
                contentEditable={false}
                mx={'5px'}
                data-id={id}
                _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                data-content={part}
                data-type={match[1]}>
                <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={ slotItem.value } aria-label='Description'>
                  {`[${slotItem.id}]`}
                </Tooltip>
            </Box>}
            {match[1] === 'refer' &&
                <Box color={'blue.500'}
                     as="span"
                     contentEditable={false}
                     mx={'5px'}
                     _hover={{ bg: "#e8e7e8", cursor: "pointer" }}
                     data-inline={true}
                     data-content={part}
                     data-id={id}
                     data-type={match[1]}>
                  {slotItem.type === OutlineNodeType.Figure &&
                      <Tooltip hasArrow padding={0} label={ slotItem.content?.indexOf('base64') > - 1 ?
                        <Image width="200px" alt="Reference Preview" src={slotItem.content} />
                        : <Image width="200px" alt="Reference Preview" src={'data:image/png;base64,' + slotItem.content} />
                      } aria-label='Description'>
                        {`[${slotItem.value}]`}
                      </Tooltip>}
                  {slotItem.type === OutlineNodeType.Equation &&
                      <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={
                        <MathJax inline>(${slotItem.content}$)</MathJax>
                      } aria-label='Description'>
                        {`[${slotItem.value}]`}
                      </Tooltip>}
                  {slotItem.type === OutlineNodeType.Table &&
                      <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={
                        <TableRender content={slotItem.content} />
                      } aria-label='Description'>
                        {`[${slotItem.value}]`}
                      </Tooltip>}
                  {slotItem.type === OutlineNodeType.Section && `[${slotItem.value}]`}
                </Box>}
            &#xFEFF;
          </React.Fragment>
        )
      }
    }
    if (part.indexOf('<strong') > -1 || part.indexOf('<em') > -1 || part.indexOf('<u') > -1) {
      return <React.Fragment key={index}>{parse(part)}</React.Fragment>;
    }
    return <React.Fragment key={`${content}-${index}`}>{part?.replace(/\uFEFF/g, '')}</React.Fragment>;
  });
  if (node.type === OutlineNodeType.Paragraph) {
    return (
      <Text
        {...commonTextProps}
        className={'editable-textarea paragraph-edit-type'}
        data-id={dataId}
        data-index={nodeIndex}
        key={content + 'parent'}
        wordBreak={"break-word"}
      >
        {replacedContent}
      </Text>
    )
  } else {
    return (
      <Text
        {...commonTextProps}
        data-id={dataId}
        className={'editable-textarea section-edit-type'}
        bgColor={"transparent"}
        _hover={{ bgColor: "transparent" }}
        textColor={
          node.level == 1 ? "#1D8AFF" : "#333333"
        }
        key={content + 'parent'}
        data-placeholder={`New Heading${node.level}`}
        flex={1}
        fontSize={hSize[node.level - 1]}
        fontWeight={"bold"}
        position={'relative'}
        wordBreak={"break-word"}
      >
        {replacedContent}
      </Text>
    )
  }
}