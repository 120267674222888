import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Flex, Heading, Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter, ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-textmate";
import "katex/dist/katex.min.css";
import { pxToRem } from "../../../utils/editor.ts";
import useEditorStore from "../../../stores/modules/editor.ts";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { useEditorHook } from "../../../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";


interface InsertFigureProps {
  isOpenModal: boolean
  closeModal: () => void
}

export const InsertFigure: React.FC<InsertFigureProps> = ({ isOpenModal, closeModal }) => {
  const { currentCursor, currentFigure, setCurrentFigure } = useEditorStore()
  const { nodeList, setNodeList } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const [content, setContent] = useState('');
  const [caption, setCaption] = useState<string>('')
  const inputRef = useRef<HTMLInputElement | null>(null);

  /**
   * add equation to editor
   */
  const confirmAddFigure = () => {
    closeModal()
    if (currentFigure?.id) { // 编辑
      const newNodeList = cloneDeep(nodeList)
      const node = newNodeList[currentFigure.nodeIndex]
      node.content = content
      node.caption = caption
      newNodeList[currentCursor.nodeIndex] = node
      setNodeList([...newNodeList])
    } else {
      addNewNode(nodeList[currentCursor.nodeIndex], OutlineNodeType.Figure, 2,
        content, undefined, caption)
    }
    setContent('')
    setCaption('')
    setCurrentFigure(null)
  }

  const closeModalHandler = () => {
    closeModal()
    setContent('')
    setCaption('')
    setCurrentFigure(null)
  }

  /**
   * upload image file to base64
   * @param file
   */
  const convertImageToBase64 = async (file: File): Promise<string> => {
    const reader = new FileReader();
    return await new Promise<string>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error("read file error"));
      reader.readAsDataURL(file);
    });
  }
  const handlerUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    // add new node, content is url
    const file = event.target.files?.[0];
    const url = await convertImageToBase64(file);
    setContent(url)
  }
  const clickUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }

  return (
    <Modal isOpen={isOpenModal} onClose={closeModalHandler}
           closeOnOverlayClick={false}>
      <ModalOverlay bg={'whiteAlpha.500'}/>
      <ModalContent
        position={'fixed'}
        minW={pxToRem(600)} minHeight={"220px"}
        bg={'#FBFCFF'}
        boxShadow={'none'}
        top={150}
        border={'2px solid #D3D7E2'}
        borderRadius={pxToRem(12)}>
        <ModalHeader>
          <Heading size={'md'} mb={1}>Insert Figure</Heading>
        </ModalHeader>
        <ModalCloseButton
          _focusVisible={{ boxShadow: 'none' }}
          bg={'#D3D7E2'}
          width={pxToRem(24)}
          height={pxToRem(24)}
          color={'#8E9ABC'}
          top={pxToRem(20)}
          right={pxToRem(20)}
          fontSize={'8px'}
          borderRadius={'50%'}/>
        <ModalBody
          display={'flex'} p={2}
          bg={'#FBFCFF'}
          borderRadius={pxToRem(12)}>
          <Box px={2} pb={0} w={pxToRem(600)}>
              <Flex mt={4} alignItems={'center'} gap={2}>
                  Caption:
                  <Input
                     value={caption}
                      onChange={(e) => {
                       setCaption(e.target.value)
                      }}
                  ></Input>
              </Flex>
            {/** image upload */}
              <Button mt={4} mb={4} onClick={clickUpload}>Upload Figure</Button>
              <Image maxWidth={pxToRem(280)} m={'10px auto'} src={content} />
              <input
                  ref={inputRef}
                  type="file"
                  accept=".png,.jpg,.gif, .bmp,.jpeg"
                  onChange={handlerUpload}
                  style={{ display: "none" }}
              />
              <ModalFooter p={2}>
                  <Button w={pxToRem(120)} mr={3} onClick={closeModalHandler}>Close</Button>
                  <Button
                      w={pxToRem(120)}
                      bg={'#1D8AFF'}
                      _hover={{ background: '#1D8AFF' }}
                      border={'1px solid #1D8AFF'}
                      borderRadius={pxToRem(12)}
                      color={'#FFFFFF'}
                      onClick={confirmAddFigure}
                  >
                      Confirm
                  </Button>
              </ModalFooter>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

