import { Accordion, Box, Flex, Text, Center } from "@chakra-ui/react";
import { useState } from "react";
import SearchConversationWindow from "./SearchConversationWindow";
import { LuUnfoldHorizontal } from "react-icons/lu";
import { useRecoilValue } from "recoil";
import { searchResultProcessingState } from "../../atoms/rootAtom";
import ResultCard from "./ResultCard";
import { Result } from "../../types/Search";

const SearchResultWindow = () => {
    const [results, setResults] = useState<Result[]>([]);
    const [duration, setDuration] = useState<number | null>(null);
    const isSearching = useRecoilValue(searchResultProcessingState);

    const [width, setWidth] = useState(400);
    const startResizing = (mouseDownEvent) => {
        console.log('Start resizing')
        // Prevent text selection while dragging
        mouseDownEvent.preventDefault();

        // Mouse move event handler
        const handleMouseMove = (mouseMoveEvent) => {
            setWidth(mouseMoveEvent.clientX);
        };

        // Mouse up event handler
        const handleMouseUp = () => {
            // Detach the event listeners when dragging is finished
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        // Attach the event listeners
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <>
            <Flex flexDir={'row'}>
                <Box flex={'1 auto'} pt={5} pl={5} pr={5} width={`${width}px`}>
                    <Box h={'100%'}>
                        {results.length > 0 &&
                            <Box h={'100%'}>
                                {<Text
                                    mt={5}
                                    color={'gray.400'}
                                    textAlign={'right'}
                                    fontSize='xs'
                                >
                                    Found {results.length} results {duration && ` in ${duration}s`}
                                </Text>}
                                <Accordion mt={2} allowMultiple defaultIndex={[0]} overflow={'auto'} h={'calc(100vh - 105px)'}>
                                    {results.map((result) => (
                                        <ResultCard
                                            key={result.id}
                                            result={result} 
                                        />
                                    ))}
                                </Accordion>
                            </Box>
                        }
                        {!isSearching && results.length == 0 &&
                            <Center w={'full'} h={'full'}>
                                Please indicate your search criteria on the right side.
                            </Center>
                        }
                        {isSearching &&
                            <Center w={'full'} h={'full'}>
                                Preparing the search results now...
                            </Center>
                        }
                    </Box>
                </Box>
                <Box
                    as="div"
                    cursor='col-resize'
                    width='2px'
                    bg='gray.100'
                    onMouseDown={startResizing}
                    position={'relative'}
                >
                    <Box
                        position={'absolute'}
                        top={'50%'}
                        left={'-10px'}
                        bg={'white'}
                        w={'22px'}
                        h={'22px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        border={'1px solid #ccc'}
                        borderRadius={'10px'}
                        cursor={'pointer'}
                    >
                        <LuUnfoldHorizontal />
                    </Box>

                </Box>
                <Box flex={'1 auto'} pt={5} pl={5} pr={5}>
                    <SearchConversationWindow
                        setResults={setResults}
                        setDuration={setDuration}
                    />
                </Box>
            </Flex>
            {/* <SummaryModal isOpen={isOpen} handleClose={onClose} file={selectedResult} /> */}
        </>
    );
}

export default SearchResultWindow;