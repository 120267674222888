import { Box } from "@chakra-ui/react";
import React from "react";

interface TableRenderProps {
  content: string
}
export const TableRender: React.FC<TableRenderProps> = ({ content }) => {
  return (
    <Box width={'100%'}
         mt={4}
         contentEditable={false}
         sx={{
           'table': {
             borderCollapse: 'collapse',
             width: '80%',
             margin: '0 auto'
           },
           'td, th': {
             'border': '1px solid #D3D7E2',
             'padding': '8px',
             textAlign: 'left',
             height: '40px',
             whiteSpace: 'nowrap',
           },
           'table, th, td': {
             borderColor: '#D3D7E2',
           }
         }}
         dangerouslySetInnerHTML={{ __html: content }}></Box>
  )
}