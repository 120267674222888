import {
  OutlineNodeType,
  PaperOutline,
  PaperOutlineNode,
} from "../../types/Writer/OutlineNode";
import {
  Text,
  Box,
  Center,
  List,
  HStack,
  VStack,
  Button,
  useDisclosure,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image, ModalCloseButton,
} from "@chakra-ui/react";
import {
  arrayMove
} from '@dnd-kit/sortable';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { OutlineNodeItem } from "./OutlineNodeItem.tsx";
import { cloneDeep, findIndex } from "lodash";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import useEditorStore from "../../stores/modules/editor.ts";


const NodeTree: React.FC<{
  height?: string;
  updateOutlineStructure: (outline: PaperOutline) => void;
  setIsExport: (isExport: boolean) => void
}> = ({ updateOutlineStructure, setIsExport }) => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const [sectionRoot, setSectionRoot] = useState<PaperOutlineNode>(undefined);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newTitleLabel, setNewTitleLabel] = useState<string>("");
  const [repeatLabel, setRepeatLabel] = useState<boolean>(false);

  const {
    isOpen: isOpenDeleteConfirmPanel,
    onOpen: onOpenDeleteConfirmPanel,
    onClose: onCloseDeleteConfirmPanel,
  } = useDisclosure();
  const {
    isOpen: isOpenTitleInputPanel,
    onOpen: onOpenTitleInputPanel,
    onClose: onCloseTitleInputPanel,
  } = useDisclosure();
  const [inputPositionY, setInputPositionY] = useState<number>(0);
  const { outline, currentSectionIndex, setOutline, setNextId, setOutlineIsChange,
    setCurrentSectionIndex,
    currentNodeId, setCurrentNodeId, createOutline, setCreateOutline } = useOutlineStore();
  const [currentEditNode, setCurrentEditNode] = useState<PaperOutlineNode>(null);
  const { setFocusEditor } = useEditorStore();

  /**
   * drag move node
   * @param from
   * @param to
   */
  const moveNode = (from: PaperOutlineNode, to: PaperOutlineNode) => {
    const newOutline = cloneDeep(outline)

    const activeIndex = findIndex(outline.nodes, { id: from.id });
    const overIndex = findIndex(outline.nodes, { id: to.id });
    newOutline.nodes = arrayMove(newOutline.nodes, activeIndex, overIndex);
    setOutline({ ...newOutline })
    setOutlineIsChange(true)
  }
  /**
   * add new section
   * @param title
   * @param label
   */
  const addNewNode = (title: string, label: string): PaperOutline => {
    if (title.length == 0) return;
    const newNode: PaperOutlineNode = {
      id: outline.nextId,
      type: OutlineNodeType.Section,
      name: title,
      level: 1,
      label: label,
      children: [],
    };

    const newOutLine = cloneDeep(outline)
    if (newOutLine.nodes.length > 0) {
      newOutLine.nodes.splice(currentSectionIndex, 0, newNode);
    } else {
      newOutLine.nodes.push(newNode)
    }
    newOutLine.nextId = newNode.id + 1
    setNextId(newOutLine.nextId)
    setOutline({ ...newOutLine })
    updateOutlineStructure(newOutLine)
  }

  const deleteNode = () => {
    const newOutLine = cloneDeep(outline);
    newOutLine.nodes = newOutLine.nodes.filter((_, index) => index !== currentSectionIndex);
    setOutline({ ...newOutLine })
    updateOutlineStructure(newOutLine)
    if (newOutLine?.nodes?.length) {
      setSectionRoot(newOutLine.nodes[0])
      setCurrentSectionIndex(0)
    }
  };

  useEffect(() => {
    if (outline?.nodes?.length) {
      setSectionRoot(outline.nodes[currentSectionIndex])
    }
  }, []);

  useEffect(() => {
    if (outline?.nodes?.length && createOutline) {
      setSectionRoot(outline.nodes[currentSectionIndex])
      setCreateOutline(false)
    }
  }, [createOutline]);

  const setInputArgs = (newTitle: string, label: string, postion: number, node?: PaperOutlineNode) => {
    setFocusEditor(false)
    onOpenTitleInputPanel();
    setNewTitle(newTitle);
    setNewTitleLabel(label);
    setInputPositionY(postion);
    if (node) {
      setCurrentEditNode(node)
    }
  }

  const saveHandler = () => {
    if (newTitle.length > 0) {
      if (currentEditNode?.id) {
        const newOutline = cloneDeep(outline);
        // judge newTitleLabel is unique
        if (newTitleLabel.trim()) {
          const isRepeat = findIndex(newOutline.nodes, { label: newTitleLabel })
          if (isRepeat !== -1 && isRepeat !== currentSectionIndex) {
            setRepeatLabel(true)
            return
          } else {
            setRepeatLabel(false)
          }
        }
        newOutline.nodes[currentSectionIndex].name = newTitle;
        newOutline.nodes[currentSectionIndex].label = newTitleLabel;
        setOutline({ ...newOutline })
        updateOutlineStructure(newOutline);
      } else {
        addNewNode(newTitle, newTitleLabel)
      }
      onCloseTitleInputPanel();
      setFocusEditor(false)
    }
  }
  return (
    <>
      {outline.nodes.length == 0 ? (
        <Center>
          <IconButton
            aria-label={""}
            bg={'transparent'}
            _hover={{ bg: "transparent" }}
            onClick={(e) => {
              setIsExport(false)
              setInputArgs("New Section", "", e.pageY - 87);
            }}
            icon={
              <Image
                w={"40px"}
                objectFit="cover"
                src="/static/editor/add-square.png"
                alt="Doenba"
              />
            }
          />
        </Center>
      ) : (
        <Box h={"100%"} overflow={"hidden"}>
          <Center>
            <Box
              position={"relative"}
              w={pxToRem(212)}
              h={"2px"}
            />
          </Center>
          <DndProvider backend={HTML5Backend}>
            <List
              h={"100%"}
              overflowY={"auto"}
              spacing={4}
              ref={listRef}
              overflowX={"hidden"}
            >
              {outline.nodes.map(
                (
                  node: PaperOutlineNode,
                  index: number
                ) => {
                  return node.level != 1 && node.level != 2 ? undefined :
                    <OutlineNodeItem
                      key={node.id.toString() + "|level" + node.level?.toString() + index}
                      node={node}
                      currentNodeId={currentNodeId}
                      setCurrentNodeId={setCurrentNodeId}
                      sectionRoot={sectionRoot}
                      setSectionRoot={setSectionRoot}
                      moveNode={moveNode}
                      setInputArgs={setInputArgs}
                      setIsExport={setIsExport}
                      onOpenDeleteConfirmPanel={onOpenDeleteConfirmPanel}/>
                }
              )}
            </List>
          </DndProvider>
        </Box>
      )}
      {/** delete confirm panel */}
      <Modal
        isOpen={isOpenDeleteConfirmPanel}
        onClose={onCloseDeleteConfirmPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={pxToRem(551)}
          height={pxToRem(345)}
          top={pxToRem(200)}
          border={"1px solid #ccc"}
          borderRadius={pxToRem(24)}
          bg={'#FBFCFF'}
        >
          <ModalCloseButton />
          <ModalBody p={5}>
            <Flex flexDir={"column"} gap={2}>
              <Center mt={5}>
                <Image
                  w={pxToRem(100)}
                  objectFit="cover"
                  src="/static/editor/delete2.png"
                  alt="Doenba"
                />
              </Center>
              <Center>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel1")}
                </Text>
              </Center>
              <Center>
                <Text fontWeight={'bold'} style={{ color: "#1D8AFF" }}>{sectionRoot?.name}</Text>
              </Center>
              <Center>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel3")}
                </Text>
              </Center>
              <HStack p={10} spacing={150}>
                <Button
                  bgColor={"#9399AB"}
                  width={pxToRem(139)}
                  height={pxToRem(48)}
                  textColor={"white"}
                  borderRadius={pxToRem(12)}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnCancel")}
                </Button>
                <Button
                  bgColor={"#1D8AFF"}
                  width={pxToRem(139)}
                  height={pxToRem(48)}
                  textColor={"white"}
                  borderRadius={pxToRem(12)}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    deleteNode()
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("yes-label")}
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/** rename / add new*/}
      <Modal
        isOpen={isOpenTitleInputPanel}
        onClose={onCloseTitleInputPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay bg="transparent"/>
        <ModalContent
          width={373}
          minHeight={135}
          position={'fixed'}
          left={pxToRem((window.innerWidth * 0.15 + 192) / 2)}
          top={inputPositionY}
          bg={'#FBFCFF'}
          border={"1px solid #ccc"}
        >
          <ModalBody p={1}>
            <VStack p={2}>
              <Flex width={"100%"}>
                <Text width={pxToRem(60)} fontSize={16} align={"left"} as="b">
                  Title:
                </Text>
                <Input
                  width={pxToRem(283)}
                  height={pxToRem(27)}
                  placeholder={"New Section New Section"}
                  defaultValue={newTitle}
                  borderColor={newTitle.length > 0 ? undefined : "red.300"}
                  onKeyDown={(e) => {
                    if(e.key === 'Enter') {
                      saveHandler()
                    }
                  }}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
              </Flex>
              <Flex width={"100%"}>
                <Text width={pxToRem(60)} fontSize={16} align={"left"} as="b">
                  Label:
                </Text>
                <VStack alignItems={'start'}>
                  <Input
                    width={pxToRem(283)}
                    height={pxToRem(27)}
                    placeholder={"Use in Latex"}
                    defaultValue={newTitleLabel}
                    onKeyDown={(e) => {
                      if(e.key === 'Enter') {
                        saveHandler()
                      }
                    }}
                    onChange={(e) => setNewTitleLabel(e.target.value)}
                  />
                  { repeatLabel && (
                    <HStack>
                      <InfoOutlineIcon mr={1} color={'#EA5050'} />
                      <Text color={'#EA5050'}>{t('repeat-label')}</Text>
                    </HStack>
                  )
                  }
                </VStack>
              </Flex>
              <HStack pt={3} spacing={170}>
                <Button
                  border={"1px solid #1D8AFF"}
                  width={pxToRem(82)}
                  height={pxToRem(25)}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  bg={'#FBFCFF'}
                  onClick={() => {
                    setRepeatLabel(false)
                    onCloseTitleInputPanel();
                  }}
                >
                  {t("editor-editormodel-treeInputCancel")}
                </Button>
                <Button
                  border={"1px solid #1D8AFF"}
                  width={pxToRem(82)}
                  height={pxToRem(25)}
                  bg={'#FBFCFF'}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => saveHandler()}
                >
                  {t("editor-editormodel-treeInputSave")}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default NodeTree;
