import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import {
  crossRefType,
  getCurrentEditLi,
  splitDomToContent, splitDomToContent2
} from "../../utils/editor.ts";
import { SelectContent, SelectContents } from "../../types/Writer/SelectContents.ts";
import { cloneDeep } from "lodash";

export const useEditorMouseEvent = () => {
  const handlerSelection = (nodeList: PaperOutlineNode[]) => {
    const selection = window.getSelection();
    let selectString = ''
    const selectContents: SelectContents = []
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range && selection.toString()?.length) {
      const startContainer = range.startContainer as HTMLElement;
      const newNodeList = cloneDeep(nodeList)
      const { endContainer } = range
      const startLi = getCurrentEditLi(startContainer, newNodeList)
      const startIndex = startLi.index
      if (startIndex !== -1) {
        const endLi = getCurrentEditLi(endContainer, newNodeList)
        const endIndex = endLi.index
        for (let index = startIndex; index <= endIndex; index++) {
          const node = newNodeList[index]
          if (index === startIndex) {
            if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) { // 当前行是块级equation figure table section
              selectContents.push({
                id: node.id,
                select_content: node.content,
                type: node.type
              })
              selectString += node.content
            } else { // selectString不为空
              if (startIndex === endIndex) {
                // 所选一行，从splitInfo.next中截取到endOffset
                const info = splitDomToContent2(node, range)
                selectString += info.selectContent
                selectContents.push({
                  id: node.id,
                  select_content: info.selectContent,
                  ...info.selectObj
                })
              } else {
                const splitInfo = splitDomToContent(node, startContainer as HTMLElement, range.startOffset)
                selectString += splitInfo.next
                selectContents.push({
                  id: node.id,
                  select_content: splitInfo.next,
                  ...splitInfo.nextObj
                })
              }
            }
          } else if (index === endIndex) { // 只会出现selectString不为空
            const splitInfo = splitDomToContent(node, endContainer as HTMLElement, range.endOffset)
            selectString += splitInfo.currentObj.content
            const item: SelectContent = {
              id: node.id,
              select_content: splitInfo.currentObj.content,
            }
            if (node.refer) {
              item.refer = node.refer
            }
            if (node.cite) {
              item.cite = node.cite
            }
            if (node.equation) {
              item.equation = node.equation
            }
            selectContents.push(item)
          } else {
            const item: SelectContent = {
              id: node.id,
              select_content: node.content,
            }
            if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) {
              item.type = node.type
            }
            if (node.refer) {
              item.refer = node.refer
            }
            if (node.cite) {
              item.cite = node.cite
            }
            if (node.equation) {
              item.equation = node.equation
            }
            selectContents.push(item)
            selectString += node.content
          }
        }
      }
    }
    return{
      selectString,
      selectContents
    }
  }

  return {
    handlerSelection
  }
}