import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack
} from "@chakra-ui/react";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuText } from "react-icons/lu";
import { getCursorPos, getNodePDom, pxToRem, setCursorPosition } from "../../utils/editor.ts";
import EditorContent from "./EditorContent.tsx";
import { deleteHandler, useEditorKeyboard } from "../../hooks/editor/useEditorKeyboard.hook.ts";
import { useTranslation } from "react-i18next";
import { useEditorMouseEvent } from "../../hooks/editor/useEditorMouseEvent.ts";
import eventBus, { EventType } from "../../hooks/useEventBus.ts";
import useAiTypeStore from "../../stores/modules/aiType.ts";
import { AIDataType } from "../../types/Writer/AIData.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { useEditorHook } from "../../hooks/editor/useEditor.hook.ts";
import { ChangeTypeList } from "./ChangeTypeList.tsx";
import useEditorStore from "../../stores/modules/editor.ts";
import { debounce, findIndex } from "lodash";

/**
 * main editor area
 */
interface SectionContentProps {
  listRef: React.MutableRefObject<HTMLUListElement | null>
  updateOutline: (updated: boolean) => void
  openLatex: () => void
  openTable: () => void
  openFoot: () => void
  isOpenFigure: boolean
  isOpenTable: boolean
  isOpenLatex: boolean
  isOpenFoot: boolean
}

export const SectionContent: React.FC<SectionContentProps> = (
  {
    listRef,
    updateOutline,
    openLatex,
    openTable,
    openFoot,
    isOpenFigure,
    isOpenTable,
    isOpenLatex,
    isOpenFoot,
  }
) => {
  const { t } = useTranslation();
  const { keyboardHandler } = useEditorKeyboard()
  const { handlerSelection } = useEditorMouseEvent()
  const [mousePos, setMousePos] = useState([0, 0]);
  const { updateAiType } = useAiTypeStore()
  const { nodeList, toolPopOpen, setToolPopOpen, currentNodeId, currentSectionIndex, setNodeList, setOutlineIsChange } = useOutlineStore();
  const { selectContentArr, setSelectContentArr } = useAiTypeStore()
  const { addNewNode, pasteHandler } = useEditorHook();
  const { currentCursor, setCurrentCursor, setCurrentLatex, focusEditor, addMode, setAddMode, setFocusEditor } = useEditorStore()
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const listRefs = useRef([]); // 用来存储每个 li 的 ref
  const [lastSectionIndex, setLastSectionIndex] = useState(0)

  const handlePaste = () => {
    const { nodeList, nextId } = useOutlineStore.getState()
    const cursor = getCursorPos(nodeList);
    if (cursor) {
      setCurrentCursor(cursor)
      // todo 如果是粘贴在选区
      pasteHandler(cursor, nextId, nodeList)
    }
  };

  const handleCopy = (event: ClipboardEvent) => {
    event.preventDefault();
    const { nodeList } = useOutlineStore.getState()
    const { selectContents } = handlerSelection(nodeList);
    navigator.clipboard.writeText(JSON.stringify(selectContents)); // todo 复制需要将格式inline, equation保留
  };

  const handleCut = (e: ClipboardEvent) => {
    e.preventDefault();
    const { nodeList } = useOutlineStore.getState()
    const { selectContents, selectString } = handlerSelection(nodeList);
    if (selectString?.length === 0) {
      return;
    }
    navigator.clipboard.writeText(JSON.stringify(selectContents));
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      deleteHandler(e, range, selection, nodeList, setNodeList, setOutlineIsChange, setCurrentCursor)
    }
  };

  useEffect(() => {
    const ulElement = listRef.current;
    if (!ulElement) return;

    const debouncedHandleCopy = debounce(handleCopy, 200);
    const debouncedHandleCut = debounce(handleCut, 200);
    const handlePasteWrapper = (e) => {
      e.preventDefault();
      debouncedHandlePaste();
    };

    const debouncedHandlePaste = debounce(handlePaste, 200);

    ulElement.addEventListener("copy", debouncedHandleCopy);
    ulElement.addEventListener("cut", debouncedHandleCut);
    ulElement.addEventListener("paste", handlePasteWrapper);

    return () => {
      debouncedHandleCopy.cancel();
      debouncedHandleCut.cancel();
      debouncedHandlePaste.cancel();

      ulElement.removeEventListener("copy", debouncedHandleCopy);
      ulElement.removeEventListener("cut", debouncedHandleCut);
      ulElement.removeEventListener("paste", handlePasteWrapper);
    };
  }, []);

  /*
   * 滚动到指定section li
   */
  const scrollToListItem = (index: number) => {
    const listItem = listRefs.current[index];
    if (listItem) {
      listItem.scrollIntoView({
        behavior: 'smooth',
        block: 'center', // 'start', 'center', 'end', or 'nearest'
      });
    }
  };

  /**
   * 新增删除后，nodeList长度变化,修改光标位置
   */
  const focusNodeAfterAdd = () => {
    try {
      if (nodeList[currentCursor.nodeIndex]) {
        const dom = getNodePDom(nodeList[currentCursor.nodeIndex]) as HTMLElement
        if (addMode === 'content') {
          if (dom?.firstChild) {
            setCursorPosition(dom.firstChild as HTMLElement, currentCursor.start)
          }
          setAddMode('blank')
        } else if (dom?.lastChild) {
          setCursorPosition(dom.lastChild as HTMLElement, currentCursor.start)
        } else if (dom) {
          setCursorPosition(dom, currentCursor.start)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const index = findIndex(nodeList, { id: currentNodeId })
    if (index > -1) {
      scrollToListItem(index)
    }
  }, [currentNodeId]);

  useEffect(() => {
    setLastSectionIndex(currentSectionIndex)
  }, [currentSectionIndex]);

  /**
   * 监听变化
   */
  useEffect(() => {
    if (currentCursor.nodeIndex > -1) {
      if (lastSectionIndex !== currentSectionIndex) {
        if (nodeList[0]) {
          const dom = getNodePDom(nodeList[0])
          if (dom?.lastChild) {
            setCursorPosition(dom?.lastChild, currentCursor.start)
          } else if (dom) {
            setCursorPosition(dom, 0)
          }
        }
      } else {
        focusNodeAfterAdd()
      }
      if (currentCursor.nodeIndex === nodeList.length - 2) {
        scrollToListItem(nodeList?.length - 1)
      } else {
        scrollToListItem(currentCursor.nodeIndex)
      }
      // 新增删除(包括粘贴剪切) 初次不执行
      if (nodeList?.length) {
        updateOutline(true)
      }
    }
  }, [nodeList?.length]);

  /**
   * click add, display selections
   * @param node
   * @param btnClick
   */
  const getSectionButton = (
    node: PaperOutlineNode,
    btnClick: (level: number) => void,
  ) => {
    const level = node.level + 1
    if (level > 5) return undefined;
    const levelIcons = {
      2: <LuHeading2/>,
      3: <LuHeading3/>,
      4: <LuHeading4/>,
      5: <LuHeading5/>,
    }
    const menuItems = []
    const endLevel = node.type === OutlineNodeType.Section ? (level + 1) : level
    for (let i = 2; i < endLevel; i++) {
      const icon = levelIcons[i]
      menuItems.push(<MenuItem
        onClick={() => btnClick(i)}
        _hover={{ bgColor: "#3898FF61" }}
        borderRadius={10}
        key={i}
      >
        {icon}
        <Text pl={"3px"} fontSize={14}>
          {t("editor-editormodel-midNewSubsection" + i)}
        </Text>
      </MenuItem>)
    }
    return menuItems
  }

  /**
   * watch input keyboard key
   */
  const useKeyboard = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.ctrlKey || e.metaKey) {
      const keys =  ['x', 'c', 'v']
      if (keys.includes(e.key.toLowerCase())) {
        return
      }
      if (e.key === "s") {
        e.preventDefault()
        updateOutline(true)
        return
      }
      // 组合键先都禁掉
    }
    await keyboardHandler(e)
    if (toolPopOpen) {
      setToolPopOpen(false)
    }
  };

  return (
    <Box ref={sectionRef} overflowY={'auto'} height={`100%`}>
      <List
        contentEditable
        suppressContentEditableWarning={true}
        ref={listRef}
        flex={1}
        borderRadius={"10px"}
        pr="1rem"
        onKeyDown={useKeyboard}
        onMouseDown={(e) => {
          const target = e.target as HTMLElement;
          if (!target.classList?.contains("editable-textarea")) { // 点击非editable区域时，光标不可聚焦
            e.preventDefault(); // prevent cursor placement
          }
          if (e.button !== 2) {
            setToolPopOpen(false)
          }
        }}
        onMouseUp={(e) => {
          if (window.getSelection()?.toString()?.length) {
            const { selectString, selectContents } = handlerSelection(nodeList);
            if (selectString) {
              if (!toolPopOpen) {
                setToolPopOpen(true)
              }
              setMousePos([e.clientX + 20, e.clientY + 30]);
              setSelectContentArr(selectContents)
            }
          } else {
            setSelectContentArr([])
          }
          if (!focusEditor) {
            setFocusEditor(true)
          }

          const cursor = getCursorPos(nodeList);
          if (cursor) {
            setCurrentCursor(cursor)
          }
        }}
        onBlur={(e) => { // 失焦时设置cursor位置
          const cursor = getCursorPos(nodeList);
          if (cursor) {
            setCurrentCursor(cursor)
          }
          e.preventDefault()

          if (e.relatedTarget?.getAttribute && e.relatedTarget.getAttribute('data-no-focus') === 'true') {
            if (toolPopOpen && e.relatedTarget.getAttribute('data-rephrase') !== 'true') {
              setToolPopOpen(false)
            }
            return
          }
          if (toolPopOpen) {
            setToolPopOpen(false)
          }
          if (cursor.target && !isOpenLatex && !isOpenFigure && !isOpenTable && focusEditor && !isOpenFoot) {
            setCursorPosition(cursor.target, cursor.start) // 失焦后重新聚焦
          }
        }}
        sx={{
          border: "none",
          outline: "none",
          boxShadow: "none",
        }}
        _focus={{
          border: "none",
          boxShadow: "none",
        }}
      >
        {nodeList?.map((child, index) =>
          (
            <ListItem
              key={child.id?.toString() + index}
              data-id={`li-${child.id}`}
              data-type={child.type}
              sx={{
                '&:hover .extra-tools': {
                  visibility: 'visible',
                },
                '.extra-tools': {
                  visibility: currentCursor?.nodeIndex === index ? 'visible' : 'hidden'
                },
              }}
              minH={pxToRem(29)}
              ref={(el) => listRefs.current[index] = el}
            >
              <Flex
                alignItems={"flex-start"}
                pl={child.level <= 2 ? 0 : pxToRem(28 * (child.level - 2))}
              >
                <HStack
                    onMouseUp={(e) => {
                      e.stopPropagation()
                    }}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    onBlur={(e) => {
                      e.stopPropagation()
                    }}
                    className={'extra-tools'}
                    mt={'0.3rem'} gap={0.3} width={pxToRem(56)} contentEditable={false} userSelect={"none"}>
                    <Menu matchWidth={true}>
                      <MenuButton
                        as={IconButton}
                        size={"xs"}
                        borderRadius={'50%'}
                        bgColor={"transparent"}
                        _hover={{ bgColor: "gray.300" }}
                        _focusVisible={{ boxShadow: 'none' }}
                        onClick={() => {
                          // 点击添加后
                          setCurrentCursor({
                            nodeIndex: index,
                            start: 0,
                            target: null
                          })
                        }}
                        icon={
                          <Image
                            w={"1rem"}
                            userSelect={"none"}
                            objectFit="cover"
                            src="/static/editor/add.png"
                          />
                        }
                      />
                      <MenuList
                        border={"1px solid #cccccc"}
                        minWidth={109}
                      >
                        <MenuItem
                          _hover={{ bgColor: "#3898FF61" }}
                          borderRadius={10}
                          key={OutlineNodeType.Equation}
                          onClick={() => {
                            setCurrentLatex({
                              id: null,
                              content: '',
                              label: '',
                              nodeIndex: index,
                              inlineType: 'block'
                            })
                            openLatex()
                          }}
                        >
                          <Image width={'1.2rem'} src={'/static/ToolAI/equation.png'} />
                          <Text pl={"3px"} fontSize={14}>
                            {t("toolbox-math")}
                          </Text>
                        </MenuItem>
                        <MenuItem
                          _hover={{ bgColor: "#3898FF61" }}
                          borderRadius={10}
                          key={OutlineNodeType.Paragraph}
                          onClick={() => {
                            addNewNode(
                              child,
                              OutlineNodeType.Paragraph,
                              child.type === OutlineNodeType.Section ? (child.level + 1) : child.level
                            )
                          }}
                        >
                          <LuText/>
                          <Text pl={"3px"} fontSize={14}>
                            {t("editor-editormodel-Text")}
                          </Text>
                        </MenuItem>
                        {getSectionButton(child, (level: number) => {
                          addNewNode(
                            child,
                            OutlineNodeType.Section,
                            level
                          )
                        })}
                      </MenuList>
                    </Menu>
                    {(child.type === OutlineNodeType.Paragraph
                      || child.type === OutlineNodeType.Section) && (
                      <ChangeTypeList index={index} node={child} updateOutline={updateOutline} />
                    )}
                </HStack>
                <HStack
                  alignItems={"flex-start"}
                  flex={1}
                  data-id={child.id?.toString() + '-' + child.level?.toString()}
                  sx={{
                    '& em': {
                      mx: pxToRem(4)
                    },
                    '& strong': {
                      mx: pxToRem(2)
                    }
                  }}
                >
                  <EditorContent nodeIndex={index} node={child} openLatex={openLatex} openTable={openTable} openFoot={openFoot} />
                </HStack>
              </Flex>
            </ListItem>
          )
        )}
      </List>
      {/** cache area */}
      <Box height={pxToRem(200)}></Box>
      {/** rephrase button */}
      {toolPopOpen && (<HStack
        position={"fixed"}
        left={mousePos[0]}
        top={mousePos[1]}>
        <VStack
          width={pxToRem(238)}
          height={pxToRem(35)}
        >
          <Button
            border={"1px solid #3898FF"}
            bg={"#B4D8FF"}
            borderRadius={"10px"}
            data-no-focus={'true'}
            data-rephrase={'true'}
            leftIcon={
              <Image
                w={pxToRem(24)}
                objectFit="cover"
                src="/static/ToolAI/flash.png"
                alt="Doenba"
              />
            }
            onClick={(e) => {
              e.stopPropagation() // 防止触发editorModel中的click
              setToolPopOpen(false)
              updateAiType(AIDataType.rephrase)
              Promise.resolve().then(() => { // wait for event on
                eventBus.emit(EventType.Rephrase, selectContentArr)
              })
            }}
          >
            {t("editor-editormodel-phraseBtn")}
          </Button>
        </VStack>
      </HStack>)}
    </Box>
  )
}