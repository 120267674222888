import React from "react";
import { Box, CloseButton, HStack, Text } from "@chakra-ui/react";
import ExportProcess from "./Export/ExportProcess.tsx";
import ExportPreview from "./Export/ExportPreview.tsx";
import { pxToRem } from "../../utils/editor.ts";

interface ExportProps {
  paperId?: string
  setIsExport: (isExport: boolean) => void
}
export const Export: React.FC<ExportProps> = (props) => {
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null)
  const [latexUrl, setLatexUrl] = React.useState<string | null>(null)
  const [pdfName, setPdfName] = React.useState<string | null>(null)
  const [isTemplate, setIsTemplate] = React.useState<boolean>(false)

  const handleClose = () => {
    props.setIsExport(false)
  };
  return (
    <HStack flex={"1 auto"} h={'full'} p={12}>
      <ExportProcess paperId={ props.paperId } setPreviewUrl={setPreviewUrl} setPdfName={setPdfName}
                     setLatexUrl={setLatexUrl}
                     setIsTemplate={setIsTemplate}/>
      <Box
        p={pxToRem(20)}
        w={'38%'}
        h={'full'}
        mx="auto"
        bg="#FBFCFF"
        position="relative"
        borderRadius={pxToRem(24)}
        border={'1px solid #D3D7E2'}
        px={pxToRem(32)}
        py={pxToRem(26)}
      >
        <CloseButton
          position="absolute"
          onClick={handleClose}
          _focusVisible={{ boxShadow: 'none' }}
          bg={'#D3D7E2'}
          width={pxToRem(24)}
          height={pxToRem(24)}
          color={'#8E9ABC'}
          top={pxToRem(20)}
          right={pxToRem(20)}
          fontSize={'8px'}
          borderRadius={'50%'} />
        <Text fontSize={pxToRem(20)} fontWeight={'bold'} fontFamily={'Lato'}>
          Preview
        </Text>
        <ExportPreview latexUrl={latexUrl} isTemplate={isTemplate} previewUrl={previewUrl} pdfName={pdfName} />
      </Box>
    </HStack>
  )
}