import { pxToRem } from "../../../utils/editor.ts";
import { List, ListItem, Text } from "@chakra-ui/react";
import React from "react";
import { PaperOutlineNode } from "../../../types/Writer/OutlineNode.ts";

interface StructureListNewProps {
  list: PaperOutlineNode[]
}

export const StructureListNew: React.FC<StructureListNewProps> = ({ list }) => {
  return (<List width={'100%'} flex={1} gap={2} flexDir={'column'} display={'flex'}
                alignContent={'center'}
                maxHeight={pxToRem(240)}
                overflow={'auto'}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#9EC3E8",
                    borderRadius: "2px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#2b6cb0",
                  }
                }}
  >
    {list?.length && list.map((item, index) => (
      <ListItem width={'90%'} m={'auto'} key={index} textAlign="center" bg={"#E0EAFF"} p={2} borderRadius={8}>
        <Text fontWeight="medium">{item.name}</Text>
      </ListItem>
    ))}
  </List>)
}