import {
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  ListItem, Text,
  Tooltip,
  VStack
} from "@chakra-ui/react";
import { useDrag, useDrop } from "react-dnd";
import { pxToRem } from "../../utils/editor.ts";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { useRef } from "react";
import { filter, findIndex } from "lodash";
import { EditListItemIcon } from "./EditListItemIcon.tsx";
import useOutlineStore from "../../stores/modules/outline.ts";

interface OutlineNodeItemProps {
  node: PaperOutlineNode
  currentNodeId:number
  setCurrentNodeId: (id: number) => void
  sectionRoot: PaperOutlineNode
  setSectionRoot: (node: PaperOutlineNode) => void
  moveNode: (from: PaperOutlineNode, to: PaperOutlineNode) => void;
  onOpenDeleteConfirmPanel: () => void
  setIsExport: (isExport: boolean) => void
  setInputArgs: (newTitle: string, label: string, position: number, node?: PaperOutlineNode) => void
}

/**
 * left outline node item
 */
export const OutlineNodeItem: React.FC<OutlineNodeItemProps> = ({
                                                                  node,
                                                                  sectionRoot,
                                                                  setIsExport,
                                                                  currentNodeId,
                                                                  setCurrentNodeId,
                                                                  setSectionRoot,
                                                                  moveNode,
                                                                  onOpenDeleteConfirmPanel,
                                                                  setInputArgs
                                                                }) => {
  const listItemRef = useRef(null)
  const btnColor: string = "#1D8AFF";
  const { outline, setCurrentSectionIndex } = useOutlineStore()
  const [, drop] = useDrop({
    accept: "card",
    hover: (dragItem: PaperOutlineNode) => {
      if (!listItemRef.current) return;
      if (node?.level !== 1) return
      if (node.id === dragItem.id) return;
      moveNode(dragItem, node);
    },
  });
  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: "card",
    item: node,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  dragPreview(drop(listItemRef));
  const opacity = isDragging ? 0.1 : 1;
  const borderColor = isDragging ? '#D3D7E2' : "transparent";
  return (
    <ListItem
      key={node.id.toString() + "|level" + node.level?.toString()}
      borderBottom={'3px solid ' + borderColor}
      userSelect={'none'}
      onClick={(e) => {
        e.stopPropagation()
        setSectionRoot(node)
        setIsExport(false)
        if (node.level === 1) {
          setCurrentSectionIndex(findIndex(outline.nodes, { id: node.id }))
          setCurrentNodeId(0)
        }
      }}
    >
      <VStack ref={listItemRef} style={{ opacity }}>
        {/** top add icon, add section*/}
        {sectionRoot?.id === node.id && (
          <VStack width={'100%'}>
            <Box
              w={'100%'}
              h={"1.5px"}
              bgColor={"#D3D7E2"}
              position={"relative"}
              top={pxToRem(22)}
              zIndex={0}
            />
            <IconButton
              size={"xs"}
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
              icon={
                <Image
                  w={pxToRem(40)}
                  objectFit="cover"
                  src="/static/editor/add-square.png"
                  alt="Doenba"
                />
              }
              onClick={(e) => {
                setInputArgs("New Section", "", e.pageY - 70);
              }}
              aria-label={""}
            />
          </VStack>
        )}
        <Center
          width={"100%"}
          // borderLeft={
          //   node.level === 1 && sectionRoot?.id === node.id
          //     ? `5px solid ${btnColor}`
          //     : null
          // }
        >
          <HStack
            bgColor={
              node.type === "section" &&
              sectionRoot?.id === node.id
                ? btnColor
                : null
            }
            alignItems="Center"
            width={pxToRem(192)}
            height={pxToRem(42)}
            _hover={{
              textColor: sectionRoot?.id === node.id ? "white" : btnColor,
            }}
            position={'relative'}
            borderRadius={"10px"}
            justifyContent={'space-between'}
            textColor={sectionRoot?.id === node.id ? "white" : "#8E9ABC"}
          >
            <IconButton
              bgColor={"transparent"}
              _hover={{ borderRadius: "15px" }}
              _active={ { background: 'rgba(56,152,255,0.38)', svg: { color: '#1D8AFF!important' } }}
              size={"sm"}
              ref={drag}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              icon={
                <PiDotsSixVerticalBold
                  style={{ width: pxToRem(20), height: pxToRem(20) }}
                  color={sectionRoot?.id === node.id ? "white" : "#8E9ABC"}
                  cursor="grab"
                />
              }
              aria-label={""}
            />
            <Tooltip hasArrow label={node.name} fontSize={'1rem'}
                     fontFamily={'Lato'}
                     minWidth={pxToRem(120)}
                     borderRadius={'4px'}
                     border={'2px solid #D3D7E2'}
                     sx={{
                       '.chakra-tooltip__arrow': {
                         bg: "#FBFCFF!important",
                         borderColor: "#D3D7E2",
                         borderLeftWidth: '2px',
                         borderBottomWidth: '2px',
                         borderStyle: "solid",
                         left: '-2px!important'
                       }
                     }}
                     bg='#FBFCFF' color='black' placement={'right'}>
              <Text
                as="b"
                cursor={'pointer'}
                fontSize={"1rem"}
                textAlign={'left'}
                fontFamily={"Lato"}
                width={pxToRem(140)}
                aria-multiline={false}
                isTruncated={true}>
                {node.name}
              </Text>
            </Tooltip>
            <EditListItemIcon node={node} sectionRoot={sectionRoot} setInputArgs={setInputArgs}
                              onOpenDeleteConfirmPanel={onOpenDeleteConfirmPanel}/>
          </HStack>
        </Center>
      </VStack>
      {/** subsection */}
      {node.level && node.children?.length > 0 && (
        <VStack textAlign={'center'} style={{ opacity }}>
          {
            filter(node.children, { type: OutlineNodeType.Section }).map((childNode) => {
              return (
                <Text
                  alignItems="Center"
                  width={pxToRem(120)}
                  ml={'-' + pxToRem(25)}
                  mt={childNode.name ? 2 : 0}
                  pb={childNode.name ? 1 : 0}
                  pl={4}
                  fontSize={'1rem'}
                  textAlign={'left'}
                  fontWeight={'semibold'}
                  fontFamily={'Lato'}
                  cursor={'pointer'}
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  key={childNode.id}
                  color={currentNodeId === childNode.id ? btnColor : '#8E9ABC'}
                  borderBottom={currentNodeId == childNode.id ? `3px solid ${btnColor}` : null}
                  onClick={(e) => {
                    e.stopPropagation()
                    setIsExport(false)
                    setSectionRoot(node)
                    setCurrentNodeId(childNode.id)
                    setCurrentSectionIndex(findIndex(outline.nodes, { id: node.id }))
                  }}
                >
                  {childNode.name}
                </Text>
              )
            })
          }
        </VStack>
      )}
    </ListItem>
  )
}