import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Text,
  VStack
} from "@chakra-ui/react";
import { pxToRem } from "../../../utils/editor.ts";
import { useTranslation } from "react-i18next";
import { PaperService } from "../../../services/paperService.ts";
import { ClientError } from "../../../utils/clientError.ts";
import eventBus, { EventType } from "../../../hooks/useEventBus.ts";
import { SelectContent } from "../../../types/Writer/SelectContents.ts";
import { RephraseResult } from "../../../types/Writer/RephraseSentencesResult.ts";
import Reorganize from "./Reorganize.tsx";
import { Rephrase } from "./Rephrase.tsx";
import { ReorganizeSectionResultVersion } from "../../../types/Writer/ReorganizeSectionResult.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import useAiTypeStore from "../../../stores/modules/aiType.ts";

interface RephraseProps {
  type: 'rephrase' | 'reorganize'
  isCalculated: boolean
  setIsCalculated: (isCalculated: boolean) => void
  updateOutline?: (updated: boolean) => void
}

export const AiPanelContainer: React.FC<RephraseProps> = (
  {
    type,
    isCalculated,
    setIsCalculated,
    updateOutline
  }
) => {
  const { t } = useTranslation()
  const { outline, currentSectionIndex } = useOutlineStore()
  const lengthMap = [
    t("editor-editormodel-AI-RephraseLength-0"),
    t("editor-editormodel-AI-RephraseLength-1"),
    t("editor-editormodel-AI-RephraseLength-2"),
    t("editor-editormodel-AI-RephraseLength-3"),
    t("editor-editormodel-AI-RephraseLength-4"),
  ];

  const degreeMap = [
    t("editor-editormodel-AI-Rephrasedegree-0"),
    t("editor-editormodel-AI-Rephrasedegree-1"),
    t("editor-editormodel-AI-Rephrasedegree-2"),
    t("editor-editormodel-AI-Rephrasedegree-3"),
    t("editor-editormodel-AI-Rephrasedegree-4"),
  ];
  const lengthPositions = ['2rem', '5rem', '5.5rem', '9.8rem', '12rem']
  const degreePositions = ['1.3rem', '3.1rem', '5.7rem', '8rem', '11.1rem']
  const [currentLength, setCurrentLength] = useState<string>(lengthMap[2])
  const [currentDegreeValue, setCurrentDegreeValue] = React.useState<string>(degreeMap[2])
  const [isError, setIsError] = useState<boolean>(false)
  // rephrase or reorganize result
  const [resultList, setResultList] = useState<RephraseResult[] | ReorganizeSectionResultVersion[]>([])
  const { selectContentArr, aiDataCache, setAiDataCache } = useAiTypeStore()
  const [defaultIndexs, setDefaultIndexs] = useState([])

  useEffect(() => {
    if (outline) {
      // reset or reflex
      const id = outline.nodes[currentSectionIndex]?.id
      const cacheData = aiDataCache.get(id) || {}
      if (cacheData[type]) {
        setResultList(cacheData[type])
        const indexs = Array.from(
          { length: cacheData[type].length },
          (_, i) => i
        )
        setDefaultIndexs(indexs)
      } else {
        setResultList([])
        setDefaultIndexs([])
      }
    }
  }, [type, currentSectionIndex])

  useEffect(() => {
    /**
     * receive editor rephrase event
     */
    eventBus.on(EventType.Rephrase, getPhrase)
    return () => {
      eventBus.off(EventType.Rephrase, getPhrase);
    }
  }, [])

  function setCache(rawData: RephraseResult[] | ReorganizeSectionResultVersion[]) {
    if (outline) {
      const id = outline.nodes[currentSectionIndex]?.id
      const data = { ...aiDataCache.get(id) }
      data[type] = rawData
      setAiDataCache(id, data)
    }
  }

  /**
   * rephrase api request
   */
  const getPhrase = async (selectContents?: SelectContent[]) => {
    // resetAIprogress(AIDataType.rephrase);
    if (outline) {
      setIsCalculated(true);
      const rephraseBody = {
        sectionNode: outline.nodes[currentSectionIndex],
        selectContents: selectContents || [],
        writingLevel: currentDegreeValue,
        writingLength: currentLength
      }
      try {
        const newPhrases = await PaperService.rephraseSentences(rephraseBody);
        const result = newPhrases.rephraseResult
        setResultList(result)
        setCache(result)
        setDefaultIndexs(Array.from(
          { length: result.length },
          (_, i) => i
        ))
        setIsCalculated(false)
      } catch (error) {
        setIsCalculated(false)
        new ClientError(error).toast();
      }
    }
    // saveAIData(id, AIDataType.rephrase, aiData);
  };

  /**
   * reorganize
   */
  const requestReorganise = async () => {
    setIsCalculated(true)
    try {
      const result = await PaperService.reorganizeSection(outline.nodes[currentSectionIndex], currentDegreeValue, currentLength);
      const list = [result.reorganizeSectionOptionsData?.reorganized_structure_version_1]
      setResultList(list)
      setCache(list)
      setDefaultIndexs(Array.from(
        { length: list.length },
        (_, i) => i
      ))
      setIsCalculated(false)
    } catch (error) {
      setIsCalculated(false)
      new ClientError(error).toast();
    }
  }

  return (
    <VStack width={pxToRem(272)}>
      <Box
        mt={2}
        width={pxToRem(272)}
        paddingLeft={lengthPositions[lengthMap.indexOf(currentLength)]}
      >
        <Text
          aria-multiline={false}
          display="inline-block"
          pl={1}
          pr={1}
          border={"1px solid #ccc"}
          borderRadius={"3px"}
          bg={'#FFFFFF'}
        >
          {currentLength}
        </Text>
      </Box>
      <HStack
        border={"1px solid #ccc"}
        borderRadius={'0.5rem'}
        paddingRight={1}
        width={'100%'}
        bg={'#FFFFFF'}
      >
        <Box
          borderRight={"1px solid #ccc"}
          borderRadius={'0.5rem'}
          bg={'#FFFFFF'}
        >
          <Image
            m={2}
            w={pxToRem(24)}
            objectFit="cover"
            transform={'rotate(45deg)'}
            src="/static/ToolAI/length.png"
            alt="length"
          />
        </Box>
        {lengthMap.map((item, index) => (
          <IconButton
            key={index.toString() + "|" + item}
            bg={item == currentLength ? "#3898FF61" : "transparent"}
            size={"sm"}
            border={
              item == currentLength ? "1px solid #3898FF" : undefined
            }
            borderRadius={"10px"}
            icon={
              <Image
                w={item == currentLength ? pxToRem(24) : "6px"}
                objectFit="cover"
                transform={'rotate(45deg)'}
                src={
                  "/static/ToolAI/" +
                  (item == currentLength ? "length.png" : "dot.png")
                }
              />
            }
            aria-label={""}
            onClick={() => {
              setCurrentLength(item);
            }}
          />
        ))}
      </HStack>
      <Box
        mt={2}
        width={"100%"}
      >
        <Text
          aria-multiline={false}
          display="inline-block"
          pl={1}
          pr={1}
          border={"1px solid #ccc"}
          bg={'#FFFFFF'}
          borderRadius={"3px"}
          whiteSpace={'nowrap'}
          marginLeft={
            degreePositions[degreeMap.indexOf(currentDegreeValue)]
          }
        >
          {currentDegreeValue}
        </Text>
      </Box>
      <HStack
        border={"1px solid #ccc"}
        borderRadius={"0.5rem"}
        paddingRight={1}
        bg={'#FFFFFF'}
        width={'100%'}
      >
        <Box
          borderRight={"1px solid #ccc"}
          borderRadius={"0.5rem"}
          bg={'#FFFFFF'}
        >
          <Image
            m={2}
            w={pxToRem(24)}
            objectFit="cover"
            src="/static/ToolAI/degree.png"
          />
        </Box>
        {degreeMap.map((item) => (
          <IconButton
            key={item}
            bg={item == currentDegreeValue ? "#3898FF61" : "transparent"}
            size={"sm"}
            border={
              item == currentDegreeValue
                ? "1px solid #3898FF"
                : undefined
            }
            borderRadius={"10px"}
            icon={
              <Image
                w={item == currentDegreeValue ? pxToRem(24) : "6px"}
                objectFit="cover"
                src={
                  "/static/ToolAI/" +
                  (item == currentDegreeValue ? "degree.png" : "dot.png")
                }
              />
            }
            aria-label={""}
            onClick={() => {
              setCurrentDegreeValue(item);
            }}
          />
        ))}
      </HStack>
      <Button
        bg={"#FFF"}
        border={"1px solid #3898FF"}
        width={pxToRem(118)}
        height={pxToRem(34)}
        textColor={"#3898FF"}
        borderRadius={pxToRem(6)}
        _hover={{ bg: '#1D8AFF', color: '#FFF' }}
        onClick={(e) => {
          e.stopPropagation()
          if (type === 'rephrase') {
            getPhrase(selectContentArr)
          }
          if (type === 'reorganize') {
            requestReorganise()
          }
        }}>
        {type === 'rephrase' ? t("editor-editormodel-AI-Rephrase") : t('editor-editormodel-AI-Reorganize')}
      </Button>
      {isCalculated ? (
        <VStack
          mt={2}
          width={pxToRem(272)}
          height={pxToRem(180)}
          border={"1px solid #D3D7E2"}
          borderRadius={pxToRem(12)}
          bgColor={"#FFFFFF"}
        >
          <Image
            mt={4}
            w={pxToRem(58)}
            h={"1rem"}
            objectFit="contain"
            src={"/static/doenba-logo-blue.png"}
            mr={pxToRem(170)}
          />
          <Image
            mt={4}
            w={pxToRem(32)}
            objectFit="cover"
            src="/static/loading-animate.svg"
            transform={"scale(2)"}
          />
          <Text mt={4}>{ type === 'rephrase' ? t("editor-editormodel-Rephrasing") : t('editor-editormodel-reorganize-loading')}</Text>
          <IconButton
            bg="transparent"
            size={"xs"}
            onClick={(e) => {
              e.stopPropagation()
              setIsCalculated(false);
              setIsError(false);
            }}
            icon={
              <Image
                w={"14px"}
                objectFit="contain"
                src={
                  "/static/ToolAI/" +
                  (isError ? "recalculate.svg" : "cancelCal.svg")
                }
                alt="cancel"
              />
            }
            aria-label="cancel"
          />
        </VStack>
      ) : (
        <Accordion
          allowMultiple
          defaultIndex={defaultIndexs}
        >
          {resultList.map((result, index) => (
            <AccordionItem
              mt={2}
              width={pxToRem(272)}
              key={index.toString() + "|" + result.id}
              border={"1px solid #D3D7E2"}
              borderRadius={pxToRem(12)}
              p={2}
              bgColor={"#FFFFFF"}
            >
              <VStack>
                <HStack>
                  <Image
                    w={pxToRem(58)}
                    h={"1rem"}
                    objectFit="cover"
                    src={"/static/doenba-logo-blue.png"}
                    mr={pxToRem(130)}
                  />
                  <AccordionButton _hover={{ bg: 'transparent' }}>
                    <Image width={pxToRem(24)} src={"/static/ToolAI/expand.png"} />
                  </AccordionButton>
                </HStack>
                <AccordionPanel>
                  {/** rephrase result or reorganize result */}
                  {type === 'rephrase' ? (<Rephrase result={result} />) :
                    <Reorganize
                      result={result}
                      index={index}
                      currentLength={currentLength}
                      currentDegree={currentDegreeValue}
                      updateOutline={updateOutline}
                  />}
                </AccordionPanel>
              </VStack>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </VStack>
  )
}