import {
    Box,
    Flex,
    Image,
    Tooltip,
    useBreakpointValue,
    Button,
    Icon,
    Link as ChakraLink,
    Center,
    VStack,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { GiArchiveResearch } from "react-icons/gi";
import { Link as RouterLink, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { LuPenTool } from "react-icons/lu";
import { IoIosHelpCircle, IoIosInformationCircle } from "react-icons/io";
import { useRecoilState } from "recoil";
import { leftDrawerOpenStatus } from "../../atoms/rootAtom";
import { pxToRem } from "../../utils/editor.ts";
import { useState } from "react";

const SideNav = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const isMobile = useBreakpointValue({ base: true, md: true, lg: true, xl: false });
    const isCurrentPathMyDrive = location.pathname === "/my-library";
    const navigate = useNavigate();
    const [, setDrawerOpenStatus] = useRecoilState(leftDrawerOpenStatus);
    const [hoverIndex, setHoverIndex] = useState<number>(1);

    const menus = [
        // {
        //     link: '/search',
        //     label: 'mini-nav-tasksearch',
        //     description: 'mini-nav-tasksearch-description',
        //     mobileIcon: Search2Icon
        // },
        {
            link: '/reader',
            label: 'mini-nav-taskrqa',
            description: 'mini-nav-taskrqa-description',
            mobileIcon: GiArchiveResearch,
            img: 'left-read.svg',
            activeImg: 'left-read-active.svg'
        },
        {
            link: '/editor',
            label: 'mini-nav-editor',
            description: 'mini-nav-editor-description',
            mobileIcon: LuPenTool,
            img: 'left-edit.svg',
            activeImg: 'left-edit-active.svg',
        },
        // {
        //     link: '/editor',
        //     label: 'mini-nav-paper-review',
        //     description: '',
        // }
        // {
        //     link: '/reader/notes',
        //     label: 'mini-nav-notes',
        //     description: 'mini-nav-notes-description',
        //     mobileIcon: GiArchiveResearch
        // }
    ]

    const matchingTabIndex = menus.findIndex(tab => matchPath(tab.link, location.pathname));

    const logoOnClick = () => {
        navigate('/editor')
    }

    return (
        <>
            <Flex flexDir={'column'} minW={isMobile ? '60px' : pxToRem(260)} justifyContent={'space-between'} h={'full'} borderRight={'2px solid white'}>
                <Flex flexDir={'column'} h={'full'}>
                    {!isMobile &&
                        <Center pt={5} mb={pxToRem(69)} mt={pxToRem(28)} onClick={logoOnClick}>
                            <Image
                                w={pxToRem(178)}
                                objectFit='cover'
                                src='/static/doenba-logo.svg'
                                alt='Doenba'
                            />
                        </Center>
                    }
                    {isMobile &&
                        <Center pt={5} onClick={logoOnClick}>
                            <Image
                                w={'35px'}
                                objectFit='cover'
                                src='/static/doenba-ico-blue.png'
                                alt='Doenba'
                            />
                        </Center>
                    }
                    <Flex flexDir={'column'} justifyContent={'space-between'} h={'full'}>
                        <Box>
                            {isCurrentPathMyDrive &&
                                <Flex flexDir='column'>
                                    {menus.map((menu, index) => (
                                        <Button mb={2} colorScheme="blue" key={menu.link}
                                                variant={matchingTabIndex === index ? 'solid' : 'ghost'}>
                                            <ChakraLink as={RouterLink} to={menu.link}>
                                                <Tooltip label={t(menu.description)} placement='bottom'>
                                                    <Image src={`/static/${menu.img}`} />
                                                    {t(menu.label)}
                                                </Tooltip>
                                            </ChakraLink>
                                        </Button>
                                    ))}
                                </Flex>
                            }
                            {!isCurrentPathMyDrive &&
                                <Flex flexDir='column'>
                                    {!isMobile && menus.map((menu, index) => (
                                      <ChakraLink textAlign={'right'} mb={pxToRem(40)} width={pxToRem(224)} as={RouterLink}
                                                  borderLeft={matchingTabIndex === index ? '3px solid #1D8AFF' : 'none'}
                                                  to={menu.link} key={menu.link}>
                                          <Tooltip p={3} borderRadius={pxToRem(5)} label={t(menu.description)} placement='right'>
                                            <Button
                                                    width={pxToRem(192)}
                                                    height={pxToRem(50)}
                                                    borderRadius={pxToRem(10)}
                                                    fontSize={pxToRem(22)}
                                                    fontFamily={'Lato'}
                                                    px={4}
                                                    justifyContent={'space-around'}
                                                    pl={'1rem'}
                                                    _hover={{ bg: '#1D8AFF', color: '#FFF' }}
                                                    bg={matchingTabIndex === index ? '#1D8AFF' : 'transparent'}
                                                    color={matchingTabIndex === index ? '#FFFFFF' : '#1D8AFF'}
                                                    onMouseOver={() => {
                                                        setHoverIndex(index)
                                                    }}
                                            >
                                                {menu.img && <Image width={pxToRem(24)} src={(matchingTabIndex === index || hoverIndex === index) ?
                                                      `/static/${menu.activeImg}` : `/static/${menu.img}`} />}
                                                    {t(menu.label)}
                                            </Button>
                                          </Tooltip>
                                      </ChakraLink>
                                    ))}
                                    {isMobile && menus.map((menu, index) => (
                                        <Button mb={2} colorScheme="blue" variant={matchingTabIndex === index ? 'solid' : 'ghost'} key={menu.link} _selected={{ bg: 'yellow.400', border: '1px solid black', borderRadius: '5px', fontWeight: '600' }}>
                                            <ChakraLink as={RouterLink} to={menu.link} pb={0}>
                                                <Tooltip label={t(menu.description)} placement='bottom'>
                                                    <span>
                                                        <Icon as={menu.mobileIcon} />
                                                    </span>
                                                </Tooltip>
                                            </ChakraLink>
                                        </Button>
                                    ))}
                                </Flex>
                            }
                        </Box>
                        <VStack py={12}>
                            {!isMobile &&
                                <>
                                    <ChakraLink
                                      _hover={{ textDecoration: 'none' }}
                                      mb={5}
                                      onClick={() => setDrawerOpenStatus(true)}>{t('editor-editormodel-leftTutorial')}</ChakraLink>
                                    {/*<ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">Help</ChakraLink>*/}
                                </>
                            }
                            {isMobile &&
                                <>
                                    <ChakraLink mb={5} onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosInformationCircle color="#0057ec" />
                                    </ChakraLink>
                                    <ChakraLink onClick={() => setDrawerOpenStatus(true)} variant="underline">
                                        <IoIosHelpCircle color="#0057ec" />
                                    </ChakraLink>
                                </>
                            }
                        </VStack>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
}

export default SideNav;