/**
 * find current edit node index
 */
import { InlineNode, OutlineNodeType, PaperOutlineNode } from "../types/Writer/OutlineNode.ts";
import { each, findIndex, merge } from "lodash";
import { CursorInfo } from "../stores/modules/editor.ts";

export const inlineType = ['refer', 'cite', 'equation', 'footnote']
/**
 * cross type
 */
export const crossRefType: string[] = [OutlineNodeType.Equation, OutlineNodeType.Figure, OutlineNodeType.Table, OutlineNodeType.Section]
/**
 * tag type
 */
const tagsType = ['STRONG', 'EM', 'U']

/**
 * px to rem
 */
export const pxToRem = (px: number) => {
  return px / 16 + 'rem';
}

/**
 * deep find outline node
 */
export const findNodeByIds = (sectionNodes: PaperOutlineNode[], ids: number[]): PaperOutlineNode[] => {
  const result = []
  const idSet = new Set(ids)

  function recursiveSearch(nodes: PaperOutlineNode[]) {
    for (const node of nodes) {
      if (idSet.has(node.id)) {
        result.push(node)
      }
      if (node.children && node.children.length > 0) {
        recursiveSearch(node.children)
      }
    }
  }

  recursiveSearch(sectionNodes)
  return result
}

/**
 * deep outline remove node
 * @param node
 * @param removeNode
 */
export const deleteNode = (node: PaperOutlineNode, removeNode: PaperOutlineNode) => {
  if (node.children?.length) {
    node.children = node.children.filter((item: PaperOutlineNode) => item.id !== removeNode.id)
    each(node.children, (item: PaperOutlineNode) => {
      deleteNode(item, removeNode)
    })
  }
}

export const getNodesWordsCount = (nodes: PaperOutlineNode[]) => {
    if (!nodes) return 0;
    let wordsCount = 0;
    nodes.forEach((node) => {
      if (!node) return;
      const str = node.type == OutlineNodeType.Paragraph ? node.content : node.name;
      if (str) {
        str.replace("\n", " ");
        wordsCount += str.trim().length
      }
    });
    return wordsCount;
  }

/**
 * set cursor position
 * @param node target cursor
 * @param offset
 */
export const setCursorPosition = (node: any, offset: number) => {
  try {
    const range = document.createRange();
    if (node) {
      range.selectNodeContents(node);
      if (offset > node.textContent?.length) {
        offset = node.textContent?.length
      }
      if (node.getAttribute && node.getAttribute('data-type') === 'block-cursor') {
        range.setStart(node.firstChild, 0)
      } else {
        range.setStart(node, offset)
      }
      if (offset === node.textContent?.length) {
        range.collapse(false)
      } else {
        range.collapse(true)
      }
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  } catch (error) {
    console.log(error)
  }
}

/**
 * check if the node label is unique
 */
export const sameNameNodes = (name: string, nodeList: PaperOutlineNode[]) => {
  const fileNodes = nodeList.filter((node) => node.name === name)
  let maxNum = 0
  fileNodes.forEach(node => {
    if (node.label) {
      const splits = node.label.split('_')
      const num = parseInt(splits[splits.length - 1])
      if (!isNaN(num) && maxNum < num) {
        maxNum = num
      }
    }
  })
  return fileNodes?.length === 0 ? 0 : (maxNum + 1)
}

/**
 * 获取当前编辑的li，包括其属性
 */
export const getCurrentEditLi = (startContainer: Node, nodeList: PaperOutlineNode[]) => {
  let editDom, id, type, index
  if (startContainer.nodeType === Node.TEXT_NODE) {
    editDom = startContainer.parentElement.closest('li')
  } else {
    editDom = (startContainer as HTMLElement).closest('li')
  }
  if (editDom) {
    id = editDom.getAttribute('data-id')?.split('-')[1]
    type = editDom.getAttribute('data-type')
    index = findIndex(nodeList, { id: Number(id) })
  }
  return {
    editDom,
    id,
    type,
    index
  }
}

export const isEmptyNode = (node: HTMLElement) => {
  return node.innerHTML.replace(/<br\s*\/?>/gi, '').trim() === ''
}

/**
 * 获取node对应的paragraph dom
 * @param node
 */
export const getNodePDom = (node: PaperOutlineNode) => {
  if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) {
    return document.querySelector(`.editable-textarea[data-id="${node.id}-${node.level}"]`)
  }
  return document.querySelector(`p[data-id="${node.id}-${node.level}"]`)
}

/**
 * 获取光标位置
 */
export const getCursorPos = (nodeList: PaperOutlineNode[]) => {
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) {
    let pIndex = 0
    for (let j = nodeList.length - 1; j >= 0; --j) {
      if (nodeList[j].type === OutlineNodeType.Paragraph) {
        pIndex = j
        break
      }
    }
    const pDom = getNodePDom(nodeList[pIndex]);
    return {
      target: pDom,
      start: 0,
      nodeIndex: pIndex,
    };
  }
  const range = selection.getRangeAt(0);
  const container = range.startContainer;
  // refer已经解决，inline-equation待解决 pDom下最后可能是3 pDom === range.startContainer && pDom.childNodes?.length === range.startOffset
  const { index } = getCurrentEditLi(container, nodeList)
  const offset = range.startOffset;

  return {
    target: container,
    start: offset,
    nodeIndex: index
  }
}


/**
 * 清除占位符
 */
export const isZero = (content: string) => {
  return content?.length === 1 && content.replace(/\uFEFF/g, '').length === 0
}

/**
 * generator table
 */
export const generatorTableHtml = (columns: string[], data: any) => {
  let tableHTML = '<table>';

  tableHTML += '<thead><tr>';
  columns.forEach(column => {
    tableHTML += `<th>${column.charAt(0).toUpperCase() + column.slice(1)}</th>`;
  });
  tableHTML += '</tr></thead>';

  tableHTML += '<tbody>';
  data.forEach(row => {
    tableHTML += '<tr>';
    columns.forEach(column => {
      tableHTML += `<td>${row[column] || ''}</td>`;
    });
    tableHTML += '</tr>';
  });
  tableHTML += '</tbody>';

  tableHTML += '</table>';

  return tableHTML;
}

/**
 * 将 Markdown 表格转换为 HTML 表格
 * @param markdownTable Markdown 表格的字符串
 * @returns HTML 表格字符串
 */
export function markdownTableToHtml(markdownTable: string): string {
  const lines = markdownTable.trim().split('\n').map(line => line.trim());

  if (lines.length < 2) {
    return '<table></table>';
  }

  const headerCells = lines[0]
    .split('|')
    .map(cell => cell.trim())
    .filter(cell => cell.length > 0);

  const separator = lines[1].split('|').map(cell => cell.trim());
  const isValidSeparator = separator.every(cell => /^\s*[:-]+\s*$/.test(cell));
  if (!isValidSeparator) {
    return '<table></table>';
  }

  const alignments = separator.map(cell => {
    if (cell.match(/^:?-+:$/)) return 'center';
    if (cell.match(/^:-/)) return 'left';
    if (cell.match(/-+:$/)) return 'right';
    return '';
  });

  let html = '<table>\n<thead>\n<tr>\n';
  headerCells.forEach((cell, index) => {
    const align = alignments[index] ? ` style="text-align: ${alignments[index]};"` : '';
    html += `  <th${align}>${cell}</th>\n`;
  });
  html += '</tr>\n</thead>\n<tbody>\n';

  const bodyRows = lines.slice(2);
  bodyRows.forEach(row => {
    const cells = row
      .split('|')
      .map(cell => cell.trim())
      .filter(cell => cell.length > 0);
    if (cells.length > 0) {
      html += '<tr>\n';
      cells.forEach((cell, index) => {
        const align = alignments[index] ? ` style="text-align: ${alignments[index]};"` : '';
        html += `  <td${align}>${cell}</td>\n`;
      });
      html += '</tr>\n';
    }
  });

  html += '</tbody>\n</table>';
  return html;
}

/**
 * Parse HTML table into columns and data
 * @param tableHtml - The HTML table string to parse
 * @returns Object with columns array and data array
 */
export const parseTableHtml = (tableHtml: string): { columns: string[], data: any[] } => {
  let columns = []
  const data = []

  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(tableHtml, 'text/html');
    const table = doc.querySelector('table');
    const headerRow = table.querySelector('thead tr');

    columns = Array.from(headerRow.querySelectorAll('th')).map(th => {
      return (th.textContent || '').toLowerCase(); // Normalize to lowercase
    });

    const bodyRows = table.querySelectorAll('tbody tr');

    bodyRows.forEach((row, index) => {
      const rowData: { [key: string]: string } = {};
      const cells = row.querySelectorAll('td');

      cells.forEach((cell, index) => {
        if (index < columns.length) {
          rowData[columns[index]] = cell.textContent || '';
        }
      });
      rowData.key = index.toString()
      data.push(rowData);
    });
  } catch (error) {
    console.log(error)
  }

  return { columns, data };
}

/**
 * 处理childNode
 * @param childNode
 * @param node 操作的node
 */
export const handleChildNode = (childNode: HTMLElement, node?: PaperOutlineNode) => {
  let content = ''
  const newSlot = {} // {refer: {}}
  if (childNode.nodeType === Node.TEXT_NODE) { // text node包含普通文本/&#xFEFF;
    content = childNode.textContent
  } else if (tagsType.includes(childNode.tagName)) { // strong em u标签
    content = childNode.outerHTML
  } else { // refer cite inline_equation
    const dataType = childNode.getAttribute('data-type')
    if (inlineType.includes(dataType)) {
      content = childNode.getAttribute('data-content')
      if (node) {
        const match = content.match(/\$(\w+){(\d+)}\$/)
        if (match) {
          const id = Number(match[2]);
          newSlot[match[1]] = {}
          newSlot[match[1]][id] = node[match[1]][id];
        }
      }
    }
  }
  return {
    content,
    newSlot
  }
}

/**
 * 根据当前行和node children信息，更新node.content
 * @param node 当前操作node
 */
export const domToContent = (node: PaperOutlineNode) => {
  let content = ''
  const newSlot = {}
  const pDom = getNodePDom(node)

  if(pDom?.childNodes?.length === 1 && pDom.firstChild?.nodeType === Node.TEXT_NODE) {
    if (node.type === OutlineNodeType.Section) {
      content = pDom.textContent
    } else {
      content = pDom.textContent
    }
  } else if (crossRefType.includes(node.type) && node.type !== OutlineNodeType.Section) {
    content = node.content
  } else if (pDom?.childNodes?.length > 1) {
    pDom.childNodes?.forEach((childNode: HTMLElement) => {
      const info =handleChildNode(childNode)
      content += info.content
      for (const key in info.newSlot) {
        for (const id in info.newSlot[key]) {
          newSlot[key][id] = info.newSlot[key][id]
        }
      }
    })
  }
 
  return {
    content,
    newSlot
  }
}

/**
 * dom中插入内容
 * @param node 当前操作node
 * @param currentCursor 当前光标信息
 * @param slotContent 插入内容 refer cite inline-equation
 * */
export const insertContentToDom = (node: PaperOutlineNode, currentCursor: CursorInfo, slotContent: string) => {
  const pDom = getNodePDom(node)
  let content = ''
  if (pDom === currentCursor.target && currentCursor.target.childNodes?.length === 0) {
    content += slotContent
  } else {
    if (currentCursor.start === pDom.childNodes.length && currentCursor.target === pDom) {
      if (node.type === OutlineNodeType.Paragraph) {
        content = node.content + slotContent
      } else {
        content = node.name + slotContent
      }
    } else {
      pDom.childNodes?.forEach((childNode: HTMLElement) => {
        if (childNode === currentCursor.target) {
          content += childNode.textContent.slice(0, currentCursor.start) + slotContent + childNode.textContent.slice(currentCursor.start)
        } else {
          if (childNode.nodeType === Node.TEXT_NODE) {
            content += childNode.textContent.replace(/\uFEFF/g, '')
          } else if (tagsType.includes(childNode.tagName)) {
            content += childNode.outerHTML
          } else {
            const dataType = childNode.getAttribute('data-type')
            if (inlineType.includes(dataType)) {
              const equationContent = childNode.getAttribute('data-content')
              content += equationContent
            }
          }
        }
      })
    }
  }
  return {
    content
  }
}

/**
 * 分割tag文本
 * @param el
 * @param currentNode
 * @param splitIndex
 */
function splitByIndexUsingRegex(el: HTMLElement, currentNode: HTMLElement, splitIndex: number) {
  if (el.nodeType === Node.TEXT_NODE) {
    return {
      left: el.textContent.slice(0, splitIndex),
      right: el.textContent.slice(splitIndex)
    }
  } else {
    const tag = el.tagName.toLowerCase();
    let left = `<${tag} class="editable-textarea">`
    let right = `<${tag} class="editable-textarea">`
    let afterSplit = false
    el.childNodes.forEach((child: HTMLElement) => {
      if (child.contains(currentNode)) {
        const info = splitByIndexUsingRegex(child, currentNode, splitIndex)
        left += info.left
        right += info.right
      } else if (child === currentNode) {
        left += child.textContent.slice(0, splitIndex)
        right += child.textContent.slice(splitIndex)
        afterSplit = true
      } else {
        if (afterSplit) {
          right += child.outerHTML || ''
        } else {
          left += child.outerHTML || ''
        }
      }
    })
    return {
      left,
      right
    };
  }
}

/**
 * 自动补全 /> 标签
 * @param str
 */
function completeWithDOM(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(`<div>${str}</div>`, 'text/html');
  const div = doc.querySelector('div');
  return div.innerHTML;
}
/**
 * split 分割dom, update content
 * @param node 当前操作node
 * @param container
 * @param offset
 * */
export const splitDomToContent = (node: PaperOutlineNode, container: HTMLElement, offset: number): {
  next: string
  nextObj: {
    [key: string]: InlineNode
  }
  currentObj: PaperOutlineNode
} => {
  const pDom = getNodePDom(node)
  let current = ''
  let next = ''
  let nextObj = {}

  if (pDom.childNodes.length) {
    let afterSplit = false
    pDom.childNodes?.forEach((childNode: HTMLElement) => {
      const isTag = tagsType.includes(childNode.tagName)
      if (childNode === container || childNode.contains(container)) {
        if (isTag) {
          const content = splitByIndexUsingRegex(childNode, container, offset)
          next = completeWithDOM(content.right)
          current += completeWithDOM(content.left)
        } else {
          const text = childNode.textContent
          next = text.slice(offset)
          current += text.slice(0, offset)
        }
        afterSplit = true
      } else {
        if (afterSplit) { // 后内容
          const nextInfo = handleChildNode(childNode, node)
          next += nextInfo.content
          nextObj = merge(nextObj, nextInfo.newSlot)
          for (const key in nextInfo.newSlot) {
            Object.keys(nextInfo.newSlot[key]).forEach((k) => {
              delete node[key][k]
            })
          }
        } else { // 前半部分
          const currentInfo = handleChildNode(childNode, node)
          current += currentInfo.content
        }
      }
    })
  } else {
    current = pDom.textContent
    if (offset < current?.length) {
      next = current.slice(offset);
      current = current.slice(0, offset);
    }
  }
  if (node.type === OutlineNodeType.Section) {
    node.name = current
  } else {
    node.content = current
  }

  return {
    next,
    nextObj,
    currentObj: node
  }
}

export const splitDomToContent2 = (node: PaperOutlineNode, range: Range) => {
  const pDom = getNodePDom(node)
  let extraContent = ''
  let selectContent = ''
  let selectObj = {}
  const { startOffset, endOffset } = range
  const startContainer = range.startContainer as HTMLElement
  const endContainer = range.endContainer as HTMLElement
  const key = node.type === OutlineNodeType.Section ? 'name' : 'content'
  if (pDom.childNodes.length && (node[key]?.indexOf('($') > -1 || node[key]?.indexOf('(</') > -1)) {
    let after = false
    let start = false
    pDom.childNodes?.forEach((childNode: HTMLElement) => {
      const isTag = tagsType.includes(childNode.tagName)
      if (childNode === startContainer || childNode.contains(startContainer)) {
        if (isTag) {
          const content = splitByIndexUsingRegex(childNode, startContainer, startOffset)
          extraContent += completeWithDOM(content.left)
          selectContent += completeWithDOM(content.right)
        } else {
          const text = childNode.textContent
          extraContent += text.slice(0, startOffset)
          if (startContainer === endContainer) {
            selectContent += text.slice(startOffset, endOffset)
            extraContent += text.slice(endOffset)
            after = true
          } else {
            selectContent += text.slice(startOffset)
          }
        }
        start = true
      } else  if (childNode === endContainer || childNode.contains(endContainer)) {
        if (isTag) {
          const content = splitByIndexUsingRegex(childNode, endContainer, endOffset)
          extraContent += completeWithDOM(content.right)
          selectContent += completeWithDOM(content.left)
        } else {
          const text = childNode.textContent
          extraContent += text.slice(endOffset)
          selectContent += text.slice(0, endOffset)
        }
        after = true
      } else if (after) {
        extraContent += handleChildNode(childNode)?.content
      } else if (start) {
        const nextInfo = handleChildNode(childNode, node)
        selectContent += nextInfo?.content
        selectObj = merge(selectObj, nextInfo.newSlot)
      }
    })
  } else {
    extraContent = pDom.textContent?.slice(0, startOffset) + pDom.textContent?.slice(endOffset)
    selectContent = pDom.textContent?.slice(startOffset, endOffset)
  }

  return {
    content: extraContent,
    selectContent,
    selectObj
  }
}

/**
 * 插入行内元素时,光标处理
 */
export function handleInlineCursor(node: PaperOutlineNode, lastInsert: string) {
  if (node) {
    const dom = document.querySelector(`li[data-id="li-${node.id}"] span[data-content="${lastInsert}"]`)
    if (dom?.nextSibling) {
      setCursorPosition(dom.nextSibling as HTMLElement, 0)
      return
    }
  }
}

/**
 * 删除section时， 将其children转移至其最近的section
 * @param nodeList
 * @param index section index
 */
export function handlerChildTransfer(nodeList: PaperOutlineNode[], index: number) {
  let nextLevelIndex = index + 1
  let preLevelUpIndex = 1
  for (let i = 1; i < nodeList.length - 1; i++) {
    const node = nodeList[i]
    if (node.type === OutlineNodeType.Section && node.level <= nodeList[index].level) {
      if (i < index) {
        preLevelUpIndex = i
      } else {
        nextLevelIndex = i // 最近的section
      }
    }
  }
  for (let i = preLevelUpIndex + 1; i < nextLevelIndex; i++) {
    nodeList[i].parentId = nodeList[preLevelUpIndex].id; // 更新parentId为preLevelUpIndex的id
    nodeList[i].level = nodeList[preLevelUpIndex].level + 1; // 更新level为preLevelUpIndex的level + 1
  }
}


/**
 * 删除pDom中的inline内容
 */
export function deleteInlineContent(node: PaperOutlineNode, inline: Element) {
  const pDom = getNodePDom(node)
  const id = inline.getAttribute('data-id')
  const inlineType = inline.getAttribute('data-type')
  if (node[inlineType] && node[inlineType][id]) {
    delete node[inlineType][id]
  }
  let content = ''
  let index = 0
  pDom.childNodes.forEach((child: HTMLElement, childIndex: number) =>{
    if (child !== inline) {
      const childInfo = handleChildNode(child)
      content += childInfo.content
    } else {
      index = childIndex
    }
  })
  return { index, content }
}