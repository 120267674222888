// ExportPreview.tsx
import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import {
  Box,
  Button,
  Text,
  Select,
  HStack, VStack,
} from '@chakra-ui/react';
import { pxToRem } from "../../../utils/editor.ts";

const ExportPreview: React.FC<{
  previewUrl: string
  latexUrl: string
  pdfName: string
  isTemplate?: boolean
}> = ({ previewUrl, pdfName, latexUrl }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageWidth, setPageWidth] = useState(600); // Default width
  const containerRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState<string>('pdf');

// 使用 pdfjs-dist 提供的在线 CDN 链接
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@4.8.69/build/pdf.worker.min.mjs`;
  // previewUrl = '/Vue3.pdf'
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setPageWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial resize on mount

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  async function downloadFile(previewUrl, pdfName) {
    try {
      const response = await fetch(previewUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('网络响应失败');
      }

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = pdfName + '.pdf';
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('download error:', error);
    }
  }

  const handleSelect = (value: string) => {
      if (value === 'pdf') {
        downloadFile(previewUrl, pdfName);
      }
      if (value === 'latex') {
        window.open(latexUrl);
      }
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  return (
    <VStack h={'100%'} w={'100%'} alignItems={'start'} pt={4}>
      {/* PDF Preview */}
      <HStack w={'full'} justifyContent={'space-between'} h={`calc(100% - 90px)`}>
        {/*{isTemplate && <IconButton*/}
        {/*  bg={'transparent'}*/}
        {/*  onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}*/}
        {/*  disabled={pageNumber === 1}*/}
        {/*  icon={*/}
        {/*    <Image src={'/static/editor/pre.svg'} />*/}
        {/*  }*/}
        {/*  aria-label={'Previous'}*/}
        {/*>*/}
        {/*</IconButton>}*/}
        <Box ref={containerRef} w={'100%'} h={'100%'}
             sx={{
               ".react-pdf__Document": {
                 overflowY: 'auto',
                 overflowX: 'hidden',
                 height: '100%',
                 width: '100%',
               },
               ".react-pdf__Page": {
                 width: '100%',
                 marginBottom: '1rem'
               },
               canvas: {
                 width: '98%!important',
                 border: '2px solid #D3D7E2',
                 borderRadius: pxToRem(24)
                }
             }}>
          <Document
            file={previewUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => console.error("Error loading PDF:", error)}
            loading={<Text>Loading PDF...</Text>}
          >
            {/* 渲染所有页面 */}
            {Array.from(new Array(numPages), (_, index) => (
                <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    width={pageWidth}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                />
            ))}
          </Document>
        </Box>
        {/*{isTemplate && <IconButton*/}
        {/*  bg={'transparent'}*/}
        {/*  onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages || 1))}*/}
        {/*  icon={*/}
        {/*    <Image src={'/static/editor/next.svg'} />*/}
        {/*  }*/}
        {/*  aria-label={'Next'}*/}
        {/*  disabled={pageNumber === numPages}*/}
        {/*>*/}
        {/*</IconButton>}*/}
        {/*<HStack mt={4} justify="center">*/}
        {/*  <Text>*/}
        {/*    Page {pageNumber} of {numPages || 'N/A'}*/}
        {/*  </Text>*/}
        {/*</HStack>*/}
      </HStack>
      {latexUrl && <HStack w={pxToRem(240)} mx={'auto'} justifyContent={'center'} alignItems={'center'} gap={0}>
        {/* Select Button */}
         <Button
            w={pxToRem(139)}
            h={'48px'}
            bg={'#1D8AFF'}
            _hover={{ background: '#1D8AFF', color: '#FFFFFF' }}
            border={'1px solid #1D8AFF'}
            borderRadius={0}
            borderRight={'1px solid #FFF'}
            borderLeftRadius={pxToRem(24)}
            color={'#FFFFFF'}
            onClick={() => {
              handleSelect(selectedOption)
            }}>
            Download
          </Button>
          <Select
              w={pxToRem(100)}
              border={0}
              borderRadius={0}
              height={'48px'}
              bg={'#1D8AFF'}
              outline={0}
              borderRightRadius={pxToRem(24)}
              _focusVisible={{ boxShadow: 'none' }}
              color={'#FFFFFF'}
              sx={{
                option: {
                  color: '#333',
                }
              }}
              value={selectedOption} onChange={(e) => {
            setSelectedOption(e.target.value)
            handleSelect(e.target.value)
          }}>
            <option value='pdf'>.PDF</option>
            <option value='latex'>.LaTeX</option>
          </Select>
      </HStack>}
    </VStack>
  );
};

export default ExportPreview;