import {
  Box, Button,
  Flex,
  Heading, HStack, Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay, Text,
  useToast, VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ClientError } from "../../../utils/clientError.ts";
import { OutlineService } from "../../../services";
import { pxToRem } from "../../../utils/editor.ts";
import { PaperOutline, PaperOutlineNode } from "../../../types/Writer/OutlineNode.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import DonebaSpinner from "../../DoenbaSpinner/DoenbaSpinner.tsx";
import FileUpload from "../FileUpload.tsx";
import { StructureListNew } from "./StructureListNew.tsx";

interface DoenbaStructureProps {
  isOpenModal: boolean
  writingArea: string
  structureType: 'import' | 'refer' | 'doenba'
  onModalClose: () => void
  updateOutlineStructure: (outline: PaperOutline) => void;
  setPopOpen: (popOpen: boolean) => void
}
/** create new document */
export const DoenbaStructure: React.FC<DoenbaStructureProps> = ({ isOpenModal, onModalClose, structureType, writingArea, updateOutlineStructure, setPopOpen }) => {
  const [structures, setStructures] = useState<PaperOutlineNode[]>([])
  const [ loading, setLoading ] = useState<boolean>(false)
  const [ applying, setApplying ] = useState<boolean>(false)
  const [ uploading, setUploading ] = useState<boolean>(false)
  const { outline, setOutline, setCreateOutline, setNextId } = useOutlineStore()
  const [outlineTemp, setOutlineTemp] = useState<PaperOutline>()
  const toast = useToast()

  const closeModal = () => {
    setStructures([])
    onModalClose()
    setUploading(false)
    setApplying(false)
    setLoading(false)
    setCreateOutline(true)
  }

  useEffect(() => {
    if (structureType === 'doenba') {
      generator()
    }
  }, []);
  /**
   * generator
   */
  const generator = async () => {
    if (!writingArea) {
      toast({
        description: 'Please write some thing!',
        status: "warning",
        duration: 1000,
      });
      return
    }
    try {
      setLoading(true)
      const res = await OutlineService.createDefaultOutlineByWrite({ writingArea })
      setStructures(res?.outline?.nodes)
      setOutlineTemp(res.outline)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      new ClientError(error).toast();
    }
  }

  const afterUpload = (outline: PaperOutline) => {
    if (outline) {
      setOutlineTemp(outline)
      setStructures(outline?.nodes)
      setUploading(true)
      if (structureType === 'import') {
        setOutline(outline)
        setNextId(outline.nextId)
        updateOutlineStructure(outline)
        closeModal()
      }
    }
  }

  const applyOutline = async () => {
    try {
      setApplying(true)
      const res = await OutlineService.mergeOutline({
        outline: outline || {},
        selectedOutline: outlineTemp
      })
      setOutline(res.outline)
      setNextId(outline.nextId)
      updateOutlineStructure(outline)
      setApplying(false)
      closeModal()
    } catch (error) {
      setApplying(false)
      new ClientError(error).toast();
    }

  }
  return (
    <Modal isOpen={isOpenModal} onClose={closeModal} size={'lg'} closeOnOverlayClick={false}>
      <ModalOverlay bg={'whiteAlpha.300'}/>
      <ModalContent minW={structureType === 'import' ? pxToRem(600) : pxToRem(736)}
                    minH={structureType === 'import' ? pxToRem(300) : pxToRem(600)} bg={'#FBFCFF'}
                    boxShadow={'none'}
                    border={'2px solid #D3D7E2'}
                    mt={'10%'}
                    ml={'-5%'}
                    borderRadius={pxToRem(24)}>
        <Heading size={'md'} ml={6} mt={4} mb={1}>
          {structureType === 'import' ? 'Import File' : (structureType === 'doenba' ? 'The Default Outline Selected' : 'Extracting Outline')}
          </Heading>
        <ModalCloseButton _focusVisible={{ boxShadow: 'none' }}
                          bg={'#D3D7E2'}
                          width={pxToRem(24)}
                          height={pxToRem(24)}
                          color={'#8E9ABC'}
                          top={pxToRem(20)}
                          right={pxToRem(20)}
                          fontSize={'8px'}
                          borderRadius={'50%'} />
        <ModalBody p={16} alignItems={structureType === 'refer' ? 'center' : 'start'} display={'flex'} flexDir={uploading ? 'column' : 'row'}>
          {structureType === 'import' && (
            <React.Fragment>
              {uploading ?
                <Flex borderRadius={8}
                      py={"48px"}
                      border={ "1px solid #C9D8E7" }
                      background={"#FFFFFF"}
                      flexDir={'column'}
                      justifyContent={"center"}
                      alignItems="center">
                  <Image
                    w={"80px"}
                    objectFit="cover"
                    src="/static/editor/check_ok.png"
                  />
                  <Text
                    mt={2}
                    fontSize={12}
                    color="gray.400"
                    lineHeight="12px"
                    textAlign="center"
                  >
                    Please Click Next to Continue
                  </Text>
                </Flex> :
                <FileUpload
                  uploadText={'Drag and upload a file to use Doenba Edit'}
                  flexDir={'column'} usage={'draft'} border={true} afterUpload={afterUpload} />}
            </React.Fragment>
          )}
          {structureType === 'doenba' && (
            <React.Fragment>
              <VStack alignItems={'center'} justifyContent={'center'} width={'100%'}>
                {/*<FormControl>*/}
                {/*  <FormLabel>*/}
                {/*    <Flex flexDir={'row'} alignItems={'center'}>*/}
                {/*      What is your writing area?*/}
                {/*      <Text ml={1} color='red'>*</Text>*/}
                {/*    </Flex>*/}
                {/*  </FormLabel>*/}
                {/*  <Textarea*/}
                {/*    rows={3}*/}
                {/*    bg={'white'}*/}
                {/*    value={writingArea}*/}
                {/*    borderRadius={'12px'}*/}
                {/*    _focusVisible={{ borderColor: '#1D8AFF' }}*/}
                {/*    onChange={(e) => {*/}
                {/*      setWritingArea(e.target.value)*/}
                {/*    }}*/}
                {/*  />*/}
                {/*</FormControl>*/}
                {/*<Button*/}
                {/*  w="120px"*/}
                {/*  mt={4}*/}
                {/*  bg={'#1D8AFF'}*/}
                {/*  _hover={{ background: '#1D8AFF' }}*/}
                {/*  border={'1px solid #1D8AFF'}*/}
                {/*  isDisabled={loading}*/}
                {/*  borderRadius={pxToRem(12)}*/}
                {/*  color={'#FFFFFF'}*/}
                {/*  onClick={generator}>*/}
                {/*  Regenerate*/}
                {/*</Button>*/}
                {/** structure list */}
                {(loading || structures?.length) && <React.Fragment>
                    <Box
                      height={pxToRem(300)}
                      w={'full'}
                      mt={12}
                      bg={'transparent'}
                      border={'1px solid #1D8AFF'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      borderRadius={'12px'} p={4}>
                      {loading ? <Flex flexDir={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}>
                          <DonebaSpinner/>
                          <Text mt={5} color={'gray.400'}>Creating default Structure</Text>
                      </Flex> : <StructureListNew list={structures} />}
                  </Box>
                  {!loading && <HStack w={'full'} justifyContent={'space-between'}>
                        <Button
                            w="120px"
                            mt={4}
                            bg={'#FFF'}
                            _hover={{ background: '#FFF' }}
                            border={'1px solid #1D8AFF'}
                            borderRadius={pxToRem(12)}
                            color={'#1D8AFF'}
                            onClick={() => {
                              setPopOpen(true)
                              closeModal()
                            }}>
                            Back
                        </Button>
                        <Button
                            w="120px"
                            mt={4}
                            bg={'#1D8AFF'}
                            _hover={{ background: '#1D8AFF' }}
                            border={'1px solid #1D8AFF'}
                            isDisabled={loading || applying}
                            isLoading={applying}
                            borderRadius={pxToRem(12)}
                            color={'#FFFFFF'}
                            onClick={() => {
                              applyOutline()
                            }}>
                            Apply
                        </Button>
                    </HStack>}
                </React.Fragment>}
              </VStack>
            </React.Fragment>
          )}
          {structureType === 'refer' && (
            <React.Fragment>
              {/*{uploading && <Text fontFamily={'Lato'}>*/}
              {/*    We have successfully extracted the structure of the article.*/}
              {/*</Text>}*/}
              {uploading ?
                <Box
                  height={pxToRem(300)}
                  w={'full'}
                  mt={12}
                  bg={'transparent'}
                  border={'1px solid #1D8AFF'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  borderRadius={'12px'} p={4}>
                    <StructureListNew list={structures} />
                </Box> :
                <FileUpload flexDir={'column'} usage={'reference'}
                            uploadText={'Drag and upload your favorite articleto extract its outline.'}
                            border={true} afterUpload={afterUpload} />}
              {uploading && <HStack w={'full'} justifyContent={'space-between'} mt={'auto'}>
                  <Button
                      w="120px"
                      mt={4}
                      bg={'#FFF'}
                      _hover={{ background: '#FFF' }}
                      border={'1px solid #1D8AFF'}
                      borderRadius={pxToRem(12)}
                      color={'#1D8AFF'}
                      onClick={() => {
                        setUploading(false)
                      }}>
                      Back
                  </Button>
                  <Button
                      w="120px"
                      mt={4}
                      bg={'#1D8AFF'}
                      _hover={{ background: '#1D8AFF' }}
                      border={'1px solid #1D8AFF'}
                      isDisabled={loading || applying}
                      isLoading={applying}
                      borderRadius={pxToRem(12)}
                      color={'#FFFFFF'}
                      onClick={() => {
                        applyOutline()
                      }}>
                      Apply
                  </Button>
              </HStack>}
            </React.Fragment>
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}