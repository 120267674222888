import { OutlineNodeType, PaperOutlineNode } from "./OutlineNode";
import { cloneDeep } from "lodash";

const getTreeNodesToList = (
  data: PaperOutlineNode[],
  filter?: OutlineNodeType | undefined,
  root?: PaperOutlineNode
): PaperOutlineNode[] => {
  if (!data) return;
  let nodeList: PaperOutlineNode[] = [];
  for (let i = 0; i < data?.length; i++) {
    const node = cloneDeep(data[i]);
    if (!node) continue;
    if (filter == undefined || node?.type === filter) {
      // delete  newNode.children
      if (root) {
        node.parentId = root?.id
      }
      if (node.type === OutlineNodeType.Paragraph && node?.children?.length) {
        // 检查其children 在content中是否有槽位，不存在删除
        node.children = node.children.filter((child) => {
          return node.content.indexOf(child.id?.toString()) > -1;
        })
      }
      nodeList.push(node);
    }
    if (node?.children?.length > 0) {
      if (node.type === OutlineNodeType.Section) {
        nodeList = nodeList.concat(getTreeNodesToList(node.children, filter, node));
      }
    }
  }
  return nodeList;
};

/**
 * nodeList 生成outline
 * @param nodeList
 */
const listToTree = (nodeList: PaperOutlineNode[]): PaperOutlineNode[] => {
  const nodeMap: { [key: number]: PaperOutlineNode } = {};
  const tree: PaperOutlineNode[] = [];

  nodeList.forEach(node => {
    if (node.type === OutlineNodeType.Paragraph) {
      nodeMap[node.id] = { ...node };
    } else {
      nodeMap[node.id] = { ...node, children: [] };
    }
  });
  nodeList.forEach(node => {
    if (node.parentId !== undefined && nodeMap[node.parentId]) {
      nodeMap[node.parentId].children?.push(nodeMap[node.id]);
    } else {
      tree.push(nodeMap[node.id]);
    }
  });

  return tree;
}

const getNodeRootInList = (
  data: PaperOutlineNode[],
  node: PaperOutlineNode
): PaperOutlineNode => {
  if (!data || !node) return;
  for (let i = 0; i < data.length; i++) {
    if (data[i]?.id == node.id) {
      return data[i];
    }
    const nodeFind = getNodeRootInList(data[i]?.children, node);
    if (nodeFind) {
      return data[i].level == 1 ? data[i] : nodeFind;
    }
  }
  return undefined;
};

const formatString = (template: string, ...args: any[]): string => {
  return template.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
};
function alignStringLen(text: string, targetLength: number): string {
  if(!text) return
  let length = 0;
  for (let i = 0; i < text.length; i++) {
    if (/[\u4e00-\u9fa5]/.test(text[i])) {
      length += 2;
    } else {
      length += 1;
    }
    if (length >= targetLength - 1) {
      return text.slice(0, targetLength - 1) + "…";
    }
  }
  const spaceCount = Math.floor((targetLength - text.length) / 2);
  return " ".repeat(spaceCount) + text + " ".repeat(spaceCount);
}

interface DragInfo {
  rectTop: number;
  rectButton: number;
}

export {
  getTreeNodesToList as getTreeNodes,
  formatString,
  getNodeRootInList as getNodeRoot,
  alignStringLen,
  listToTree
};
export type { DragInfo };
