import { create } from 'zustand';
import { AIDataType } from "../../types/Writer/AIData.ts";
import { SelectContent } from "../../types/Writer/SelectContents.ts";

interface CounterState {
  aiType: AIDataType | null;
  updateAiType: (newOutline: AIDataType | null) => void;
  selectContentArr: SelectContent[]
  setSelectContentArr: (newSelectContentArr: SelectContent[]) => void
  aiDataCache: Map<number, any> // todo: 还要与currentSectionIndex绑定，滚动切换
  setAiDataCache: (id: number, newAiDataCache: any) => void
}

const useAiTypeStore = create<CounterState>((set) => ({
  aiType: null,
  updateAiType: (newAiType) => set(() => ({ aiType: newAiType })),
  selectContentArr: [],
  setSelectContentArr: (newSelectContentArr) => set(() => ({ selectContentArr: newSelectContentArr })),
  aiDataCache: new Map(),
  setAiDataCache:(id: number, newAiDataCache: any) => set((state) => {
    const updatedMap = state.aiDataCache;
    updatedMap.set(id, newAiDataCache);
    return { aiDataCache: updatedMap };
  })
}))

export default useAiTypeStore;

