import { Button, Center, Flex, HStack, IconButton, Image, Text, Tooltip } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AIDataType } from "../../types/Writer/AIData";
import { domToContent, getCursorPos, pxToRem } from "../../utils/editor.ts";
import useAiTypeStore from "../../stores/modules/aiType.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { cloneDeep } from "lodash";
import React, { useState } from "react";
import useEditorStore from "../../stores/modules/editor.ts";
import InsertTable from "./InserTool/InsertTable.tsx";

enum ToolType {
  Bold = 'strong',
  Italic = 'em',
  Underline = 'u',
  Equation = 'equation',
  Figure = 'figure',
  Table = 'table',
  Foot = 'footnote',
}
const ToolAIBox: React.FC<{
  openLatex: () => void
  openTable: () => void
  openFoot: () => void
  openFigure: () => void
  isOpenTable: boolean
  closeTable: () => void
}> = ({ openLatex, openTable, openFigure, closeTable, isOpenTable, openFoot }) => {
  const { t } = useTranslation();
  const { aiType, updateAiType } = useAiTypeStore()
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()
  const [activeTools, setActiveTools] = useState<ToolType[]>([])
  const basicTools = [
    { img: '/static/ToolAI/b.png', label: t("toolbox-bold"), type: ToolType.Bold },
    { img: '/static/ToolAI/i.png', label: t("toolbox-italic"), type: ToolType.Italic },
    { img: '/static/ToolAI/u.png', label: t("toolbox-underline"), type: ToolType.Underline },
    { img: '/static/ToolAI/equation.png', label: t("toolbox-math"), type: ToolType.Equation },
    { img: '/static/ToolAI/image.png', label: t("toolbox-image"), type: ToolType.Figure },
    { img: '/static/ToolAI/table.png', label: t("toolbox-table"), type: ToolType.Table },
    { img: '/static/ToolAI/footnote.png', label: t("toolbox-footnote"), type: ToolType.Foot },
  ]
  const AIBtns = [
    { img: '/static/ToolAI/magicpen.png', label: t("editor-editormodel-AI-Rephrase"), type:AIDataType.rephrase, tooltip: 'Paraphrase selected texts.' },
    { img: '/static/ToolAI/clipboard-tick.png', label: t("editor-editormodel-AI-Suggestions") , type:AIDataType.suggestion,
      tooltip: 'Get improvement tips for the current section.' },
    { img: '/static/ToolAI/layer.png', label: t("editor-editormodel-AI-Reorganize") , type:AIDataType.reorganize,
      tooltip: 'Reorganize the current section.' },
  ]
  const { currentCursor, setCurrentLatex, setCurrentCursor, setCurrentFoot } = useEditorStore()
  const [openSelectTable, setOpenSelectTable] = useState(false)
  const [tablePoi, setTablePoi] = useState<{
    x: number
    y: number
  }>({ x: 0, y: 0 })

  /**
   * 解决u em strong互相嵌套的问题
   * @param btn
   */
  function applyFormattingToSelection(btn: ToolType) {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    if (range.collapsed) return;
    const newElement = document.createElement(btn);
    newElement.className = 'editable-textarea';
    newElement.appendChild(range.extractContents());
    range.insertNode(newElement);
    selection.removeAllRanges();
    const newRange = document.createRange();
    newRange.selectNodeContents(newElement);
    selection.addRange(newRange);
  }

  const onClickTool = (btn: ToolType, e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    if (currentCursor.nodeIndex >= 0) {
      if (activeTools.indexOf(btn) === -1) {
        setActiveTools([...activeTools, btn])
      }
      let nodeIndex = currentCursor.nodeIndex
      const cursor = getCursorPos(nodeList)
      if (cursor) {
        nodeIndex = cursor.nodeIndex
      }
      const newNodeList = cloneDeep(nodeList)
      const node = newNodeList[nodeIndex]
      switch (btn) {
        case ToolType.Bold:
        case ToolType.Italic:
        case ToolType.Underline: {
          applyFormattingToSelection(btn)
          node.content = domToContent(node)?.content;
          setNodeList([...newNodeList]);
          setOutlineIsChange(true)
          break
        }
        case ToolType.Figure: {
          setCurrentCursor(cursor)
          openFigure()
          break
        }

        case ToolType.Equation: {
          setCurrentCursor(cursor)
          setCurrentLatex({
            id: null,
            content: '',
            label: '',
            nodeIndex,
            inlineType: 'inline'
          })
          openLatex()
          break
        }
        case ToolType.Table: {
          setCurrentCursor(cursor)
          setTablePoi({
            x: e.clientX,
            y: e.clientY
          })
          setOpenSelectTable(true)
          break
        }
        case ToolType.Foot: {
          setCurrentCursor(cursor)
          setCurrentFoot({
            id: null,
            content: '',
            nodeIndex,
          })
          openFoot()
          break
        }
      }
    }
  }

  return (
    <Center>
      <HStack
        bg={'#FFFFFF'}
        border={"1px solid #ccc"}
        width={pxToRem(796)}
        height={pxToRem(48)}
        borderRadius={"10px"}
        gap={0}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Flex
          borderRight={"1px solid #D3D7E2"}
          width={pxToRem(297)}
          height={pxToRem(48)}
          pl={pxToRem(15)}
          gap={pxToRem(16)}
          justifyContent={'start'}
          alignItems={'center'}
        >
          {basicTools.map((tool) => {
            return (
              <IconButton
                key={tool.type}
                // disabled={!currentCursor.nodeIndex === -1}
                size={"xs"}
                bgColor={"transparent"}
                _hover={{ bgcolor: "transparent" }}
                data-no-focus={'true'}
                icon={
                  <Image
                    w={pxToRem(26)}
                    h={pxToRem(26)}
                    transform={tool.type === 'footnote' ? `scale(0.65)` : 'scale(1)'}
                    objectFit="cover"
                    src={tool.img}
                    alt="Doenba"
                  />
                }
                aria-label={tool.label}
                onClick={(e) => onClickTool(tool.type, e)}
              />
            )
          })}
        </Flex>
        <Flex width={pxToRem(499)} flex={1} alignItems={'center'} justifyContent={'start'} pl={pxToRem(20)} gap={pxToRem(16)}>
          {AIBtns.map((btn) => (
            <Tooltip hasArrow placement={'bottom'}
                     borderRadius={pxToRem(6)}
                     key={btn.label}
                     padding={4} bg={'#FFF'} color={'#333'} label={
              <Text fontSize={'1rem'} fontFamily={'Lato'}>{btn.tooltip}</Text>
            } aria-label='import'>
              <Button
                width={btn.type === AIDataType.rephrase ? pxToRem(131) :
                  (btn.type === AIDataType.suggestion ? pxToRem(155) : pxToRem(145))}
                height={pxToRem(36)}
                value={btn.label}
                bgColor={btn.type === aiType ? "#C9D9E8":"transparent" }
                fontWeight="bold"
                textColor={"#333333"}
                _hover={{  bg: "transparent" }}
                onClick={()=>{
                  if (btn.type === aiType) {
                    updateAiType(null)
                  } else {
                    updateAiType(btn.type)
                  }
                }}
                leftIcon={
                  <Image
                    w={pxToRem(24)}
                    objectFit="cover"
                    src={btn.img}
                    alt="Doenba"
                  />
                }
              >
                {btn.label}
              </Button>
            </Tooltip>
          ))}
        </Flex>
        <InsertTable isOpen={openSelectTable}
                     openTable={openTable} isOpenTable={isOpenTable} closeTable={closeTable}
                     setIsOpen={setOpenSelectTable} tablePoi={tablePoi} />
      </HStack>
    </Center>
  );
};
export default ToolAIBox;
