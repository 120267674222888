import React, { useRef, useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useDisclosure
} from "@chakra-ui/react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-latex";
import "ace-builds/src-noconflict/theme-textmate";
import "katex/dist/katex.min.css";
import { BlockMath } from "react-katex";
import {
  handleInlineCursor,
  insertContentToDom,
  pxToRem,
} from "../../../utils/editor.ts";
import useEditorStore from "../../../stores/modules/editor.ts";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { useEditorHook } from "../../../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";

interface InsertLatexProps {
  isOpenModal: boolean
  closeModal: () =>  void
}

export const InsertLatex: React.FC<InsertLatexProps> = ({ isOpenModal, closeModal }) => {
  const { currentCursor, currentLatex, setCurrentLatex } = useEditorStore()
  const { nodeList, setNodeList } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const { onClose } = useDisclosure()
  const editorRef = useRef(null);
  const [quickSymbol, setQuickSymbol] = useState([])

  useEffect(() => {
    setQuickSymbol([
      {
        key: 'symbol1',
        symbols: [
          { symbol: "alpha", latex: "\\alpha" }, { symbol: "beta", latex: "\\beta" }, { symbol: "gamma", latex: "\\gamma" },
          { symbol: "delta", latex: "\\delta" }, { symbol: "epsilon", latex: "\\epsilon" }, { symbol: "zeta", latex: "\\zeta" },
          { symbol: "eta", latex: "\\eta" }, { symbol: "theta", latex: "\\theta" }, { symbol: "iota", latex: "\\iota" },
          { symbol: "kappa", latex: "\\kappa" }, { symbol: "lambda", latex: "\\lambda" }, { symbol: "mu", latex: "\\mu" },
          { symbol: "nu", latex: "\\nu" }, { symbol: "xi", latex: "\\xi" }, { symbol: "pi", latex: "\\pi" },
          { symbol: "rho", latex: "\\rho" }, { symbol: "sigma", latex: "\\sigma" }, { symbol: "tau", latex: "\\tau" },
          { symbol: "upsilon", latex: "\\upsilon" }, { symbol: "phi", latex: "\\phi" }, { symbol: "chi", latex: "\\chi" },
          { symbol: "psi", latex: "\\psi" }, { symbol: "omega", latex: "\\omega" }, { symbol: "Gamma1", latex: "\\Gamma" },
          { symbol: "Delta2", latex: "\\Delta" }, { symbol: "Theta2", latex: "\\Theta" }, { symbol: "Lambda2", latex: "\\Lambda" },
          { symbol: "Xi2", latex: "\\Xi" }, { symbol: "Pi2", latex: "\\Pi" }, { symbol: "Sigma2", latex: "\\Sigma" },
          { symbol: "Upsilon2", latex: "\\Upsilon" }, { symbol: "Phi2", latex: "\\Phi" }, { symbol: "Psi2", latex: "\\Psi" },
          { symbol: "Omega2", latex: "\\Omega" }
        ]
      },
      {
        key: 'symbol2',
        symbols: [
          { symbol: "times", latex: "\\times" },
          { symbol: "div", latex: "\\div" },
          { symbol: "cdot", latex: "\\cdot" },
          { symbol: "pm", latex: "\\pm" },
          { symbol: "mp", latex: "\\mp" },
          { symbol: "ast", latex: "\\ast" },
          { symbol: "star", latex: "\\star" },
          { symbol: "circ", latex: "\\circ" },
          { symbol: "bullet", latex: "\\bullet" },
          { symbol: "oplus", latex: "\\oplus" },
          { symbol: "ominus", latex: "\\ominus" },
          { symbol: "oslash", latex: "\\oslash" },
          { symbol: "otimes", latex: "\\otimes" },
          { symbol: "odot", latex: "\\odot" },
          { symbol: "dagger", latex: "\\dagger" },
          { symbol: "ddagger", latex: "\\ddagger" },
          { symbol: "vee", latex: "\\vee" },
          { symbol: "wedge", latex: "\\wedge" },
          { symbol: "cap", latex: "\\cap" },
          { symbol: "cup", latex: "\\cup" },
          { symbol: "aleph", latex: "\\aleph" },
          { symbol: "Re", latex: "\\Re" },
          { symbol: "Im", latex: "\\Im" },
          { symbol: "top", latex: "\\top" },
          { symbol: "bot", latex: "\\bot" },
          { symbol: "infty", latex: "\\infty" },
          { symbol: "partial", latex: "\\partial" },
          { symbol: "forall", latex: "\\forall" },
          { symbol: "exists", latex: "\\exists" },
          { symbol: "neg", latex: "\\neg" },
          { symbol: "angle", latex: "\\angle" },
          { symbol: "triangle", latex: "\\triangle" },
          { symbol: "diamond", latex: "\\diamond" }
        ]
      },
      {
        key: 'symbol3',
        symbols: [
          { symbol: "leq", latex: "\\leq" },
          { symbol: "geq", latex: "\\geq" },
          { symbol: "prec", latex: "\\prec" },
          { symbol: "succ", latex: "\\succ" },
          { symbol: "preceq", latex: "\\preceq" },
          { symbol: "succeq", latex: "\\succeq" },
          { symbol: "ll", latex: "\\ll" },
          { symbol: "gg", latex: "\\gg" },
          { symbol: "equiv", latex: "\\equiv" },
          { symbol: "sim", latex: "\\sim" },
          { symbol: "simeq", latex: "\\simeq" },
          { symbol: "asymp", latex: "\\asymp" },
          { symbol: "approx", latex: "\\approx" },
          { symbol: "ne", latex: "\\ne" },
          { symbol: "subset", latex: "\\subset" },
          { symbol: "supset", latex: "\\supset" },
          { symbol: "subseteq", latex: "\\subseteq" },
          { symbol: "supseteq", latex: "\\supseteq" },
          { symbol: "in", latex: "\\in" },
          { symbol: "ni", latex: "\\ni" },
          { symbol: "notin", latex: "\\notin" }
        ]
      },
      {
        key: 'symbol4',
        symbols: [
          { symbol: "x_a", latex: "x_{a}" },
          { symbol: "x^b", latex: "x^{b}" },
          { symbol: "x_a^b", latex: "x_{a}^{b}" },
          { symbol: "bar_x", latex: "\\bar{x}" },
          { symbol: "tilde_x", latex: "\\tilde{x}" },
          { symbol: "frac_a_b", latex: "\\frac{a}{b}" },
          { symbol: "sqrt_x", latex: "\\sqrt{x}" },
          { symbol: "sqrt_n_x", latex: "\\sqrt[n]{x}" },
          { symbol: "bigcap_a_b", latex: "\\bigcap_{a}^{b}" },
          { symbol: "bigcup_a_b", latex: "\\bigcup_{a}^{b}" },
          { symbol: "prod_a_b", latex: "\\prod_{a}^{b}" },
          { symbol: "coprod_a_b", latex: "\\coprod_{a}^{b}" },
          { symbol: "svg13", latex: "\\left( x \\right)" },
          { symbol: "svg14", latex: "\\left[ x \\right]" },
          { symbol: "svg15", latex: "\\left\\{ x \\right\\}" },
          { symbol: "svg16", latex: "\\left| x \\right|" },
          { symbol: "int_a_b", latex: "\\int_{a}^{b}" },
          { symbol: "oInta_b", latex: "\\oint_{a}^{b}" },
          { symbol: "sub_a_b", latex: "\\sum_{a}^{b}{x}" },
          { symbol: "lim_a_right_bx", latex: "\\lim_{a \\rightarrow b}{x}" }
        ]
      },
      {
        key: 'symbol5',
        symbols: [
          { symbol: "svg1", latex: "\\leftarrow" },
          { symbol: "svg2", latex: "\\rightarrow" },
          { symbol: "svg3", latex: "\\leftrightarrow" },
          { symbol: "svg4", latex: "\\Leftarrow" },
          { symbol: "svg5", latex: "\\Rightarrow" },
          { symbol: "svg6", latex: "\\Leftrightarrow" },
          { symbol: "svg7", latex: "\\uparrow" },
          { symbol: "svg8", latex: "\\downarrow" },
          { symbol: "svg9", latex: "\\updownarrow" },
          { symbol: "svg10", latex: "\\Uparrow" },
          { symbol: "svg11", latex: "\\Downarrow" },
          { symbol: "svg12", latex: "\\Updownarrow" }
        ]
      },
    ])
  }, [])
  const insertSymbol = (latex: string) => {
    onClose()
    editorRef.current?.editor.setValue(currentLatex.content + latex)
    editorRef.current?.editor?.focus() // gotoLine
  };

  /**
   * add equation to editor
   */
  const confirmAddEquation = () => {
    closeModal()
    if (currentLatex.id) { // 编辑
      const newNodeList = cloneDeep(nodeList)
      if (currentLatex.inlineType === 'inline') {
        const node = newNodeList[currentLatex.nodeIndex]
        node.equation[currentLatex.id] = {
          value: currentLatex.content
        }
      } else {
        newNodeList[currentLatex.nodeIndex].content = currentLatex.content
      }
      setNodeList([...newNodeList])
    } else { // 新增
      const newNodeList = cloneDeep(nodeList)
      const node = newNodeList[currentCursor.nodeIndex]
      if (currentLatex.inlineType === 'inline') {
        if (!node.equation) {
          node.equation = {}
        }
        const id = Object.keys(node.equation)?.length + 1
        node.equation[id] = {
          value: currentLatex.content
        }
        const slotContent = `($equation{${id}}$)`
        if (node.type === OutlineNodeType.Equation || node.type === OutlineNodeType.Figure
        || node.type === OutlineNodeType.Table) { // 碰到块级的直接下一行
          addNewNode(node, OutlineNodeType.Paragraph, 2, slotContent)
        } else {
          const key = node.type === OutlineNodeType.Paragraph ? 'content' : 'name'
          node[key] = insertContentToDom(node, currentCursor, slotContent).content
          setNodeList([...newNodeList])
        }
        setTimeout(() => {
          handleInlineCursor(nodeList[currentCursor.nodeIndex], slotContent)
        }, 300)
      } else {
        addNewNode(nodeList[currentCursor.nodeIndex], OutlineNodeType.Equation, 2, currentLatex)
      }
    }
    setCurrentLatex(null)
  }

  const closeModalHandler = () => {
    closeModal()
    setCurrentLatex(null)
  }

  return (
    <Modal isOpen={isOpenModal} onClose={closeModalHandler}
           closeOnOverlayClick={false}>
      <ModalOverlay bg={'whiteAlpha.500'}/>
      <ModalContent
        position={'fixed'}
        minW={pxToRem(600)} minHeight={"220px"}
        bg={'#FBFCFF'}
        boxShadow={'none'}
        top={150}
        border={'2px solid #D3D7E2'}
        borderRadius={pxToRem(12)}>
        <ModalCloseButton
          _focusVisible={{ boxShadow: 'none' }}
          bg={'#D3D7E2'}
          width={pxToRem(24)}
          height={pxToRem(24)}
          color={'#8E9ABC'}
          top={pxToRem(20)}
          right={pxToRem(20)}
          fontSize={'8px'}
          borderRadius={'50%'} />
        <ModalBody
          display={'flex'} p={2}
          bg={'#FBFCFF'}
          borderRadius={pxToRem(12)}>
          { currentLatex && <Box px={2} pb={0} pt={6} w={pxToRem(600)}>
            <Flex className={'latex-toolbar'} mb={2} gap={4}>
              {/* symbol quick btns */}
              {quickSymbol.map((quickItem) => (<Popover
                isOpen={quickItem.isOpen}
                onOpen={quickItem.onOpen}
                key={quickItem.key}
                onClose={quickItem.onClose}>
                <PopoverTrigger>
                  <IconButton aria-label={''} bg={'transparent'} border={'1px solid #D3D7E2'} size="sm"
                    icon={
                      <Image h={'20px'} src={`/static/latex/${quickItem.key}.png`} />
                    }
                  ></IconButton>
                </PopoverTrigger>
                <PopoverContent w={pxToRem(400)}>
                  <PopoverArrow />
                  <SimpleGrid columns={10} p={2} spacing={1}>
                    {quickItem.symbols.map(({ symbol, latex }) => (
                      <IconButton
                        type={'button'}
                        aria-label={''}
                        bg={'transparent'}
                        key={symbol}
                        icon={
                          <Image src={`/static/latex/${quickItem.key}/${symbol}.svg`} />
                        }
                        onClick={() => insertSymbol(latex)}>
                      </IconButton>
                    ))}
                  </SimpleGrid>
                </PopoverContent>
              </Popover>))}
            </Flex>
            <Box  width={'100%'}
                  height={pxToRem(150)}
                  p={2}
                  border="1px solid #D3D7E2"
            >
              {/* ace math editor */}
              <AceEditor
                ref={editorRef}
                mode="latex"
                theme="textmate"
                value={currentLatex.content}
                onChange={(value) => {
                  setCurrentLatex({
                    ...currentLatex,
                    content: value
                  })
                }}
                fontSize={16}
                width={'100%'}
                height={'100%'}
                showGutter={false}
                focus={true}
                setOptions={{ showLineNumbers: false }}
              />
            </Box>
            {currentLatex.inlineType === 'block' && <Flex mt={4} alignItems={'center'} gap={2}>
              Label:
              <Input
                  defaultValue={currentLatex.label}
                  onChange={(e) => {
                    setCurrentLatex({
                      ...currentLatex,
                      label: e.target.value,
                    })
                  }}
              ></Input>
            </Flex>}
            {/* LaTeX preview */}
            <Box mt={1} minH={'60px'} bg="gray.50" display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{
              'div[data-testid="react-katex"]': {
                width: '100%'
              }
            }}>
              <BlockMath style={'width: 100%'}>{currentLatex.content}</BlockMath>
            </Box>
            <ModalFooter p={2}>
              <Button  w={pxToRem(120)} mr={3} onClick={closeModalHandler}>Close</Button>
              <Button
                w={pxToRem(120)}
                bg={'#1D8AFF'}
                _hover={{ background: '#1D8AFF' }}
                border={'1px solid #1D8AFF'}
                borderRadius={pxToRem(12)}
                color={'#FFFFFF'}
                onClick={confirmAddEquation}
              >
                Confirm
              </Button>
            </ModalFooter>
          </Box>}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

