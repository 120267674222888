import { InlineNode, OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { domToContent, getNodePDom, sameNameNodes, setCursorPosition } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { cloneDeep, find, map, merge } from "lodash";
import useEditorStore, { CursorInfo } from "../../stores/modules/editor.ts";

/**
 * editor add/del/edit outline handler
 */
export const useEditorHook = () => {
  const outlineStore = useOutlineStore()
  const editorStore = useEditorStore()

  /**
   * add new node
   * @param node
   * @param type section | paragraph
   * @param level
   * @param content newNode text
   * @param nextObj
   * @param caption
   */
  const addNewNode = (
    node: PaperOutlineNode,
    type: OutlineNodeType,
    level: number,
    content?: string | any,
    nextObj?: {
      [key: string]: InlineNode
    },
    caption?: string
  ) => {
    let newNode: PaperOutlineNode = {
      id: outlineStore.nextId,
      name: '',
      type: type,
      level,
    };
    switch (type) {
      case OutlineNodeType.Section: {
        if (level === node.level + 1) {
          newNode.parentId = node.id;
        }
        if (level === node.level) {
          newNode.parentId = node.parentId;
        }
        if (level === node.level - 1) {
          const parent = find(outlineStore.nodeList, { id:node.parentId })
          newNode.parentId = parent.parentId;
        }
        newNode.children = []
        break
      }
      case OutlineNodeType.Paragraph: {
        newNode.parentId = node.type === OutlineNodeType.Section ? node.id : node.parentId
        newNode.content = content || ''
        newNode = merge(newNode, nextObj)
        delete newNode.name
        break
      }
      case OutlineNodeType.Figure: {
        newNode.parentId = node.parentId
        delete newNode.name
        newNode.content = content
        newNode.label = 'Figure' + outlineStore.figureNextId
        newNode.caption = caption
        outlineStore.setFigureNextId(outlineStore.figureNextId + 1)
        break
      }
      case OutlineNodeType.Table: {
        newNode.parentId = node.parentId
        delete newNode.name
        newNode.content = content
        newNode.caption = caption
        newNode.label = 'Table' + outlineStore.tableNextId
        outlineStore.setTableNextId(outlineStore.tableNextId + 1)
        break
      }

      case OutlineNodeType.Equation: {
        newNode.parentId = node.parentId
        newNode.content = content.content || ''
        newNode.label = content.label || 'Equation' + outlineStore.equationNextId
        outlineStore.setEquationId(outlineStore.equationNextId + 1)
        delete newNode.name
        break
      }
    }
    if (node.children == undefined) node.children = []
    outlineStore.setOutlineIsChange(true)
    const nodeList = cloneDeep(outlineStore.nodeList)
    const index = nodeList.findIndex(item => item.id === node.id)
    if (node.type === OutlineNodeType.Section) {
      nodeList[index].name = node.name
    } else if (node.type === OutlineNodeType.Paragraph){
      nodeList[index].content = node.content
    }
    let subIndex = index
    if (node.type === OutlineNodeType.Section) {
      if (index !== 0) {
        for (let i = index + 1; i <= nodeList.length; i++) {
          if (i === nodeList.length) { // 当前行有子subsection，是最后一个subsection
            subIndex = i
            break
          } else {
            if (nodeList[i].level <= nodeList[index].level) {
              subIndex = i
              break
            }
          }
        }
      }
    }
    const newIndex = subIndex === index ? (index + 1) : subIndex
    nodeList.splice(newIndex, 0, newNode)
    outlineStore.setNextId(newNode.id + 1)
    editorStore.setCurrentCursor({
      nodeIndex: newIndex,
      start: 0,
    })
    editorStore.setAddMode(content ? 'content' : 'blank')
    outlineStore.setNodeList([...nodeList])

    if (type === OutlineNodeType.Equation || type === OutlineNodeType.Table || type === OutlineNodeType.Figure) {
      outlineStore.setAddSpecial(true)
    }
  }
  const extractText = (htmlString: string)=> {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    const span = tempDiv.querySelector("span[data-content]");
    const dataContent = span ? span.getAttribute("data-content") : "";

    span?.remove();
    const textContent = tempDiv.textContent;

    return textContent.trim().replace(/\s+/g, ' ') + (dataContent ? ` ${dataContent} ` : '') ;
  }

  /**
   * 全量从DOM中读取文本并更新 outline 的内容
   * @param nodes 大纲节点数组
   */
  const syncDOMToNodes = (nodes: PaperOutlineNode[])=> {
    nodes.forEach((node) => {
      const nodeInfo = domToContent(node)
      if (node.type === OutlineNodeType.Section) {
        if (node.name !== nodeInfo.content) {
          node.name = nodeInfo.content;
          node.label = node.name
          const sameLen = sameNameNodes(node.name, outlineStore.nodeList)
          if (sameLen) {
            node.label += `_${sameLen}`
          }
        }
      } else if (node.type === OutlineNodeType.Paragraph) {
        node.content = nodeInfo.content;
      }
      const keys = Object.keys(nodeInfo.newSlot)
      if (keys?.length) {
        keys.forEach(key => {
          node[key] = nodeInfo.newSlot[key]
        })
      }
    });
    return nodes
  }

  function isJSONStringified(str) {
    if (typeof str !== "string" || str.length === 0) {
      return false;
    }
    try {
      const parsed = JSON.parse(str);
      if (typeof parsed === "object" && parsed !== null) {
        return true;
      }
      return Array.isArray(parsed);
    } catch (e) {
      return false;
    }
  }

  /**
   * paste handler
   */
  const pasteHandler = async (cursor: CursorInfo, nextId: number, nodeList: PaperOutlineNode[]) => {
    try {
      const { nodeIndex, start } = cursor
      const text = await navigator.clipboard.readText()
      let contents = text.split(/\n+/) // 从editor复制过来的是\r\n, 从其他地方复制过来的是\n
      const isEditorCopy = isJSONStringified(text)
      let editorContents = []
      if (isEditorCopy) {
        editorContents = JSON.parse(text)
        contents = map(editorContents, 'select_content')
      } else if (text.indexOf('\r\n') > -1) {
        contents = text.split(/\r\n+/).filter((item => item.trim() !== ""))
      }
      const nodes = []
      const newNodeList = cloneDeep(nodeList)
      contents.forEach((content, i) => {
        if (i > 0) {
          const childItem: PaperOutlineNode = {
            id: nextId + i,
            content,
            type: editorContents[i]?.type || OutlineNodeType.Paragraph,
            level: newNodeList[nodeIndex].level === 1 ? 2 : newNodeList[nodeIndex].level,
            parentId: newNodeList[nodeIndex].parentId,
            children: []
          }
          if (editorContents[i]?.refer) {
            childItem.refer = editorContents[i].refer
          }
          if (editorContents[i]?.equation) {
            childItem.equation = editorContents[i].equation
          }
          if (editorContents[i]?.cite) {
            childItem.cite = editorContents[i].cite
          }
          nodes.push(childItem)
        } else {
          const node = newNodeList[nodeIndex]
          if (isEditorCopy) {
            if (node?.refer) {
              node.refer = merge(node.refer, editorContents[i].refer)
            }
            if (node?.equation) {
              node.equation = merge(node.equation, editorContents[i].equation)
            }
            if (node?.cite) {
              node.cite = merge(node.cite, editorContents[i].cite)
            }
          }

          if (node.type === OutlineNodeType.Paragraph) {
            node.content += content
          } else if (node.type === OutlineNodeType.Section) {
            node.name += content
          }
        }
      })
      if (nodes?.length) {
        newNodeList.splice(nodeIndex + 1, 0, ...nodes)
        const lastNode = nodes[nodes.length - 1]
        outlineStore.setNextId(lastNode.id + 1)
        let start2 = 0
        if (lastNode.type === OutlineNodeType.Paragraph) {
          start2 = lastNode.content?.length || 0
        }
        if (lastNode.type === OutlineNodeType.Section) {
          start2 = lastNode.name?.length || 0
        }
        editorStore.setCurrentCursor({
          nodeIndex: nodeIndex + nodes.length,
          start: start2,
        })
        editorStore.setAddMode(start2 ? 'content' : 'blank')
        outlineStore.setNextId
        outlineStore.setNodeList([...newNodeList])
      } else {
        // 走浏览器默认事件
        editorStore.setCurrentCursor({
          nodeIndex: nodeIndex,
          start: start + contents[0]?.length,
        })
        outlineStore.setNodeList([...newNodeList])
        setTimeout(() => {
          const dom = getNodePDom(nodeList[nodeIndex])
          setCursorPosition(dom.firstChild as HTMLElement, start + (contents[0]?.length || 0))
        }, 200)
      }
      outlineStore.setOutlineIsChange(true)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * replace node content
   * @param node current edit node
   * @param selectContent
   * @param newContent
   */
  const replaceNodeContent = (node: PaperOutlineNode, selectContent: string, newContent: string) => {
    switch (node.type) {
      case OutlineNodeType.Section: {
        node.name = node.name.replace(selectContent, newContent)
        break
      }
      case OutlineNodeType.Paragraph: {
        node.content = node.content.replace(selectContent, newContent)
        break
      }
    }
    // setNodeList([...nodeList])
    window.getSelection()?.removeAllRanges()
  }


  /**
   * update nodeList history
   */
  const updateListHistory = (newNodeList: PaperOutlineNode[]) =>{
    outlineStore.setNodeList([...newNodeList])
  }

  return {
    addNewNode,
    syncDOMToNodes,
    pasteHandler,
    replaceNodeContent,
    extractText,
    updateListHistory
  }
}