import { IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuText } from "react-icons/lu";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { getNodePDom, pxToRem } from "../../utils/editor.ts";
import { useTranslation } from "react-i18next";
import React from "react";
import useOutlineStore from "../../stores/modules/outline.ts";
import { cloneDeep } from "lodash";
import useEditorStore from "../../stores/modules/editor.ts";


interface ChangeTypeListProps {
  index: number
  node: PaperOutlineNode
  updateOutline: (updated: boolean) => void
}

export const ChangeTypeList: React.FC<ChangeTypeListProps> = ({ index, node }) => {
  const { t } = useTranslation()
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()
  const { setCurrentCursor } = useEditorStore()

  /**
   * click change selections
   * @param btnClick
   */
  const getSectionButton = (
    btnClick: (level: number) => void,
  ) => {
    const level = node.level
    if (level > 5) return undefined;
    const levelIcons = {
      2: <LuHeading2/>,
      3: <LuHeading3/>,
      4: <LuHeading4/>,
      5: <LuHeading5/>,
    }
    const menuItems = []
    for (let i = 2; i <= level; i++) {
      const icon = levelIcons[i]
      menuItems.push(<MenuItem
        onClick={() => btnClick(i)}
        _hover={{ bgColor: "#3898FF61" }}
        borderRadius={10}
        key={i}
      >
        {icon}
        <Text pl={"3px"} fontSize={14}>
          {t("editor-editormodel-midNewSubsection" + i)}
        </Text>
      </MenuItem>)
    }
    return menuItems
  }
  return (
    <Menu matchWidth={true}>
      <MenuButton
        as={IconButton}
        size={"xs"}
        borderRadius={'50%'}
        bgColor={"transparent"}
        _hover={{ bgColor: "gray.300" }}
        _focusVisible={{ boxShadow: 'none' }}
        onClick={() => {
          setCurrentCursor({
            nodeIndex: index,
            start: 0,
            target: null
          })
        }}
        icon={
          <PiDotsSixVerticalBold
            style={{ width: pxToRem(20), height: pxToRem(20) }}
          />
        }
      />
      { node.level !== 1 && <MenuList
        border={"1px solid #cccccc"}
        minWidth={109}
      >
        <MenuItem
          _hover={{ bgColor: "#3898FF61" }}
          borderRadius={10}
          key={OutlineNodeType.Paragraph}
          onClick={() => {
            if (node.type === OutlineNodeType.Section) {
              const index = nodeList.findIndex((child) => child.id === node.id)
              node.type = OutlineNodeType.Paragraph
              const pDom = getNodePDom(node)
              node.content = pDom.textContent || node.name
              delete node.name
              for (let j = nodeList.length - 1; j > 0; j--) {
                if(nodeList[j].level < node.level && j < index) {
                  node.parentId = nodeList[j].id
                }
                // change children parentId
                if (nodeList[j].parentId === node.id) {
                  nodeList[j].parentId = node.parentId
                  nodeList[j].level = node.level
                }
              }
              const newNodeList = cloneDeep(nodeList)
              newNodeList[index] = node
              setNodeList([...newNodeList])
              setOutlineIsChange(true)
            }
          }}
        >
          <LuText/>
          <Text pl={"3px"} fontSize={14}>
            {t("editor-editormodel-Text")}
          </Text>
        </MenuItem>
        {getSectionButton((level: number) => {
          // change section type
          const index = nodeList.findIndex((child) => child.id === node.id)
          for (let j = index; j > 0; j--) {
            if(nodeList[j].level < level) {
              node.parentId = nodeList[j].id
            }
          }
          node.level = level
          if (node.type === OutlineNodeType.Paragraph) {
            node.type = OutlineNodeType.Section
            const pDom = getNodePDom(node)
            node.name = pDom.textContent || node.content
            delete node.content
          }
          const newNodeList = cloneDeep(nodeList)
          newNodeList[index] = node
          setNodeList([...newNodeList])
          setOutlineIsChange(true)
        })}
      </MenuList>}
    </Menu>
  )
}