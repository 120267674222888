import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text, useDisclosure,
  VStack,
  Link as ChakraLink, Tooltip,
} from "@chakra-ui/react";
import NodeTree from "../OutlineNodeTree/NodeTree.tsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { PaperOutline } from "../../types/Writer/OutlineNode.ts";
import { DoenbaStructure } from "./Structure/DoenbaStructure.tsx";
import useEditorStore from "../../stores/modules/editor.ts";

/**
 * left outline overview
 */
interface LeftOutlineProps {
  popOpen: boolean
  setPopOpen: (popOpen: boolean) => void
  setPaperId: (paperId: string) => void
  leftBarWidth: string
  updateOutlineStructure: (outline: PaperOutline) => void
  setIsExport: (isExport: boolean) => void
}
export const LeftOutline: React.FC<LeftOutlineProps> = (
  {
    popOpen,
    setPopOpen,
    setPaperId,
    setIsExport,
    leftBarWidth,
    updateOutlineStructure
  }
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { outline, setOutline, setNodeList, setNextId, setOutlineIsChange, setCurrentSectionIndex, setToolPopOpen } = useOutlineStore();
  const [referencePanelOpenStatus, setReferencePanelOpenStatus] = useState<boolean>(false);
  const { isOpen: isStructureOpen, onOpen: openStructure, onClose: closeStructure } = useDisclosure();
  const [structureType, setStructureType] = useState<'import' | 'refer' | 'doenba'>('import')
  const { setFocusEditor, setCurrentCursor } = useEditorStore();
  const [writingArea, setWritingArea] = useState<string>('');
  const [subject] = useState([
    {
      label: 'Biology',
      value: 'Biology'
    },
    {
      label: 'Chemistry',
      value: 'Chemistry'
    },
    {
      label: 'Computer Science',
      value: 'Computer Science'
    },
    {
      label: 'Mathematics',
      value: 'Mathematics'
    },
    {
      label: 'Physics',
      value: 'Physics'
    }
  ])

  return (
    <React.Fragment>
      <Flex
        h={"100%"}
        flexDir={"column"}
        w={leftBarWidth}
        justifyContent={"space-between"}
        boxShadow={"md"}
        position={"relative"}
        transition={"all 0.5s ease"}
        bgColor={"#FBFCFF"}
        overflow={'hidden'}
        borderRight={'2px solid #D3D7E2'}
        onClick={() => {
          setToolPopOpen(false)
          setPopOpen(false)
        }}
      >
        <Flex flexDir={"column"}>
          <Center
            p={10}
            onClick={() => {
              setOutline(null)
              setNodeList([])
              setNextId(0)
              setToolPopOpen(false)
              setCurrentSectionIndex(0)
              setOutlineIsChange(false)
              setCurrentCursor({
                nodeIndex: 0,
                start: 0,
                target: null
              })
              setPaperId('')
              navigate("/editor")
            }}
            cursor={"pointer"}
          >
            <Image
              w={pxToRem(144)}
              h={pxToRem(40)}
              objectFit="contain"
              src={"/static/doenba-logo-blue.png"}
            />
          </Center>
          {/** refer article */}
          <Flex
            flexDir={"column"}
            px={2}
            py={2}
            alignItems={'center'}
          >
            <Tooltip hasArrow placement={'right'}
                     borderRadius={pxToRem(6)}
                     padding={4} bg={'#FFF'} color={'#333'} label={
              <Text fontSize={'1rem'} fontFamily={'Lato'}>Upload a (.tex, .txt, or docx) file to edit, or start from scratch.</Text>
            } aria-label='import'>
              <Button
                color={'#FFF'}
                bg={'#333'}
                width={pxToRem(140)}
                borderRadius={pxToRem(6)}
                _hover={{ bg: '#333' }}
                onClick={() => {
                  setStructureType('import')
                  setFocusEditor(false)
                  openStructure()
                }}
                leftIcon={
                  <Image
                    borderRadius={pxToRem(8)}
                    padding={'2px'}
                    mt={1}
                    w={pxToRem(20)} src={'/static/editor/upload.svg'} />
                }>{t('import')}</Button>
            </Tooltip>
            <Button
              w={"full"}
              mt={2}
              bgColor={"transparent"}
              _hover={{ color: "#ADD2FC", bg: 'transparent' }}
              leftIcon={
                <Image
                  w={pxToRem(20)}
                  objectFit="contain"
                  src="/static/editor/structure.png"
                />
              }
              // rightIcon={
              //   <Image
              //     w={pxToRem(20)}
              //     objectFit="contain"
              //     src="/static/arrow-down.png"
              //   />
              // }
              onClick={() =>
                setReferencePanelOpenStatus(!referencePanelOpenStatus)
              }
            >
              <Tooltip hasArrow placement={'right'}
                       borderRadius={pxToRem(6)}
                       padding={4} bg={'#FFF'} color={'#333'} label={
                <Text fontSize={'1rem'} fontFamily={'Lato'}>{t('outline-tooltip')}</Text>
              } aria-label='Outline'>
                <Text m={1} color={"#333"} fontSize={pxToRem(20)}>
                  { t('outline-text') }
                </Text>
              </Tooltip>
            </Button>
            {referencePanelOpenStatus && (
              <Box
                w={'full'}
                transition={"all 0.5s ease"}
              >
                <Flex flexDir={"column"} justifyContent={"space-between"}>
                <Button
                  w={"full"}
                  mt={2}
                  border={'1px solid #3898FF44'}
                  bgColor={"transparent"}
                  cursor={'pointer'}
                  _hover={{ color: "#ADD2FC", bg: '#3898FF33' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    setStructureType('doenba')
                    setPopOpen(!popOpen)
                  }}
                >
                  <Tooltip hasArrow placement={'right'}
                           borderRadius={pxToRem(6)}
                           padding={4} bg={'#FFF'} color={'#333'} label={
                    <Text fontSize={'1rem'} fontFamily={'Lato'}>Select an appropriate subject.</Text>
                  } aria-label='Default'>
                    <Text m={1} color={"#333"} fontSize={pxToRem(18)}>
                      {t('default')}
                    </Text>
                  </Tooltip>
                  </Button>
                  <Button
                    w={"full"}
                    mt={2}
                    border={'1px solid #3898FF44'}
                    bgColor={"transparent"}
                    cursor={'pointer'}
                    _hover={{ color: "#ADD2FC", bg: '#3898FF33' }}
                    onClick={() =>{
                      setStructureType('refer')
                      setFocusEditor(false)
                      openStructure()
                    }}
                  >
                    <Tooltip hasArrow placement={'right'}
                             borderRadius={pxToRem(6)}
                             padding={4} bg={'#FFF'} color={'#333'} label={
                      <Text fontSize={'1rem'} fontFamily={'Lato'}>Upload a PDF article for Doneba to extract its outline automatically.</Text>
                    } aria-label='Extract'>
                      <Text m={1} color={"#333"} fontSize={pxToRem(18)}>
                        {t('extract')}
                      </Text></Tooltip>
                  </Button>
                </Flex>
              </Box>
            )}
          </Flex>
          <Box
            mt={5}
            h={`calc(100vh - ${
              referencePanelOpenStatus ? pxToRem(550) : pxToRem(520)
            })`}
          >
            {/** outline structure */}
            {outline && (
              <Box
                onClick={(e) => {
                  e.stopPropagation()
                  setFocusEditor(false)
                }}
                transition={'all .5s'}
                height={`calc(100vh - ${
                  referencePanelOpenStatus ? pxToRem(550) : pxToRem(520)
                })`}>
                <NodeTree setIsExport={setIsExport} updateOutlineStructure={updateOutlineStructure} />
              </Box>
            )}
          </Box>
          <VStack justifyContent={'center'} mt={10}>
            <Tooltip hasArrow placement={'right'}
                     borderRadius={pxToRem(6)}
                     padding={4} bg={'#FFF'} color={'#333'} label={
              <Text fontSize={'1rem'} fontFamily={'Lato'}>{t('export-text')}</Text>
            } aria-label='export'>
              <Button
                mt={3}
                px={0}
                w={pxToRem(192)}
                fontSize={pxToRem(18)}
                onClick={() => setIsExport(true)}
                leftIcon={
                  <Image
                    w={pxToRem(20)}
                    objectFit="cover"
                    mr={'auto'}
                    src="/static/editor/Export2.png"
                    alt="no draft"
                    position={'relative'}
                    left={pxToRem(-10)}
                  />
                }
              >
                {t("editor-editormodel-leftExport")}
              </Button>
            </Tooltip>
          </VStack>
          <Center mt={7}>
            <ChakraLink mb={5}
                        color={'#333333'}
                        fontFamily={'Lato'}
                        _hover={{ textDecoration: 'none' }}
                        fontWeight={'bold'} fontSize={pxToRem(16)}>
              {t("editor-editormodel-leftTutorial")}
            </ChakraLink>
          </Center>
        </Flex>
        {/** create outline by doenba */}
        {isStructureOpen && <DoenbaStructure isOpenModal={isStructureOpen} onModalClose={closeStructure}
                                             updateOutlineStructure={updateOutlineStructure}
                                             setPopOpen={setPopOpen}
                                             structureType={structureType} writingArea={writingArea} />}
      </Flex>
      {popOpen && <VStack zIndex={9999} w={pxToRem(200)} bg={'#FFF'} px={4} py={2} pt={5} border={'1px solid #ADD2FC'}
                          borderRadius={pxToRem(12)}
                          boxShadow={'0 0 2px #ADD2FC'}
                          opacity={popOpen ? 1 : 0}
                          transition={'all .5s'}
                          position={'fixed'} top={pxToRem(220)} left={'14.8%'}>
        {subject.map((item) => {
          return (
            <Text w={'full'} textAlign={'center'} py={1}
                  cursor={'pointer'}
                  key={item.label}
                  m={1} color={"#333"} fontSize={pxToRem(18)}
                  _hover={{ bg: '#3898FF33' }}
                  border={'1px solid #ADD2FC'} borderRadius={pxToRem(6)}
                  onClick={() => {
                    setWritingArea(item.value)
                    setFocusEditor(false)
                    openStructure()
                    setPopOpen(false)
                  }}>
              {item.label}
            </Text>
          )
        })}
      </VStack>}
    </React.Fragment>
  )
}