import { Box, Image, Text, Flex } from "@chakra-ui/react";
import { pxToRem } from "../../utils/editor.ts";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import React from "react";
import useEditorStore from "../../stores/modules/editor.ts";
import { MathJax } from 'better-react-mathjax';
import { InlineContent } from "./InlineContent.tsx";

// TODO: consider xss attack
const EditorContent: React.FC<{
  node: PaperOutlineNode
  nodeIndex: number
  openLatex: () => void
  openTable: () => void
  openFoot: () => void
}> = ({ nodeIndex, node, openLatex, openTable, openFoot }) => {
  const dataId = node.id?.toString() + '-' + node.level?.toString()
  const { setCurrentLatex, setCurrentTable } = useEditorStore()
  const commonTextProps = {
    w: "full",
    minH: pxToRem(32),
    lineHeight: "2rem",
    fontSize: "1rem",
    fontFamily: '"Times New Roman", Times, serif',
  };
  const hSize = [pxToRem(32), pxToRem(24), pxToRem(20), pxToRem(16), pxToRem(14)]
  switch (node.type) {
    case OutlineNodeType.Section: {
      const content = node.name;
      if (content.indexOf('($') > -1) {
        return (
          <InlineContent
            content={content}
            commonTextProps={commonTextProps} dataId={dataId}
            openFoot={openFoot}
            node={node} nodeIndex={nodeIndex} openLatex={openLatex} />
        )
      } else {
        return (
          <Text
            {...commonTextProps}
            data-id={dataId}
            className={'editable-textarea section-edit-type'}
            bgColor={"transparent"}
            _hover={{ bgColor: "transparent" }}
            textColor={
              node.level == 1 ? "#1D8AFF" : "#333333"
            }
            data-placeholder={`New Heading${node.level}`}
            flex={1}
            fontSize={hSize[node.level - 1]}
            fontWeight={"bold"}
            position={'relative'}
            wordBreak={"break-word"}
          >
            {node.name}
          </Text>
        )
      }
    }
    case OutlineNodeType.Paragraph: {
      const content = node.content;
      if (content.indexOf('($') > -1) {
        return (
            <InlineContent
              content={content}
              commonTextProps={commonTextProps} dataId={dataId}
              openFoot={openFoot}
              node={node} nodeIndex={nodeIndex} openLatex={openLatex} />
        )
      } else {
        return (
          <Text
            {...commonTextProps}
            className={'editable-textarea paragraph-edit-type'}
            data-id={dataId}
            wordBreak={"break-word"}
            dangerouslySetInnerHTML={{ __html: node.content }}
          >
          </Text>
        )
      }
    }
    case OutlineNodeType.Figure: {
      return (
          <Flex {...commonTextProps}
                className={'editable-textarea figure-edit-type'}
                data-id={dataId}
                data-index={nodeIndex}
                mt={2}
                cursor={'pointer'}
                data-type={'figure'}
          >
              <Box contentEditable={false} as={'span'} display={'inline-block'} width={'90%'} flexDir={'column'}>
                <Text fontFamily={'Lato'} fontSize={pxToRem(14)} textAlign={'center'}>{node.caption}</Text>
                {node.content?.indexOf('data:image/png;base64') > - 1 ?
                  <Image maxWidth={pxToRem(280)} m={'auto'} src={node.content} />
                  : <Image maxWidth={pxToRem(280)} m={'auto'} src={'data:image/png;base64,' + node.content} />}
              </Box>
              <Box width={'8%'} as={'span'}
                   cursor={'text'}
                   className={'editable-textarea'} data-type={'block-cursor'} data-index={nodeIndex}>&#xFEFF;&nbsp;</Box>
          </Flex>
      )
    }
    case OutlineNodeType.Equation: {
      return (
        <Box {...commonTextProps}
             minH={'3rem'}
             mt={2}
             className={'editable-textarea equation-edit-type'}
             data-type={'equation'}
             cursor={'pointer'}
             data-id={dataId}>
          <Box
            as={'span'}
            cursor={'pointer'}
            _hover={{ bg: '#fef2f2' }}
            width={'90%'}
            display={'inline-block'}
            textAlign={'center'}
            sx={{
              background: '#fef2f2aa',
              borderRadius: '5px',
              padding: '2px 8px',
              '.katex-display': {
                margin: 0
              },
            }}
            onClick={() => {
              setCurrentLatex({
                id: node.id,
                content: node.content,
                label: node.label,
                nodeIndex,
                inlineType: 'block'
              })
              openLatex()
            }}
            borderRadius={4}
            contentEditable={false}>
            <MathJax inline>(${node.content}$)</MathJax>
          </Box>
          <Box width={'8%'} as={'span'} className={'editable-textarea'} data-type={'block-cursor'} data-index={nodeIndex}>&#xFEFF;&nbsp;</Box>
        </Box>
      )
    }
    case OutlineNodeType.Table: {
      return (
        <Box
          {...commonTextProps}
          className={'editable-textarea table-edit-type'}
          data-id={dataId}
          mt={2}
          display={'flex'}
          cursor={'pointer'}
          data-type={'table'}
        >
          <Flex flexDir={'column'} contentEditable={false} width={'90%'}>
            <Text fontFamily={'Lato'} fontSize={pxToRem(14)} textAlign={'center'}>{node.caption}</Text>
            <Box width={'90%'}
                 mt={4}
                 sx={{
                   'table': {
                     borderCollapse: 'collapse',
                     width: '80%',
                     margin: '0 auto'
                   },
                   'td, th': {
                     'border': '1px solid #D3D7E2',
                     'padding': '8px',
                     textAlign: 'left',
                     height: '40px'
                   },
                   'table, th, td': {
                     borderColor: '#D3D7E2',
                   }
                 }}
                 onClick={() => {
                   setCurrentTable({
                     id: node.id,
                     content: node.content,
                     label: node.label,
                     nodeIndex,
                     caption: node.caption
                   })
                   openTable()
                 }}
                 dangerouslySetInnerHTML={{ __html: node.content }}></Box>
          </Flex>
          <Box width={'8%'} as={'span'}
               cursor={'text'}
               className={'editable-textarea'} data-type={'block-cursor'} data-index={nodeIndex}>&#xFEFF;&nbsp;</Box>
        </Box>

      )
    }
 }
};
export default EditorContent;
