import { Box, Button, Checkbox, Flex, Image, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { selectedCrossReferences } from '../../atoms/rootAtom';
import { CrossReference, OutlineNodeType, PaperOutline, PaperOutlineNode } from '../../types/Writer/OutlineNode';
import { getCrossReferenceNodes } from '../../utils/flatternPaperNodes';
import LatexWrapper from '../LatexWrapper';
import { handleInlineCursor, insertContentToDom, pxToRem } from "../../utils/editor.ts";
import useEditorStore from "../../stores/modules/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { cloneDeep } from "lodash";
import { TableRender } from "../PaperEditor/InserTool/TableRender.tsx";

enum REFERENCE_TYPE {
  figure = 'figure',
  table = 'table',
  equation = 'equation',
  paragraph = 'paragraph',
  section = 'section'
}

type CrossReferencePanelProps = {
  outline: PaperOutline
}

const CrossReferencePanel: React.FC<CrossReferencePanelProps> = ({ outline }) => {
  const [currentReferenceType, setCurrentReferenceType] = useState<REFERENCE_TYPE>(REFERENCE_TYPE.figure);
  const [selectedReferences, setSelectedReferences] = useRecoilState(selectedCrossReferences);
  const [crossRef, setCrossRef] = useState<CrossReference | null>(undefined);
  const { currentCursor } = useEditorStore()
  const [lastInsert, setLastInsert] = useState<string>('')
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()

  useEffect(() => {
    if (outline && outline.nodes) {
      const referData = getCrossReferenceNodes(outline.nodes)
      setCrossRef(referData)
    }
  }, [outline])

  useEffect(() => {
    if (lastInsert) {
      handleInlineCursor(nodeList[currentCursor.nodeIndex], lastInsert)
      setLastInsert('')
    }
  }, [lastInsert]);

  const toggleSelection = (reference: PaperOutlineNode) => {
    setSelectedReferences((prevSelected) => {
      const exists = prevSelected.some((ref) => ref.id === reference.id);
      if (exists) {
        return prevSelected.filter((ref) => ref.id !== reference.id);
      } else {
        return [...prevSelected, reference];
      }
    });
  }

  const crossReferencePillarOnClick = (piller: REFERENCE_TYPE) => {
    setCurrentReferenceType(REFERENCE_TYPE[piller])
  }

  /**
   * insert table/figure/equation/section refer
   * @param e
   */
  const insertReference = (e) => {
    e.stopPropagation()
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    const newNodeList = cloneDeep(nodeList)
    const node = newNodeList[currentCursor.nodeIndex]
    let slotContent = ''
    if (!node.refer) {
      node.refer = {}
    }
    let id = Object.keys(node.refer)?.length + 1
    let lastSlot = ''
    selectedReferences.forEach((ref, index) => {
      node.refer[id] = {
        value: ref.label || ref.name,
        content: ref.content,
        type: ref.type,
      }
      slotContent += `($refer{${id}}$)`
      if (index === selectedReferences.length - 1) {
        lastSlot = `($refer{${id}}$)`
      }
      id++
    })

    const key = node.type === OutlineNodeType.Section ? 'name' : 'content'
    node[key] = insertContentToDom(node, currentCursor, slotContent).content
    setNodeList([...newNodeList])
    setSelectedReferences([])
    setOutlineIsChange(true)
    setLastInsert(lastSlot)
  }

  return (
      <Flex flexDir={'column'} onClick={(e) => e.stopPropagation()}>
        <Flex flexDir={'row'} pb={1} px={'0.5rem'}>
          <Button size={'xs'}
                  flex={1}
                  mr={1}
                  color={"#333"}
                  borderRadius={pxToRem(10)}
                  border={'1px solid rgba(56, 152, 255, 0.38)'}
                  _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                  bg={currentReferenceType === 'figure' ? 'rgba(56, 152, 255, 0.38)' : 'transparent'}
                  onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.figure)}>
            Figure
          </Button>
          <Button size={'xs'}
                  flex={1}
                  mr={1}
                  color={"#333"}
                  borderRadius={pxToRem(10)}
                  border={'1px solid rgba(56, 152, 255, 0.38)'}
                  _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                  bg={currentReferenceType === 'table' ? 'rgba(56, 152, 255, 0.38)' : 'transparent'}
                  onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.table)}>
            Table
          </Button>
          <Button size={'xs'}
                  flex={1}
                  mr={1}
                  color={"#333"}
                  border={'1px solid rgba(56, 152, 255, 0.38)'}
                  borderRadius={pxToRem(10)}
                  _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                  bg={currentReferenceType === 'equation' ? 'rgba(56, 152, 255, 0.38)' : 'transparent'}
                  onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.equation)}>
            Equation
          </Button>
          <Button size={'xs'}
                  flex={1}
                  mr={1}
                  color={"#333"}
                  border={'1px solid rgba(56, 152, 255, 0.38)'}
                  borderRadius={pxToRem(10)}
                  _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                  bg={currentReferenceType === 'section' ? 'rgba(56, 152, 255, 0.38)' : 'transparent'}
                  onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.section)}>
            Section
          </Button>
        </Flex>
        <Flex flexDir={'column'} overflow={'auto'} h={pxToRem(120)}>
          {crossRef && crossRef?.section.map((ref, index) => (
              <Box key={index}>
                {currentReferenceType === REFERENCE_TYPE.section &&
                    <Flex py={2} onClick={(e) => {
                      e.stopPropagation()
                      toggleSelection(ref)
                    }}>
                        <Checkbox
                            isChecked={selectedReferences.includes(ref)}
                            onChange={() => toggleSelection(ref)}>
                            <Tooltip hasArrow
                                     bg={'#FFF'} color={'#333'}
                                     label={JSON.stringify(ref.name)} aria-label='Description' style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: 2,
                            }}>
                                <span>{ref.label || ref.name}</span>
                            </Tooltip>
                        </Checkbox>
                    </Flex>
                }
              </Box>
          ))}
          {crossRef && crossRef?.equation.map((ref, index) => (
              <Box key={index}>
                {currentReferenceType === REFERENCE_TYPE.equation &&
                    <Flex py={2} onClick={(e) => {
                      e.stopPropagation()
                      toggleSelection(ref)
                    }}>
                        <Checkbox
                            isChecked={selectedReferences.includes(ref)}
                            onChange={() => toggleSelection(ref)}>
                            <Tooltip bg={'#FFF'} color={'#333'} hasArrow label={
                              <Box minW={pxToRem(300)} borderRadius={4}>
                                <LatexWrapper content={ref.content}></LatexWrapper>
                              </Box>
                            } aria-label='Description'>
                              {ref.label || <LatexWrapper content={ref.content}></LatexWrapper>}
                            </Tooltip>
                        </Checkbox>
                    </Flex>
                }
              </Box>
          ))}
          {crossRef && crossRef?.figure.map((ref, index) => (
              <Box key={index}>
                {currentReferenceType === REFERENCE_TYPE.figure &&
                    <Flex py={2} onClick={(e) => {
                      e.stopPropagation()
                      toggleSelection(ref)
                    }}>
                        <Checkbox
                            isChecked={selectedReferences.includes(ref)}
                            onChange={() => toggleSelection(ref)}>
                            <Tooltip hasArrow bg={'#FFF'} padding={0} label={
                              <Box>
                                {ref.content?.indexOf('data:image/png;base64') > - 1 ?
                                  <Image     alt="Reference Preview"
                                             maxWidth="200px"
                                             maxHeight="200px" src={ref.content} />
                                  : <Image     alt="Reference Preview"
                                               maxWidth="200px"
                                               maxHeight="200px" src={'data:image/png;base64,' + ref.content} />}
                              </Box>
                            } aria-label='Description'>
                              {ref.label || ref.caption || (`Figure${ref.id}`)}
                            </Tooltip>
                        </Checkbox>
                    </Flex>
                }
              </Box>
          ))}
          {crossRef && crossRef?.table.map((ref) => (
              <Box key={ref.id}>
                {currentReferenceType === REFERENCE_TYPE.table &&
                    <Flex py={2} onClick={(e) => {
                      e.stopPropagation()
                      toggleSelection(ref)
                    }}>
                        <Checkbox
                            isChecked={selectedReferences.includes(ref)}
                            onChange={() => toggleSelection(ref)}>
                            <Tooltip hasArrow padding={4} bg={'#FFF'} color={'#333'} label={
                              <TableRender content={ref.content} />
                            } aria-label='Description'>
                              {ref.label || ref.caption}
                            </Tooltip>
                        </Checkbox>
                    </Flex>
                }
              </Box>
          ))}
        </Flex>
        <Flex flexDir={'row'} justifyContent={'space-between'} pt={1} pb={2}>
          <Box>
            <Button w={pxToRem(120)} h={pxToRem(30)}
                    size={"sm"}
                    variant={'outline'}
                    borderColor={'#d3d7e2'}
                    leftIcon={
                      <Image bg={'#C9D9E8'} w="1rem" p={'2px'} borderRadius={pxToRem(5)}
                             src="/static/editor/login.svg" alt="Refer button"/>
                    }
                    onClick={insertReference}
            >
              Add
            </Button>
          </Box>
        </Flex>
      </Flex>
  )
}

export default CrossReferencePanel;