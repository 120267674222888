import React from 'react';
import { Box } from '@chakra-ui/react';
import { BlockMath, InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

interface LatexProps {
  content: string;
  isInline?: boolean;
}

const LatexWrapper: React.FC<LatexProps> = ({ content, isInline = false }) => {
  return (
    <Box sx={{
      '.katex-display': {
        margin: 0
      }
    }}>
      {isInline ? (
        <InlineMath math={content} />
      ) : (
        <BlockMath math={content} />
      )}
    </Box>
  );
};

export default LatexWrapper;